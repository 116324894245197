.home-landing {
  min-height: calc(100vh - 110px);
  background-image: url("../../assets/images/bgs/mobbg2.png");
}

.hl-ctt {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.hl-header {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.hl-header h1 {
  color: #232c2e;
  font-family: "MS-Bold";
  font-size: 48px;
  letter-spacing: 2px;
  text-transform: uppercase;
}

.hl-logo {
  display: flex;
  justify-content: center;
}

.hl-logo img {
  height: 30vh;
  /* width: 20vw; */
}

.hl-items {
  margin-top: 5vh;
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
}

.hl-item {
  width: 36vw;
  box-shadow: rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
  margin-bottom: 5vh;
  padding: 20px;
  border-radius: 20px;
  transition: 150ms ease-in-out;
  background-color: white;
}

.hl-item:hover {
  cursor: pointer;
  transform: scale(1.05);
  background-color: #edefef;
}

.hli-ctt {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

.hli-ctt h2 {
  background-color: #232c2e;
  /* background-color: #1e7a69; */
  color: white;
  text-transform: uppercase;
  padding: 10px 20px;
  font-size: 16px;
  font-family: "MS-Bold";
  border-radius: 20px;
}

.hli-ctt h5 {
  margin: 10px 0px;
  font-family: "MS-SM";
  opacity: 0.5;
  letter-spacing: 2px;
  text-transform: uppercase;
}

.hl-ico {
  margin: 20px 0px;
  height: 3vw;
  width: 3vw;
  padding: 20px;
  border-radius: 50%;
  background-color: #1e7a69 !important;
  fill: whitesmoke !important;
}

.hli-ctt p {
  font-family: "MS-Med";
  text-align: center;
  color: #232c2e;
  width: 50%;
  font-size: 14px;
}

.hli-top {
  display: flex;
  flex-direction: column;
  align-items: center;
}
