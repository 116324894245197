.player-row {
  display: flex;
  align-items: center;
  padding: 5px 0px;
  border-bottom: 1px solid var(--othergrey);
}

.player-row p {
  font-family: "TT-Med";
  font-size: 14px;
}

.pr-item {
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 0.1;
}

.pr-player {
  flex: 0.5;
}

.pr-item p {
  text-align: center;
}

.player-row-header {
  border-bottom: 2px solid var(--black);
}

.player-row-header p {
  text-transform: uppercase;
  font-size: 12px !important;
  font-family: "TT-Bold" !important;
  opacity: 0.7;
}

.player-row-header .pr-item {
  display: flex;
  align-items: center;
}

.player-row-header .pr-player {
  display: flex;
  align-items: center;
}

.event-home .pr-item {
  flex: 0.2 !important;
}
