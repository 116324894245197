.about-box-two {
  background-color: #f9f9f9;
}

.about-box-one {
  background-color: white;
}

.about-box {
  display: flex;
  padding: 40px;
  justify-content: space-evenly;
}

.aboi-box img {
  border-radius: 50%;
  height: 30vw;
  width: 30vw;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px,
    rgba(0, 0, 0, 0.3) 0px 30px 60px -30px,
    rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;
}

.abo-header h1 {
  color: #1e7a69;
  text-transform: uppercase;
  letter-spacing: 7.5px;
  /* font-family: "TT-Med"; */
  font-family: "MS-SM";
}

.abo-sub-header h3 {
  color: #1e7a69;
  text-transform: uppercase;
  letter-spacing: 7.5px;
  font-family: "MS-SM";
  /* font-family: "TT-Med"; */
}

.module-item {
  background-color: #1e7a69;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15vw;
  padding: 10px 0px;
}

.module-item h1 {
  text-transform: uppercase;
  color: white;
  font-size: 20px;
  /* font-family: "TT-Med"; */
  font-family: "MS-SM";
  font-weight: 400;
  letter-spacing: 5px;
}

.module-arrow {
  margin: 5px 0px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.mod-arr {
  fill: #1e7a69 !important;
  height: 20px !important;
}

.abo-text {
  font-family: "MS-Reg";
  margin: 15px 0px;
}

.abo-text p {
  width: 40vw;
  color: #232c2e;
  opacity: 0.8;
  font-size: 14px;
  /* font-family: "TT-Light"; */
}

.aboh-border {
  height: 2px;
  background-color: #1e7a69;
  width: 25vw;
  margin-top: 5px;
}

.abob p {
  font-family: "MS-Reg";
  font-size: 13px;
  color: #232c2e;
  opacity: 0.8;
  max-width: 40vw;
}

.abob {
  display: flex;
  /* align-items: center; */
  margin-bottom: 10px;
}

.abo-bulls li {
  font-size: 13px;
}

.abob h3 {
  font-family: "MS-SM";
  font-size: 14px;
  color: #232c2e;
  margin-right: 5px;
  /* width: 10vw; */
}

.abot-item {
  margin-bottom: 2.5vh;
}

.abot-item span {
  /* font-family: "TT-Bold"; */
  font-family: "MS-Bold";
}

.abo-bulls {
  /* font-family: "TT-Light"; */
  font-family: "MS-Reg";
  color: #232c2e;
  opacity: 0.8;
}

.heavy-pee {
  font-family: "MS-SM";
}

.abo-ib-text {
  margin-top: 5px;
}

.abo-info-box {
  margin-top: 20px;
}

.abo-ib-text p {
  /* font-family: "TT-Light"; */
  font-family: "MS-Reg";
  font-size: 14px;
  color: #232c2e;
  opacity: 0.8;
  max-width: 40vw;
}

.aboibs {
  display: flex;
}

.aboib-right {
  width: 20vw;
  margin-left: 40px;
}

.aboib-left {
  width: 30vw;
}
