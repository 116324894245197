.support {
  height: calc(100vh - 110px);
  overflow: scroll;
  background-image: linear-gradient(180deg, #fafcff 0%, #fff 100%);
}

.support-page {
  min-height: calc(100vh - 110px);
}

.support-message {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
}

.support-message p {
  text-align: center;
  font-size: 14px;
  color: #232c23;
}

.support-drop {
  padding: 20px 25px;
  width: calc(100% - 50px);
}

.support-drop .Dropdown-control {
  padding-top: 4.75px !important;
  padding-bottom: 4.75px !important;
}

.support-input {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.support .Dropdown-option {
  font-size: 12px;
}

.support .button-row {
  margin-top: 20px;
}

.query-error {
  color: red;
  opacity: 0.7;
  font-weight: bold;
  font-size: 14px;
  margin-top: 20px;
}

.query-error {
  display: flex;
  justify-content: center;
  text-align: center;
}

.query-finished {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 0px 20px;
}

.query-finished h2 {
  color: #232c23;
  margin-bottom: 20px;
  font-size: 16px;
}

.query-finished p {
  text-align: center;
  color: #232c23;
  font-size: 14px;
}

.faq-cat {
  box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px,
    rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
}

.fq-box {
  display: flex;
  /* justify-content: center; */
  /* align-items: center; */
}

.faq-cats {
  display: flex;
  padding-left: 20px;
  flex-wrap: wrap;
}

.faq-cat {
  padding: 20px;
  display: flex;
  margin-bottom: 2.5vw;
  border-radius: 40px;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #edefef;
  width: 25vw;
  margin-right: 2.5vw;
  cursor: pointer;
  transition: 200ms ease-in-out;
}

.faq-cat:hover {
  background-color: #edefef;
}

.faq-cat h5 {
  font-weight: 500;
  opacity: 0.7;
  font-size: 12px;
  font-family: "MS-SM";
  color: #232c23;
}

.faq-icon {
  opacity: 0.6;
  height: 12px !important;
  width: 12px !important;
}

.faq-cat:active {
  background-color: #edefef;
  transform: scale(0.95);
}

.faq-item {
  padding: 0px 20px;
}

.faq-item h3 {
  font-size: 14px;
  color: #232c23;
  font-weight: 600;
  width: max-content;
  padding-bottom: 5px;
  margin-bottom: 10px;
  border-bottom: 2px solid #1e7a69;
}

.faq-item p {
  font-size: 13px;
  color: #232c23;
  margin-top: 5px;
  opacity: 0.7;
}

.faq-content {
  overflow: scroll;
}
