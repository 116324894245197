.ph-me-opt {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 80vw;
}

.msw-bot {
  display: flex;
  align-items: center;
}

.mes-txt p {
  color: #232c2e;
  font-size: 12px;
  font-family: "MS-SM";
}

.mes-txt {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0px 5px;
}

.me-swi {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.me-swi h3 {
  font-size: 13px;
  color: #232c2e;
  font-family: "MS-Bold";
}

.msw-bot .scoring-switch {
  transform: scale(0.6);
}

.mes-mid {
  display: flex;
  align-items: center;
  justify-content: center;
}
