.sc-dg {
  /* cursor: pointer; */
}

.sc-set-box {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
}

.rp-box .round-player {
  width: 30vw;
}

.sc-players .round-player {
  background-color: white;
}

.sc-players {
  padding: 20px 0px;
  /* padding: 20px; */
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
}

.scorecard-creator .eve-rds {
  padding-top: 0px !important;
}

.scorecard-creator .eve-rd-item {
  cursor: pointer;
}
