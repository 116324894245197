.stable-points {
    width: 100%;
    height: 100%;
    overflow: scroll;
}

.stable-points .player-td {
    width: auto !important;
}


.stable-points .Dropdown-control {
    padding-top: 4.75px !important;
    padding-bottom: 4.75px !important;
}

.stable-points .input-group {
    padding: 0px 20px;
}

.sph-row {
    display: flex;
}

.sp-holes .score-table-container td {
    font-size: 12px !important;
}

.sp-holes .header-row {
    font-size: 14px;
}

.sp-holes .score-table-container table {
    width: 100%;
}

.player-round .stable-points .neh-box h2 {
    margin-left: 10px;
}