.psp-header {
    padding: 20px;
}

.psp-header h2 {
    font-size: 14px;
    color: #232C2E !important;
    font-weight: 600;
}

.ps-slider {
    display: flex;
    overflow-x: scroll;
    padding-left: 20px;
    margin-top: 20px;
}

.ps-details {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-right: 10px;
}

.player-selector .ts-header {
    background-color: transparent !important;
}

.ps-details h2 {
    font-size: 12px;
    color: #232C2E;
    margin-top: 10px;
}

.ps-cancel {
    fill: lightgray !important;
    position: relative;
    top: 0px;
}

.psc {
    width: 100%;
    display: flex;
    justify-content: flex-end;
}

.ps-button {
    display: flex;
    justify-content: center;
    border-top: 1px solid #edefef;
    
}

.ps-button button {
    /* background-color: #21c17c; */
    color: white !important;
    text-transform: none ;
    font-size: 14px !important;
    /* border: 1px solid #21c17c; */
    box-shadow: 0px 2px 4px 1px rgb(0 0 0 / 28%);
    background-image: linear-gradient(45deg, #1e7a69 0%, #21c17c 100%);
    border: none !important;
}

.ps-button button:disabled {
    background-color: grey;
    border-color: grey;
}

.ps-players {
    overflow: scroll;
    height: calc(100vh - 170px - 57px - 89px - 81px);
}

.pss-box {
    display: flex;
    justify-content: center;
    width: 100%;
    margin-bottom: 20px;
}

.ps-spin-box {
    display: flex;
    margin-top: 40px;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.ps-spin-box p {
    margin-top: 20px;
    font-size: 12px;
    font-weight: 600;
    color: #232C2E;
    opacity: 0.8;
}

.ps-search {
    display: flex;
    align-items: center;
    padding: 0px 20px;
    /* border: 1px solid #8E9191; */
    border-radius: 20px;
    width: 80vw;
    /* background-color: #F5F7F8 !important; */
}

.ps-search input {
    border: none !important;
    width: 100% !important;
    outline-width: 0;
}