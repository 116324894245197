.team-league-editor .player-item {
  width: 25vw !important;
  margin-right: 5vw;
}

.tle-courses {
  display: flex;
  flex-wrap: wrap;
}

.tle-courses .course-item {
  width: 25vw !important;
}

.tl-event-home {
  padding-top: 20px;
}

.tl-event-home .back-row {
  margin-top: 0px !important;
}

.tlr-match {
  display: flex;
  align-items: center;
  padding: 0px 20px;
}

.ts-team {
  margin-bottom: 40px;
}

.ts-pl {
  display: flex;
  align-items: center;
}

.tsp-left p {
  color: #232c2e;
  font-size: 14px;
}

.tsp-left {
  margin-right: 20px;
}

.vs-pee {
  margin: 0px 20px;
}

.vs-pee p {
  font-weight: 500;
  color: #232c2e;
  opacity: 0.7;
}

.tlr-match .player-item {
  width: 20vw !important;
}

.tlr-match .input-group {
  margin-left: 5vw;
  margin-top: 0px !important;
}

.small-input {
  width: 5vw !important;
}

.tlr-match {
  margin-bottom: 20px;
}

.tem-matches {
  width: calc(100% - 40px);
  padding: 20px;
}

.live .team-event-matches {
  height: calc(100vh - 110px - 84px);
  overflow: scroll;
}

.projected-team-log {
  width: 100vw;
}

.team-event-matches {
  /* width: 100vw; */
}

.tem-match .matchplay-score {
  width: 100% !important;
}

.tem-match .matchplay-score .msm-top {
  width: 5vw !important;
  height: 5vw !important;
  /* margin-top: 10px; */
}

.ed-box-open .mphs-hole {
  height: 60px !important;
}

.tem-match {
  margin-bottom: 20px;
}

.tem-filters {
  min-height: 50vh;
}

.tem-name-row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 5px;
}

.tnr-left {
  display: flex;
  align-items: center;
}

.tnr-right {
  display: flex;
  align-items: center;
  flex-direction: row-reverse;
}

.tem-name-row .MuiAvatar-root {
  height: 20px !important;
  width: 20px !important;
}

.tem-name-row p {
  font-weight: 600;
  color: #232c2e;
  font-size: 12px;
}

.tnr-left p {
  margin-left: 10px;
}

.tnr-right p {
  margin-right: 10px;
}

.ltm-teams {
  display: flex;
  flex-wrap: wrap;
}

.ltm-team {
  width: calc(50vw - 40px);
}

.ltm-members .player-score {
  width: 100% !important;
}

.ltm-members .ps-right-left {
  flex: 1 !important;
}

.ltm-members .ps-right {
  padding-left: 5px !important;
}

.ted-col {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.ted-col .mp-man-holes {
  width: 25vw;
}

.tra-res {
  /* display: flex; */
  margin-bottom: 5px;
}

.tlt-pad .log-header {
  padding-right: 30px;
}

.tra-res span {
  font-family: "Inter";
  font-weight: 500;
}

.tra-res h3 {
  margin-right: 5px;
  color: #232c23;
  font-family: "Inter";
  font-size: 13px;
}

.tra-res p {
  color: #232c23;
  font-family: "Inter";
  font-size: 13px;
}

.tl-accord .player-score {
  margin-bottom: 0px !important;
}

.tl-accord .MuiAccordion-root::before {
  display: none !important;
}

.tte-box {
  padding: 20px;
  box-shadow: rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
  background-color: white;
  border-radius: 20px;
  width: 80vw;
}

.tte-row {
  display: flex;
  align-items: center;
  padding: 5px 0px;
}

.tte-row p {
  font-size: 14px;
  font-weight: 600;
  color: #232c23;
}

.tte-tit {
  flex: 0.4;
}

.tte-item {
  flex: 0.1;
  display: flex;
  align-items: center;
  justify-content: center;
}

.tte-item input {
  width: 2.5vw !important;
}
