.client-stats {
  /* background-color: white;
  padding: 20px;
  margin-top: 20px;
  width: 80vw;
  margin-left: 20px; */
  /* margin-top: 20px; */
  padding-bottom: 40px;
  background-color: #f5f5fac1;
  padding-top: 20px;
}

.client-stats .back-row {
  margin-top: 0px;
}

.client-stats {
  min-height: calc(100vh - 110px);
}

.si-ico {
  background-color: #209e7359;
  fill: #209e73 !important;
  border-radius: 50%;
  padding: 7.5px;
  height: 36px !important;
  width: 36px !important;
}

.clista-right .rs-box {
  display: flex;
  flex-wrap: wrap;
  /* justify-content: space-between !important; */
}

.search-input {
  /* width: 35vw; */
  border: 1px solid transparent;
  /* border-radius: 40px; */
  display: flex;
  /* background-color: white; */
  align-items: center;
  font-size: 14px;
  padding: 5px 10px;
}

.clista-box {
  display: flex;
}

.csl-item {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: white;
  width: 20vw;
  padding: 10px;
  margin-bottom: 20px;
  justify-content: space-between;
  transition: 100ms ease-in-out;
  cursor: pointer;
  border-radius: 10px;
  /* border: 0.5px solid #ced4da; */
}

.csl-item:hover {
  box-shadow: rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;

  /* box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px; */
}

.csli-active {
  background-color: #1e7a69;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

.csli-active p {
  color: whitesmoke !important;
}

.clista-left {
  display: flex;
  flex-direction: column;
  height: 80vh;
  overflow: scroll;
  padding-left: 20px;
}

.csl-item p {
  color: #232c2e;
  font-size: 13px;
  padding-left: 10px;
  font-family: "MS-SM";
}

.clista-right {
  /* background-color: white; */
  margin-left: 20px;
  padding: 20px;
  width: 60vw;
}

.cs-graphs {
  /* background-color: white; */
  width: 90vw;
  margin-left: 20px;
  padding: 20px;
}

.cs-graphs .default-button {
  height: min-content;
  padding: 10px 20px !important;
}

.cs-graphs .def-drop {
  width: 15vw !important;
}

.stats-correl {
  /* background-color: white; */
  margin-left: 20px;
  padding: 20px;
  width: 90vw;
}

.cs-loader {
  background-color: white;
}

.scp-ctt {
  min-height: 75vh;
  min-width: 50vw;
}

.MuiInputBase-input::placeholder {
  color: #232c2e !important;
  font-family: "Inter" !important;
  font-size: 10px !important;
  opacity: 1 !important;
}

.MuiAutocomplete-clearIndicator:hover {
  background-color: #edefef !important;
}

.MuiAutocomplete-option {
  color: #232c2e;
  font-size: 12px;
}

.client-stats .MuiAutocomplete-root .MuiInputBase-input {
  padding: 10px 10px !important;
}

.MuiAutocomplete-option.Mui-focused {
  background-color: #6ba3903a !important;
}

.MuiAutocomplete-option[aria-selected="true"] {
  background-color: #6ba390 !important;
  color: whitesmoke !important;
}

.MuiAutocomplete-popupIndicator {
  margin-right: 10px !important;
}

.client-stats .MuiInputBase-root {
  outline-width: 0 !important;
  background-color: white !important;
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
    rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
  /* box-shadow: 0px 2px 2px 1px rgb(0 0 0 / 28%); */
  border: none !important;
  /* border: 1px solid #8e9191 !important; */
  font-size: 12px;
  border-radius: 20px !important;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 9.75px 20px !important;
  color: #5c6363;
  border: none !important;
  min-width: 20vw !important;
}
.CheckboxIndicator {
  color: #1e7a69;
}

.rcn-ta button {
  width: max-content;
}

.rcn-ta textarea {
  resize: none;
}

.csg-dates input {
  width: 8vw !important;
  margin-right: 10px;
}

.csg-per {
  display: flex;
  align-items: flex-start;
}

.cr-left {
  max-height: 100vh;
  overflow: scroll;
}

.rcn-ta {
  display: flex;
  flex-direction: column;
}

.rcn-box {
  margin-top: 20px;
}

.rcn-box p {
  color: #232c2e;
  font-size: 14px;
  white-space: pre-wrap;
}

.client-rounds {
  width: 100%;
}

.cr-load {
  width: 100%;
}

.cao-item {
  display: flex;
  align-items: center;
  cursor: pointer;
  margin-right: 10px;
}

.cao-item p {
  font-size: 11px;
  font-family: "MS-SM" !important;
  margin-bottom: 0px !important;
}

.dt-tabs {
  /* background-color: white; */
  /* box-shadow: rgba(0, 0, 0, 0.3) 0px 1px 3px -1px; */
}

.CheckboxRoot {
  margin-right: 20px;
  background-color: white;
  width: 25px;
  height: 25px;
  border-radius: 4px;
  display: flex;
  border-radius: 20px;
  align-items: center;
  justify-content: center;
  box-shadow: rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;

  /* box-shadow: 0 2px 10px var(--black-a3); */
  border: none;
}

.dt-tabs .MuiT .CheckboxRoot {
  border: none !important ;
  border-radius: 50% !important;
  margin-right: 20px;
  background-color: white;
  width: 25px;
  height: 25px;
  border-radius: 4px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 2px 10px var(--black-a3);
}

.gmb .MuiTabs-flexContainer {
  width: max-content;
  overflow: hidden;
  /* border: 2px solid #1e7a69; */
  border-radius: 20px;
}

.client-stats .ri-right {
  flex: inherit !important;
}

.client-stats .ri-left {
  flex: inherit !important;
  display: flex;
  align-items: flex-start !important;
  flex-direction: column;
}

.client-stats .MuiAutocomplete-root .MuiInputBase-root {
  display: flex;
  flex-wrap: wrap;
  width: 15vw;
  /* min-width: 25vw !important; */
}

.client-stats .MuiAutocomplete-root .MuiInputBase-root:hover {
  box-shadow: 0px 2px 2px 1px rgb(0 0 0 / 28%) !important;
}

.client-stats .MuiAutocomplete-root .MuiIconButton-root svg {
  fill: #232c2e !important;
}

.MuiOutlinedInput-notchedOutline {
  border: none !important;
}

.client-stats .input-group span {
  opacity: 1 !important;
}

.MuiChip-label {
  color: white !important;
  font-weight: 500 !important;
  font-family: "MS-SM" !important;
  font-size: 12px !important;
}

.MuiChip-root {
  background-color: #1e7a69 !important;
}

.MuiChip-deleteIcon {
  fill: whitesmoke !important;
}
.flex {
  display: flex;
}

.client-stats .MuiInputBase-input {
  font-family: "Inter" !important;
  font-weight: 500 !important;
  color: #232c2e !important;
  font-size: 12px !important;
}

.client-stats .Dropdown-control {
  padding: 5px 10px !important;
}

.MuiOutlinedInput-notchedOutline {
  border: none !important;
}

.SliderRoot {
  position: relative;
  display: flex;
  align-items: center;
  user-select: none;
  touch-action: none;
  width: 200px;
  height: 20px;
}

.SliderTrack {
  background-color: #6ba390;
  position: relative;
  flex-grow: 1;
  border-radius: 9999px;
  height: 3px;
}

.SliderRange {
  position: absolute;
  background-color: white;
  border-radius: 9999px;
  height: 100%;
}

.SliderThumb {
  display: block;
  width: 20px;
  height: 20px;
  background-color: white;
  box-shadow: 0 2px 5px #232c2e;
  border-radius: 10px;
}
.SliderThumb:hover {
  background-color: #edefef;
}
.SliderThumb:focus {
  outline: none;
  /* box-shadow: 0 0 0 5px #232c2e; */
}

.flex-betw {
  justify-content: space-between;
}

.rdrDateDisplayItemActive {
  border-color: #6ba390 !important;
}

.rdrStartEdge,
.rdrInRange,
.rdrEndEdge {
  color: #6ba390 !important;
}

.rdrDayToday .rdrDayNumber span:after {
  background-color: #6ba390 !important;
}

.client-stats .selector-group {
  max-height: 30px;
}

.rdrDateDisplayWrapper {
  background-color: whitesmoke !important;
}

.rdrDayNumber span {
  color: #232c2e;
}

.rdrStaticRangeLabel {
  color: #232c2e !important;
}

.rdrStaticRange:hover .rdrStaticRangeLabel,
.rdrStaticRange:focus .rdrStaticRangeLabel {
  background-color: whitesmoke !important;
}

.rdrDay.rdrDayHovered {
  border-color: #6ba390 !important;
}

.gmb button:hover {
  background-color: #edefef;
}

.gre-meu button:hover {
  background-color: #edefef;
  /* border-top-right-radius: 10px; */
}

.gre-meu {
  box-shadow: rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
  background-color: white;
  width: max-content;
  border-radius: 20px;
  overflow: hidden;
}
