.cf-dc {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  margin-left: 20px;
}

.cf-default {
  padding-left: 20px;
  display: flex;
  align-items: center;
}

.cf-dc h5 {
  margin: 0px 5px;
}

.cf-button {
  margin-left: 20px;
  font-size: 16px;
  padding: 10px 20px;
  margin-bottom: 10px;
}

/* .custom-formats .dg-icon-bg {
  box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px,
    rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
} */

.ep-edit-icon {
  background-color: #a9363f !important;
}

.cff-top {
  display: flex;
  align-items: center;
}

.edit-presets {
  width: 50vw;
}

.reset-icon {
  background-color: #232c2e !important;
}

.ep-item {
  box-shadow: rgb(149 157 165 / 20%) 0px 8px 24px;
  display: flex;
  align-items: center;
  border-radius: 20px;
  padding: 20px;
  width: 30vw;
  justify-content: space-between;
}

.ep-item h5 {
  color: #232c2e;
  font-size: 16pz;
}

.cf-formula {
  box-shadow: rgb(149 157 165 / 20%) 0px 8px 24px;
  border-radius: 40px;
  width: max-content;
  display: flex;
  padding: 20px;
  align-items: center;
  margin-bottom: 20px;
}

.cf-icon {
  margin-left: 40px;
  margin-top: 10px;
}

.custom-formats .radio-group {
  padding-bottom: 0px !important;
}

.cff-top .input-group {
  margin-top: 0px !important;
}

.cf-icon svg {
  width: 30px !important;
  height: 30px !important;
}

.custom-formats .Dropdown-control {
  width: 15vw;
  margin-bottom: 10px;
}

.cf-formulas {
  padding: 20px;
}

.cf-hole {
  display: flex;
  align-items: center;
}

.cfh-deets {
  display: flex;
  min-width: 20vw;
  width: max-content;
}

.cfh-deets p {
  font-weight: 500;
  font-family: "MS-SM";
  margin-top: 10px;
  color: #232c2e;
  font-size: 13px;
  margin-right: 20px;
}

.cf-holes {
  padding: 20px;
}
