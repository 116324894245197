.cps-search button {
    /* background-image: linear-gradient(45deg, #1e7a69 0%, #21c17c 100%); */

    background-color: #1e7a69;
    color: white;
    font-size: 14px;
    padding: 10px 20px;
    margin-left: 20px;
    border-radius: 20px;
    border: none;
    font-weight: bold;
    text-transform: uppercase;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
    cursor: pointer;
    transition: 200ms ease-in-out;
}

.cps-search button:hover {
    transform: scale(1.1);
}

.cps-search button:active {
    opacity: 0.7;
}


.cps-search input {
    border: 1px solid #edefef !important;
    width: 30vw;
}

.cps-search button:disabled {
    background-color: grey;
    border-color: grey;
    background-image: none;
}

.cs-no-courses {
    padding: 20px;
}

.cs-no-courses h1 {
    font-size: 20px;
}

.cs-courses {
    display: flex;
    flex-wrap: wrap;
    /* justify-content: space-between; */
}

.cs-courses .course-item {
    width: calc(25vw) !important;
    overflow: hidden;

}

.course-search {
    height: 100%;
}