.sd-menu .MuiFab-root {
  background-color: #1e7a69 !important;
  background-image: linear-gradient(1deg, #1e7a69, #ffffff20 99%);
  box-shadow: rgba(0, 0, 0, 0.3) 0px 1px 3px -1px !important;
}

.sd-menu .MuiSpeedDialAction-staticTooltipLabel {
  color: #232c2e !important;
  width: max-content !important;
  font-size: 12px !important;
  /* font-family: "Inter" !important; */
  font-weight: 600;
  border-radius: 20px !important;
  letter-spacing: normal !important;
  font-family: "MS-SM" !important;
  cursor: pointer;
  /* box-shadow: rgb(149 157 165 / 20%) 0px 8px 24px !important; */
}

.sd-menu .MuiSpeedDialAction-staticTooltipLabel:hover {
  background-color: #edefef !important;
}

.sdm-ico {
  z-index: 1000;
  fill: whitesmoke !important;
}

.sdm-ico:hover {
  opacity: 0.8 !important;
}

.sdm-ico-big {
  width: 1.5em !important;
  height: 1.5em !important;
}

#sdm-sd .MuiSpeedDial-actions {
  z-index: 1000;
}
