.course-stats-page {
  min-height: 100vh;
  overflow: scroll;
}

.cs-card {
  display: flex;
  padding: 20px 0px;
  justify-content: center;
}

.csp-rs {
  width: 75vw;
}

.tot-row td {
  font-weight: 600;
  font-size: 14px;
}

.csbs-box {
  display: flex;
  justify-content: center;
  padding: 20px;
}
