.log-item {
  width: 100% !important;
  padding: 10px 0px;
}

.log-iteem::before {
  content: "";
  position: absolute;
  border-bottom: 1px solid #edefef;
  height: 1px;
  width: 75vw;
  left: 12.5vw;
  /* top: 50px; */
  /* margin-top: 10%; */
}

.log-item .MuiBadge-badge {
  /* margin-top: 6px; */
}

.rk-pee {
  color: #232c2e !important;
  font-weight: 600 !important;
}

.log-item h3 {
  font-weight: 600 !important;
  font-size: 12px !important;
}

.log-item p {
  padding: 0px !important;
  margin: 0px !important;
}

.full-log .log-header {
  padding: 5px 0px;
  margin-bottom: 10px !important;
}

.log-item .ps-left {
  flex: none;
  height: 100%;
}

.stats-container {
  padding: 0px !important;
}

.log-item .ps-right {
  flex: 0.9;
}

.move-icon {
  height: 15px !important;
  width: 15px !important;
  margin-bottom: 5px;
}

.mi-down {
  fill: #e24955 !important;
}

.mi-up {
  fill: #1e7a69 !important;
}

.log-header p {
  font-size: 11px !important;
}

.ps-left p {
  margin-bottom: 5px !important;
}

/* .stats-header .ps-left {
    flex: 0.3 !important;
} */

/* .stats-header .ps-right {
    flex: 0.7 !important;
} */
