.fixture-row {
  display: flex;
  align-items: center;
  padding: 5px 0px;
  border-bottom: 1px solid #edefef;
}

.fixture-row-header {
  border-bottom: 2px solid #232c2e;
}

.fixture-row-header p {
  text-transform: uppercase;
  font-size: 12px !important;
  font-family: "MS-Bold" !important;
  opacity: 0.7;
}

.fixture-row-header .fr-item {
  display: flex;
  align-items: center;
}

.fri-2 {
  flex: 0.3;
}

.fri-1 {
  flex: 0.1;
}

.fri-15 {
  flex: 0.15;
}

.fr-item p {
  font-family: "MS-SM";
  font-size: 14px;
}

.fri-ava {
  flex: 0.05;
}

.fri-link {
  flex: 0.05;
}

.fri-link {
  display: flex;
  align-items: center;
  justify-content: center;
}

.fplb-box {
  display: flex;
  justify-content: center;
}

.fp-list-box {
  padding: 20px;
  width: 90vw;
}
