.end-season-row {
    margin-top: 0px !important;
}

.end-season-row button {
    /* background-color: #E24955; */
    background-image: linear-gradient(180deg, #F0A4AA 0%, #70242A 100%) !important;
    /* border: 1px solid #E24955; */
    /* background-image: none !important; */
}


.se-header h2 {
    font-size: 14px;
    color: #232c2e;
    margin-left: 20px;
    /* font-weight: 600; */
}

.season-editor .MuiPaper-elevation {
    box-shadow: none !important;
    background-color: transparent;
}

.season-editor .MuiAccordionSummary-root {
    padding: 0px 20px 0px 0px !important;
}

.season-editor .MuiAccordionDetails-root {
    padding: 0px !important;
}

.season-editor .Mui-expanded {
    /* min-height: 20px !important; */
    margin: 0px !important;
}

.MuiAccordionSummary-content {
    margin: 0px !important;
}

.season-img {
    display: flex;
    align-items: center;
}

.season-editor .input-group svg {
    margin-bottom: 10px;
}