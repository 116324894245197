.stats-header {
  /* margin-top: 10px; */
  /* box-shadow: 0 2px 5px -3px grey; */
  /* padding: 0px 10px; */
  /* background-image: linear-gradient(45deg, #1e7a69 0%, #21c17c 100%); */
  border-radius: 0px !important;
  display: flex;
  /* justify-content: space-between; */
}

.stats-header p {
  text-align: center;
}

.leagues .stats-header {
  margin-bottom: 0px !important;
}

.psa {
  opacity: 0.7;
  /* color: #21C17C !important;  */
}

.stats-header .ps-right-right {
  flex-direction: row !important;
  justify-content: center;
}

.sh-icon {
  opacity: 0.7;
  /* fill: white !important; */
}

.ps-right-right {
  transition: 200ms ease-in-out;
}

.ps-right-right:hover {
  opacity: 0.5;
  cursor: pointer;
}

.stats-header p {
  color: #232c2e;
  /* color: white; */
  font-weight: 600;
  font-size: 11px !important;
}

.stats-header .ps-left {
  /* flex: 0.3 !important; */
  padding-left: 10px;
}

.stats-header .ps-left p {
  /* font-weight: bold; */
  /* width: max-content !important; */
  /* white-space: nowrap; */
}

.stats-header .ps-left {
  overflow: visible;
}

.stats-header .ps-right {
  /* flex: 0.7 !important; */
}
