.bd-left {
  display: flex;
  align-items: center;
  padding: 20px;
  width: 100%;
  justify-content: space-evenly;
}

.bd-right {
  display: flex;
  padding: 20px;
  align-items: center;
  flex-direction: row-reverse;
  justify-content: space-evenly;
  width: 100%;
}

.bd-box {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.bdr-header {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

.bdr-header p {
  text-transform: uppercase;
  font-weight: 600;
  color: #232c2e;
  border-bottom: 2px solid #1e7a69;
  padding-bottom: 2.5px;
}

.bdm-player {
  box-shadow: rgb(149 157 165 / 20%) 0px 8px 24px;
  border-radius: 20px;
  padding: 10px 0px;
  width: 10vw;
  display: flex;
  justify-content: center;
}

.bdm-active {
  background-color: #1e7a69;
}

.bdm-active h1 {
  color: whitesmoke !important;
}

.bdm-player h1 {
  color: #232c2e;
  font-weight: 600;
  font-size: 12px !important;
  text-align: center;
  /* text-transform: uppercase; */
}

.bdm-vs {
  margin: 5px;
  font-size: 12px;
}

.bdm-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 40px;
}

.match-pee {
  opacity: 0.5;
  font-weight: 600;
  font-size: 10px;
  margin-bottom: 2.5px;
}

.bd-round {
  margin-right: 2.5vw;
}

.team-bd .bdm-player {
  padding: 10px !important;
  width: 15vw !important;
}

.team-bd .bdm-player h1 {
  text-align: center;
}
