.round-player {
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* padding: 0px 20px; */
  /* margin-top: 20px; */
  padding-bottom: 10px;
  padding-top: 10px !important;
  border-bottom: 1px solid #edefef;
  /* width: 30vw; */
}

.rp-team {
  margin: 2.5px 0px;
}

.rp-left {
  display: flex;
  /* align-items: center; */
  justify-content: center;
  flex: 0.2;
}

.rp-swap {
  cursor: pointer;
}

.sel-swap {
  background-color: #c7efde;
}

.rp-swap:hover:not(.sel-swap) {
  background-color: #edefef;
}

.rp-mid {
  flex: 0.6;
  /* display: flex;
    flex-direction: column; */
}

.rp-mr {
  flex: 0.2;
}

.rp-mr p {
  font-size: 10px;
  opacity: 0.6;
  font-weight: 500;
  margin-top: 4px;
  text-align: center;
}

.rp-mid h2 {
  font-size: 13px;
  font-weight: 600;
  line-height: 17px;
  color: #232c2e;
}

.rp-right {
  flex: 0.1;
  display: flex;
}

.rp-star {
  height: 20px !important;
  width: 20px !important;
}

.rp-hcp {
  display: flex;
  align-items: center;
}

.rp-hcp h3 {
  font-size: 11px;
  color: #232c2e;
  margin-left: 5px;
}

.rp-tee {
  background: #e24955;
  border-radius: 16px;
  padding: 3px 9px;
  width: min-content;
  margin: 5px 0px;
}

.rp-tee p {
  text-transform: capitalize;
  font-size: 10px;
  color: white;
  font-weight: 600;
}

.rp-mi {
  padding: 10px;
  background-color: white !important;
}

.rp-mi p {
  font-size: 14px;
}

.rp-right .MuiPaper-elevation {
  background-color: white !important;
}

.MuiList-root {
  padding: 0px !important;
}

.rp-mi {
  cursor: pointer;
  transition: 200ms ease-in-out;
}

.rp-mi p {
  color: #5c6363;
  font-size: 12px;
}

.rp-mi:hover {
  /* background-color: #edefef !important; */
  background-color: rgba(0, 0, 0, 0.04) !important;
  border-radius: 10px;
}

.rp-guest {
  background-color: #1e7a69;
  border-radius: 20px;
  padding: 2.5px 7.5px;
  margin-left: 20px;
}

.rp-guest p {
  color: white;
  font-size: 10px;
  opacity: 1 !important;
  font-weight: 600;
}

.rp-thru {
}

.rp-thru {
  /* margin-left: 10px; */
  /* background-color: #232c2e; */
  border-radius: 50%;
  height: 20px;
  width: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.rp-thru p {
  opacity: 1 !important;
  /* color: whitesmoke; */
  font-weight: 600;
  color: #232c2e;
  font-size: 12px !important;
  /* border-bottom: 2px solid #1e7a69; */
}

.rp-thru-ico {
  height: 16px !important;
}

.just-bet {
  justify-content: space-between;
}

.rpt-box {
  border: 2px solid #1e7a69;
  border-radius: 20px;
}

.rp-score-box {
  /* margin-left: 20px; */
  /* border: 1px solid #232c2e; */
  /* border-radius: 50%; */
}

.rp-score-box h4 {
  color: #232c2e;
  font-size: 13px;
}
