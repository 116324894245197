.jh-holes {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  padding: 20px;
  width: 40vw;
}

.jh-hole {
  width: 5vw;
  height: 5vw;
  margin-bottom: 1vw;
  margin-right: 1vw;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  border: 2px solid #1e7a69;
}

.jhh-box {
  display: flex;
  justify-content: center;
  cursor: pointer;
}

.jh-hole p {
  font-weight: 600;
  color: #1e7a69;
}

.jhh-active {
  background-color: #1e7a69;
}

.jhh-active p {
  color: whitesmoke !important;
}
