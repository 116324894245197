.event-player-selector button {
    padding: 10px 40px;
    border-radius: 7px;
    font-weight: bold;
    transition: 50ms ease-in;
    margin-bottom: 20px;
    margin-top: 20px;
    cursor: pointer;
    border: none;
    border-color: #21c17c;
    box-shadow: 0px 2px 4px 1px rgba(0,0,0,0.28);

}

.event-player-selector {
    height: 100%;
    overflow: scroll;
    /* padding-bottom: 20px; */
}

.no-invites {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
}

.no-invites h2 {
    font-size: 14px;
    color: #232c23;
}

