.cp-cups .fixture-preview {
  width: 50vw;
}

.cups-page {
  min-height: calc(100vh - 110px);
  background-color: #f5f5fac1;
}

.cp-cups {
  padding: 20px;
}
