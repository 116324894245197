.is-image img {
  width: 20vw;
  border-radius: 5px;
  /* width: fit-content; */
}

.is-image {
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: content-box;
  /* flex: 0.3; */
  border-radius: 7.5px;
  border: 2px solid transparent;
  margin-bottom: 20px;
  transition: 200ms ease-in-out;
}

.isi-active {
  border: 4px solid #1e7a69 !important;
  opacity: 1 !important;
}

.is-boxes {
  margin-top: 20px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
}

.is-image:hover {
  cursor: pointer;
  border-color: #edefef;
  opacity: 0.8;
}
