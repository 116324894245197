.format-item {
  padding: 10px 20px;
  cursor: pointer;
}

.format-item h2 {
  color: #232c2e;
  font-size: 14px;
  font-weight: 600;
}

.format-item p {
  color: #232c2e;
  font-size: 12px;
}

.fi-selected {
  background-color: #c7efde;
}

.format-list-item {
  border-bottom: 1px solid #edefef;
}

.format-list-item p {
  opacity: 0.8;
}
