.team-scorecard {
  width: 100%;
  height: 100%;
}

.team-scorecard .ts-header {
  padding-top: 0px !important;
}

.team-scorecard th {
  font-size: 14px;
}

.tsc-high p {
  background-color: #a9363f;
  color: whitesmoke;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.team-leaderboard .ts-header {
  margin-top: 20px;
}

.tsc-low p {
  background-color: #1e7a69;
  color: whitesmoke;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.team-scorecard .over-par {
  color: #a9363f !important;
}

.team-scorecard .under-par {
  color: #1e7a69 !important;
}

.osl-opa {
  opacity: 0.7;
}

.team-scorecard .player-td {
  width: 30px;
}

.ove-sc-list h2 {
  font-size: 14px !important;
}

.osl-ove h3 {
  text-transform: uppercase;
  font-size: 15px !important;
}

.osl-ove {
  /* width: max-content; */
  border-bottom: 2px solid #1e7a69;
}

.osl-ove p {
  font-size: 15px !important;
}

.osl-pl {
  display: flex;
  align-items: center;
  margin-left: 20px;
  margin-bottom: 5px;
}

.osl-box {
  width: 30vw;
}

.osl-pl {
  justify-content: space-between;
}

.osl-pl h3 {
  font-family: "Inter";
  color: #232c23;
  font-size: 13px;
  font-weight: 600;
  width: 20vw;
}

.osl-pl p {
  color: #232c23;
  font-size: 13px;
}

.osl-pl p strong {
  font-family: "Inter";
  font-weight: 600;
  margin-left: 2.5px;
}
.osl-ove p strong {
  font-family: "Inter";
  font-weight: 600;
  margin-left: 2.5px;
}

.cus-td {
  color: #232c23;
  font-size: 12px !important;
  text-transform: capitalize;
}
