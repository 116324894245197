.vp-box {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80vh;
}

.verify-page {
  /* min-height: 80vh; */
  /* background-color: #1e7a69; */
  background-color: #f5f5fac1;
}

.vpb-box {
  box-shadow: rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
  background-color: white;
  display: flex;
  flex-direction: column;
  padding: 60px;
  align-items: center;
}

.vp-box h2 {
  margin-bottom: 20px;
  font-size: 50px;
  font-family: "MS-Bold";
  text-align: center;
}

.vp-box p {
  text-align: center;
  font-family: "MS-Reg";
}
