.cdl-dia .MuiPaper-root {
  width: 90vw;
  height: 90vh;
  max-width: none !important;
}

.drill-attempt {
  /* justify-content: space-between; */
  width: 100%;
  padding: 5px 0px;
  /* margin-bottom: 10px; */
  border-bottom: 1px solid #edefef;
}

.dt-tabs {
  border-radius: 20px;
  overflow: hidden;
  background-color: white;
  box-shadow: rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
}

.dt-tabs .MuiTab-root:hover {
  background-color: #edefef;
}

.dss-box {
  display: flex;
  justify-content: center;
  padding: 20px;
}

.dss-box .bs-subtle {
  width: 100%;
}

.drill-attempt div {
  display: flex;
  flex: calc(1 / 6);
  justify-content: center;
}

.da-date p {
  color: #232c2e;
}

.drill-attempt p {
  font-size: 13px;
  font-family: "MS-Reg";
}

.cd-box {
  display: flex;
  justify-content: center;
  padding: 20px;
}

.cd-box .bss-white {
  width: 90%;
}

.da-header {
  border-bottom: 2px solid #232c2e;
}

.da-header p {
  color: #232c2e;
  opacity: 0.6;
  text-transform: uppercase;
  font-family: "MS-SM";
}

.da-diff-Medium {
}

.da-diff-Medium p {
  color: whitesmoke !important;
  width: max-content;
  background-color: #232c2e;
  padding: 5px;
  border-radius: 5px;
}

.da-diff-Easy p {
  color: whitesmoke !important;
  width: max-content;
  background-color: #6ba390;
  padding: 5px;
  border-radius: 5px;
}

.da-diff-Hard p {
  color: whitesmoke !important;
  width: max-content;
  background-color: #db5461;
  padding: 5px;
  border-radius: 5px;
}

.dsi-ctt h3 {
  color: #232c2e;
  font-weight: 500;
  font-size: 14px;
}

.dd-attempt div {
  flex: 0.25 !important;
}

.drill-sce:hover {
  background-color: #edefef;
}

.sce-tags {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  row-gap: 5px;
}

.sce-tag {
  margin-right: 10px;
  border: 1px solid #edefef;
  border-radius: 20px;
  padding: 5px;
}

.sce-tag p {
  font-family: "MS-SM" !important;
  opacity: 0.5;
  font-size: 10px !important;
}

.sce-notes {
  margin-top: 10px;
}

.sce-notes li {
  font-family: "MS-Reg";
  font-size: 14px;
}

.adr-view {
  display: flex;
  align-items: center;
}

.ddbr-top {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.ddbr-top .di-diff {
  margin-left: 0px !important;
  margin-right: 10px;
}
