.features-tabs {
  margin: 20px 0px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.ft-box {
  background-color: #edefef80;
  border-radius: 40px;
  padding: 5px;
}

.ft-tabs .MuiTabs-scroller {
  display: flex;
  align-items: center;
}

.ft-tabs .MuiTab-root {
  margin: 0px 5px;
  font-size: 14px !important;
  text-transform: none !important;
  font-family: "MS-Med" !important;
  color: #232c2e !important;
  padding: 10px 30px !important;
  min-height: 0px !important;
  border-radius: 40px;
  transition: 100ms ease-in-out !important;
}

.ft-tabs {
  min-height: 0px !important;
}

.ft-box .ft-tabs .Mui-selected {
  color: white !important;
  border-radius: 40px;
  background-image: linear-gradient(1deg, #1e7a69, #ffffff20 99%);
}

.ft-tabs .MuiTab-root:hover {
  background-color: #1e7a69;
  /* background-image: linear-gradient(1deg, #1e7a69, #ffffff20 99%); */

  color: white !important;
}

.disp-head {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: max-content;
}

.disp-head h2 {
  color: #232c2e;
  font-size: 36px;
  font-family: "MS-SM";
  /* font-family: "TT-Med"; */
}

.dis-bb {
  margin-top: 5px;
  height: 2px;
  background-color: #1e7a69;
  width: 60%;
}

.ftib-ctr {
  margin: 40px 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.dis-bb-2 {
  margin-top: 5px;
  height: 2px;
  background-color: #1e7a69;
  width: 30%;
}

.fti-box {
  /* box-shadow: rgba(0, 0, 0, 0.3) 0px 1px 3px -1px; */
  background-color: #1e7a690f;
  width: 80vw;
  min-height: 50vh;
}

.fti-head h3 {
  color: #232c2e;
  font-family: "MS-Bold";
  font-size: 36px;
  padding: 40px;
}
