.ecb-left {
  display: flex;
  flex-direction: column;
}

.message-item {
  display: flex;
  align-items: center;
  box-shadow: rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
  background-color: white;
  /* box-shadow: rgb(149 157 165 / 20%) 0px 8px 24px; */
  border-radius: 20px;
  padding: 20px;
  width: 50vw;
  justify-content: space-between;
  margin-bottom: 20px;
}

.message-board {
  min-height: calc(100vh - 110px);
}

.message-item .MuiAvatar-root {
  width: 8vw !important;
  height: 8vw !important;
}

.mi-left {
  margin-right: 2.5vw;
}

.mi-text {
  display: flex;
  align-items: center;
}

.mi-right h2 {
  color: #232c2e;
  font-size: 16px;
}

.mi-right h5 {
  font-weight: 500;
  opacity: 0.6;
  font-size: 12px;
  margin: 5px 0px;
}

.mi-icons {
  display: flex;
  flex-direction: column;
}

.mi-right p {
  font-size: 13px;
  margin-top: 5px;
  color: #232c2e;
  opacity: 0.8;
}
