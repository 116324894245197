.chat-page {
  display: flex;
  height: calc(100vh - 110px);
  flex-direction: column;
  justify-content: space-between;
}

.cp-header {
  display: flex;
  align-items: center;
  height: 50px;
  padding: 10px 10px;
  justify-content: space-between;
  /* width: calc(100vw - 20px); */
  border-bottom: 2px solid #edefef;
}

.cp-deets h1 {
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
  font-family: "MS-SM";
  /* Black/Black 50 */

  color: #232c2e;
}

.cph-ico {
  padding: 5px;
  border-radius: 50%;
}

.cph-ico:hover {
  background-color: #edefef;
}

.cp-deets p {
  font-weight: normal;
  font-size: 12px;
  line-height: 17px;
  /* identical to box height */

  /* Black/Black 40 */

  color: #232c2e;
  opacity: 0.5;
}

.cp-ava {
  margin: 0px 10px 0px 10px;
}

.cph-left {
  display: flex;
  align-items: center;
}

.cp-input {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 5px 10px;
  border-top: 2px solid #edefef;
}

.cp-input textarea {
  resize: none;
  background-color: transparent;
  border: none;
  height: 100%;
  font-size: 12px;
  line-height: 19px;
  width: 90%;
  padding: 5px 5px;
  /* Black/Black 50 */

  color: #232c2e;
}

.cpi-button {
  /* width: 40px; */
  /* height: 40px; */
  /* border-radius: 50%; */
  background: #1e7a69;
  color: white;
  font-weight: bold;
  border-radius: 20px;
  padding: 5px 10px;
  cursor: pointer;
  /* background-color: transparent; */
  text-transform: uppercase;
  /* filter: drop-shadow(0px 4px 20px rgba(40, 190, 136, 0.32)); */
  border: none;
  transition: 200ms ease-in-out;
}

.cpi-button:hover {
  transform: scale(1.1);
  /* text-decoration: underline; */
}

.cp-msgs {
  height: 100%;
  padding-bottom: 20px;
  overflow: scroll;
}

.no-msgs {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  height: 100%;
}

.no-msgs span {
  font-weight: bold;
  font-size: 14px;
  opacity: 0.5;
  margin-bottom: 20px;
}

.blocked {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: flex-end;
  margin-top: 50px;
  /* height: 100%; */
  /* justify-content: center; */
}

.blocked span {
  font-weight: bold;
  font-size: 14px;
  opacity: 0.5;
}

.player-pee {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  width: 20vw;
}

.player-list {
  padding: 20px;
}

.cpii {
  width: 90%;
  display: flex;
  align-items: center;
}
