.ms-events {
  padding: 20px;
  display: flex;
  flex-wrap: wrap;
}

.ms-events .fixture-preview {
  width: 40vw !important;
  margin-right: 5vw;
  cursor: pointer;
}
