.ed-box {
  width: calc(42.5vw + 10px);
  height: 30vw;
  overflow: scroll;
  padding: 20px;
  background-color: white;
  border-radius: 15px;
  transition: 400ms ease-in-out;
  /* box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px,
    rgba(14, 30, 37, 0.32) 0px 2px 16px 0px; */
  margin-bottom: 5vh;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
    rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
}

.ed-box-open .time-item {
  width: 20vw !important;
  margin-right: 5vw;
}

.ed-box-open .edt-box {
  /* justify-content: space-evenly; */
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.mr-ms {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.mr-ms .tem-team {
  width: 40vw;
}

.evt-add-guest {
  width: 75vw;
}

.dg-comp {
  background-color: #c7efde46;
}

.lg-guest-edit {
  width: 75vw;
}

.erp-players {
  padding-bottom: 20px;
}

.tph-name {
  width: calc(15vw + 40px);
  /* margin-right: 20px; */
}

.ltc-handicaps p {
  margin-bottom: 5px;
  font-size: 14px;
  color: #23232c;
}

.tph-opt {
  width: 7.5vw;
  display: flex;
  justify-content: center;
}

.tp-header p {
  font-size: 12px;
  color: #23232c;
  font-weight: 600;
}

.tp-player p {
  font-size: 14px;
  color: #23232c;
  /* font-weight: 600; */
}

.cup-dashboard {
  min-height: calc(100vh - 110px);
}

.tp-header {
  padding-left: 10px;
  display: flex;
  align-items: center;
}

.tp-header .tph-opt {
  cursor: pointer;
}

.tpho-active p {
  color: #1e7a69 !important;
}

.tp-player {
  display: flex;
  align-items: center;
}

.countout-dialog {
  height: 50vh;
}

.tied-players {
  padding: 20px;
}

.tied-players .player-item {
  width: 15vw !important;
  margin-right: 10px;
}

.erp-loading {
}

.cancel-dia-content {
  padding: 20px;
}

.event-dash .quick-links {
  /* width: 75vw !important; */
}

.cancel-dia-content .neh-box {
  /* padding-left: 0px !important; */
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.ls-confirm-delete {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.cdr-group {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.cdc-msg p {
  font-size: 14px;
  text-align: center;
  color: #23232c;
}

.cancel-dia-content input {
  width: 50vw !important;
}

.cancel-dia-content .input-group {
  align-items: center;
}

.cd-radio {
  padding: 0px 10px;
}

.cdr-group p {
  font-size: 12px;
  margin-left: 10px;
  color: #23232c;
  opacity: 0.7;
}

.cancel-dia-content .MuiRadio-root {
  color: #1e7a69 !important;
}

.ed-boxes {
  display: flex;
  flex-wrap: wrap;
  padding: 20px;
  justify-content: space-between;
  width: 95vw;
}

.ed-box-open {
  width: calc(100% - 40px) !important;
  height: 60vw !important;
}

.edb-box {
  display: flex;
  justify-content: center;
}

.edt-box {
  padding: 0px 20px;
  padding-bottom: 20px;
}

.edt-loading {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.ed-tee-times {
  height: calc(100% - 64px);
}

.event-remove-player {
  width: 50vw;
  padding: 20px 20px;
  min-height: 50vh;
  max-height: 80vh;
}

.erp-confirm {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.erpc-msg p {
  font-size: 14px;
  margin-bottom: 40px;
}

.erp-finished {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 40px;
}

.event-add-player {
  min-height: 50vh;
  width: 75vw;
}

.event-add-player .player-item {
  width: 20vw !important;
}

.event-add-player .loading {
  height: calc(50vh - 79px);
}

.eap-players {
  padding: 20px;
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
}

.apm-times {
  padding-left: 20px;
}

.event-add-player .error-message-row {
  display: flex;
  justify-content: center;
}

.event-handicap-edit .im-msg p {
  width: 40vw;
}

.event-handicap-edit {
  width: 50vw;
  min-height: 50vh;
  transition: 600ms ease-in-out;
}

.event-handicap-edit .loading {
  height: calc(50vh - 79px - 26px);
}

.submit-dia {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 50vw;
  padding: 20px;
}

.submit-dia p {
  font-size: 14px;
  margin: 20px 0px;
  text-align: center;
}

.submit-dia .page-header {
  width: 100%;
}

.submit-dia .loading {
  height: 30vh;
}

.score-portal-players {
  width: 90vw;
  min-height: 90vh;
  padding: 20px 40px;
}

.score-portal-players .player-item {
  width: 50vw !important;
}

.spgg-pl {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.sppg-header h3 {
  color: #23232c;
}

.sppg-header {
  /* margin-top: 40px; */
  margin-bottom: 20px;
  margin-left: 20px;
}

.spp-group {
  margin-bottom: 30px;
}

.portal-prog-row {
  justify-content: space-evenly;
}

.tpp-ra {
  width: 2.5vw;
}

.tpp-ra p {
  font-size: 12px;
  font-weight: 600;
}

.tpho-act p {
  font-weight: 600;
  background-color: #1e7a69;
  color: whitesmoke !important;
  border-radius: 50%;
  width: 2.5vw;
  height: 2.5vw;
  display: flex;
  justify-content: center;
  align-items: center;
}

.vs-pee-pee {
  padding: 10px 0px;
  border-bottom: 1px solid #edefef;
}

.tlg-black {
  background-color: #23232c;
  color: whitesmoke;
  padding: 5px 10px;
  border-radius: 20px;
  font-size: 13px !important;
  font-weight: 500;
}

.tlg-gr {
  background-color: #1e7a69;
  color: whitesmoke;
  padding: 5px 10px;
  border-radius: 20px;
  font-size: 13px !important;
  font-weight: 500;
}

.tlg-red {
  background-color: #a9363f;
  color: whitesmoke;
  padding: 5px 10px;
  border-radius: 20px;
  font-size: 13px !important;
  font-weight: 500;
}
/* 
.tlg-gr p {
  color: whitesmoke;
} */

.mhrb-state {
  width: 4vw;
  margin-right: 2vw;
}

.mhr-state-top {
  height: 10vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.mhrb-state h3 {
  font-size: 14px !important;
}

.mhr-state-bot {
  height: 10vh;
}
.mhr-state-mid {
  height: 7vh;
}

.mhr-state-act .mhrw-box {
  background-color: #1e7a69;
  border-color: #1e7a69;
  width: 4vw !important;
  height: 4vw !important;
  cursor: initial !important;
}

.mhr-state-act h3 {
  color: whitesmoke !important;
}

.mhr-state-act-tie .mhrw-box {
  background-color: #23232c;
  border-color: #23232c;
  width: 4vw !important;
  height: 4vw !important;
  cursor: initial !important;
}

.mhr-state-act-tie h3 {
  color: whitesmoke !important;
}

.mhr-state-act-two .mhrw-box {
  background-color: #a9363f;
  border-color: #a9363f;
  width: 4vw !important;
  height: 4vw !important;
  cursor: initial !important;
}

.mhr-state-act-two h3 {
  color: whitesmoke !important;
}

.mhr-box {
  display: flex;
  width: 100vw;
  margin-top: 20px;
  flex-direction: column;
  /* align-items: center; */
}

.mhrb-name {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: center;
  height: 10vh;
}

.mhrb-name h2 {
  color: #23232c;
  font-size: 14px;
  /* border-bottom: 2px solid #1e7a69; */
  /* padding-bottom: 5px; */
  text-align: center;
}

.mhrb-hole-i {
  display: flex;
}

.mhrb-mid p {
  text-align: center;
  color: #23232c;
  font-weight: 500;
  opacity: 0.7;
  font-size: 12px;
}

.mhrb-hole {
  width: 4vw;
}

.mhrb-holes {
  display: flex;
  align-items: center;
}

.mhr-win-hole {
  height: 10vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.mhrw-box {
  border: 2px solid #23232c71;

  border-radius: 50%;
  height: 3vw;
  width: 3vw;
  display: flex;
  cursor: pointer;

  justify-content: center;
  align-items: center;
}

.mhrw-box h3 {
  color: #23232c71;
}

.mhr-tie-hole {
  height: 7vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.mhrt-act .mhrw-box {
  /* width: 2.5vw;
  height: 2.5vw; */
  border-color: #1e7a69;
  background-color: #1e7a69;
}

.mhrt-tie-act .mhrw-box {
  /* width: 2.5vw;
  height: 2.5vw; */
  border-color: #232c2e;
  background-color: #232c2e;
}

.eve-rds {
  display: flex;
  align-items: center;
  padding: 10px;
}

.eve-rd-item p {
  font-size: 12px;
  color: #23232c;
  font-weight: 500;
  font-family: "MS-SM";
}

.eve-rd-item {
  display: flex;
  margin-right: 20px;
  align-items: center;
}

.mhrt-two-act .mhrw-box {
  /* width: 2.5vw;
  height: 2.5vw; */
  border-color: #a9363f;
  background-color: #a9363f;
}

.mhrt-act h3 {
  color: whitesmoke;
}

.mhrt-tie-act h3 {
  color: whitesmoke;
}

.mhrt-two-act h3 {
  color: whitesmoke;
}

.mhrb-left {
  width: 20vw;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.mhr-vers {
  align-items: center;
  display: flex;
  height: 7vh;
  justify-content: center;
}

.mhrb-right {
  display: flex;
  flex-direction: column;
}

.dgp-team {
  display: flex;
  align-items: center;
  justify-content: center;
}

.add-2c-dia {
  width: 75vw;
}

.add-2c-dia .player-item {
  width: 20vw !important;
}

.two-board-box {
  width: 75vw;
}

.dgp-team p {
  font-size: 13px;
  color: #23232c;
  font-weight: 500;
  margin-top: 10px;
  text-transform: uppercase;
  letter-spacing: 1px;
  opacity: 0.5;
}

.mhrb-main {
  display: flex;
  align-items: center;
}

.lcg-vs {
  display: flex;
  justify-content: center;
  border-bottom: 1px solid #edefef;
}

.lcg-vs p {
  font-weight: 600;
  color: #23232c;
  font-size: 14px;
  margin: 10px 0px;
}

.bbsr-team input {
  margin-left: 20px;
  width: max-content !important;
  margin-bottom: 10px !important;
}

.bbsr-team .player-item {
  width: 30vw;
}

.player-dq {
  min-height: calc(100vh - 110px);
  overflow: scroll;
}

.lpce-ctt {
  min-width: 50vw;
}

.player-dq .player-item {
  width: 20vw !important;
}

/* .player-dq .player-item . */

.event-dash {
  min-height: calc(100vh - 110px);
  overflow: scroll;
  background-color: #f5f5fac1;
}

.div-opt {
  box-shadow: rgb(149 157 165 / 20%) 0px 8px 24px;
  border-radius: 20px;
  padding: 20px;
  color: #232c2e;
  width: 20vw;
  margin-right: 1vw;
  cursor: pointer;
  margin-bottom: 20px;
}

.do-active {
  background-color: #c7efde;
}

.sd-group .Dropdown-root {
  width: 30vw !important;
}

.div-opt p {
  font-family: "Inter";
  font-weight: 600;
  font-size: 12px;
}

.fb-div-options {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  padding: 20px;
}

.ed-ico {
  height: 20px !important;
  width: 20px !important;
}

.list-group {
  box-shadow: rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
  background-color: white;
  /* padding: 10px; */
  border-radius: 20px;
  margin-bottom: 20px;
}

.lg-top {
  padding: 10px;
}

.list-group-player {
  display: flex;
  align-items: center;
  padding: 5px 10px;
  border-bottom: 1px solid #edefef;
}

.lgp-pl {
  width: 10vw;
}

.lgp-sc {
  width: 3vw;
}

.lgp-ico {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 5vw;
}

.lgp-pl p {
  color: #23232c;
  font-size: 12px;
  font-weight: 400;
}

.lgp-left {
  display: flex;
  align-items: center;
}

.lgp-head {
  padding-bottom: 10px;
}

.lgp-head p {
  font-weight: bold !important;
}

.lgp-sc {
  display: flex;
  align-items: center;
  justify-content: center;
}

.lgp-sc p {
  font-size: 12px;
  font-weight: 500;
  color: #23232c;
}

.list-group-player svg {
  height: 20px !important;
  width: 20px !important;
}

.ico-dis {
  fill: grey !important;
}

.ico-dis:hover {
  /* fill: grey !important; */
  background-color: white !important;
  cursor: initial !important;
}

.small-score-ip {
  width: 100%;
}

.lgp-right {
  width: 100%;
}

.ssi-bot input {
  width: 1vw !important;
  padding: 10px !important;
}

.ssi-holes {
  display: flex;
  overflow-x: scroll;
  align-items: center;
  justify-content: space-evenly;
  padding-bottom: 5px;
}

.ssi-hole {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.ssi-top p {
  font-size: 10px;
}

.ssi-top {
  margin-bottom: 5px;
}

.ssi-holes input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.ssi-bt p {
  color: #23232c;
  font-weight: 500;
  font-size: 14px;
}

.lgp-comp {
  background-color: #c7efde46;
}

.list-group-player .dg-icon-bg {
  background-color: white;
}

.list-group .red-icon-bg {
  background-color: white;
}
