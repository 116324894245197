.player-tee-selector {
}

.player-tee-selector .page-header {
  padding-top: 10px;
}

.pts-players {
  padding: 20px;
}

.tee-ip {
  text-transform: capitalize;
}

.pts-player {
  background-color: white;
  display: flex;
  align-items: center;
  padding: 10px 20px;
  border-bottom: 1px solid #edefef;
  box-shadow: rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;

  /* box-shadow: rgb(149 157 165 / 20%) 0px 8px 24px; */
  border-radius: 20px;
  min-width: 60vw;
  margin-bottom: 20px;
}

.ptsp-left {
  display: flex;
  align-items: center;
  /* flex: 0.3; */
  width: 30vw;
}

.pts-hcp {
  display: flex;
  align-items: center;
}

.ptsp-left .MuiAvatar-root {
  height: 5vw !important;
  width: 5vw !important;
}

.pts-name {
  padding-left: 20px;
}

.pts-name p {
  font-size: 13px;
  font-weight: 600;
  margin-bottom: 2.5px;
}

.pts-name h5 {
  margin-left: 5px;
  font-weight: 300;
  font-size: 11px;
}

.pts-hcp .dg-icon {
  height: 20px !important;
  width: 20px !important;
}

.pts-tees {
  margin-left: 5vw;
  display: flex;
  align-items: center;
}

.pts-tee {
  margin-right: 2.5vw;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px 10px;
  border-radius: 7.5px;
}

.pts-circle {
  height: 3.5vw;
  width: 3.5vw;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 4px solid transparent;
}

.ptst-active {
  box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px,
    rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
}

.pts-tee h5 {
  font-size: 10px;
  opacity: 0.8;
  margin-top: 5px;
}

.pts-tee p {
  /* margin-top: 5px; */
  text-align: center;
  font-size: 11px;
  font-weight: 500;
  color: white;

  opacity: 0.8;
  text-transform: capitalize;
}

.ptst-active .pts-circle {
  border: 4px solid #232c2e !important;
}

.pts-all {
  display: flex;
  align-items: center;
}
.player-tee-selector .ec-box {
  /* padding: 20px; */
  align-items: center !important;
}

.pts-all button {
  margin-left: 5vw;
  /* width: 10vw; */
  padding: 10px 20px;
}

.pts-all .Dropdown-option {
  text-transform: capitalize;
}

.pts-all .Dropdown-root {
  text-transform: capitalize;
}
