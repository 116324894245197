.notification-item {
  display: flex;
  padding: 20px;
  align-items: center;
  transition: 200ms ease-in-out;
  cursor: pointer;
  justify-content: space-between;
}

.nd-nots .notification-item {
  border-bottom: 1px solid #edefef;
}

.ni-unread svg {
  fill: #1e7a69 !important;
}

.ni-box {
  display: flex;
  align-items: center;
}

.home-ni {
  cursor: initial !important;
  border-radius: 20px;
  background-color: white !important;
  margin-bottom: 20px;
}

.notification-drop .no-msg {
  margin-bottom: 10vh;
}

.notification-item:hover {
  cursor: pointer;
  background-color: #edefef;
}

.notification-item:active {
  /* transform: scale(0.95); */
}
.home-ni:active {
  /* transform: scale(0.95); */
}

.notification-drop .np-header {
  justify-content: space-between;
}

.notification-drop .np-header .green {
  font-weight: bold;
  font-size: 14px;
}

.ni-left .MuiAvatar-root {
  height: 5vw !important;
  width: 5vw !important;
}

.ni-left {
  margin-right: 20px;
}

.nir-top {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
}

.nir-top svg {
  height: 14px !important;
  fill: #1e7a69 !important;
}

.home-ni {
  box-shadow: rgb(149 157 165 / 20%) 0px 8px 24px;
  border: 1px solid #edefef;
  border-radius: 20px;
}

.ni-right h5 {
  padding-bottom: 5px;
  font-size: 14px;
  border-bottom: 3px solid #1e7a69;
  /* border-image: linear-gradient(0.25turn, #21c17c, #1e7a69 );
    border-image-slice: 1; */
  width: max-content;
  max-width: 240px;
  margin-bottom: 5px;
}

.ni-right p {
  font-size: 13px;
  opacity: 0.5;
}

.ni-right h6 {
  text-transform: uppercase;
  letter-spacing: 1px;
  font-weight: 500;
  opacity: 0.7;
  font-size: 9px;
}

.nit-close {
  justify-content: space-between;
}

.nit-close .hgb-ico {
  padding: 0px !important;
}
