.lc-box {
  padding-left: 20px;
}

.ig-header {
  display: flex;
  align-items: center;
}

.ig-header button {
  /* margin-bottom: 10px; */
  margin-left: 20px;
}

.league-admins .player-item {
  width: 30vw;
}

.league-creator .Dropdown-control {
  /* width: calc(75vw + 40px); */
}
.ec-button-row {
  display: flex;
  justify-content: center;
  margin-top: 20px;
  margin-bottom: 20px;
}

.ig-header > span {
  margin-right: 20px;
}

.ec-button-row button:active {
  transform: scale(1.1);
}

.ec-button-row button {
  color: white;
  box-shadow: 0px 2px 4px 1px rgba(0, 0, 0, 0.28);
  background-image: linear-gradient(45deg, #1e7a69 0%, #21c17c 100%);
  /* border: 1px solid #21C17C; */
  text-transform: uppercase;
  font-size: 14px;
  border: none;
  width: 7;
  font-weight: bold;
  padding: 10px 0px;
  border-radius: 4px;
  transition: ease-in-out 100ms;
  width: 70vw;
}

.ig-header button {
  color: white;
  /* background-image: linear-gradient(45deg, #1e7a69 0%, #21c17c 100%) ;   */
  border: none;
  background-color: #1e7a69;
  /* background-color: #21c17c;
    border: 1px solid #21c17c; */
  text-transform: uppercase;
  font-size: 12px;
  font-weight: bold;
  padding: 6px 20px;
  border-radius: 20px;
  transition: 50ms ease-in;
  cursor: pointer;
}

.ig-header button:active {
  transform: scale(1.05);
}

.ig-header p {
  /* width: 30vw; */
}

.league-creator {
  /* height: calc(100vh - 110px); */
  /* overflow: scroll; */
}

.image-selector button {
  color: white;
  background-color: #21c17c;
  border: 1px solid #21c17c;
  text-transform: uppercase;
  font-size: 14px;
  font-weight: bold;
  padding: 5px 20px;
  border-radius: 7px;
  transition: 50ms ease-in;
  cursor: pointer;
  /* margin-top: 15px; */
  /* width: 50vw; */
}

.image-selector button:active {
  transform: scale(1.05);
}

.league-creator .loading-container {
  height: 100% !important;
}

.admin-group .Dropdown-control {
  width: calc(20vw + 40px);
}

.lc-invited .player-item {
  width: 25vw;
}
