.bm-dia {
  width: 50vw;
}

.bm-dia textarea {
  width: 30vw !important;
}

.chat-type {
  padding-left: 20px;
  margin-bottom: 20px;
}

.chat-type p {
  font-size: 12px;
  opacity: 0.7;
  font-weight: 500;
  font-family: "MS-Med";
}

.bm-dia .loading {
  height: 40vh;
}

.bm-events {
  padding: 20px;
  display: flex;
  flex-wrap: wrap;
}

.bm-events .fixture-preview {
  cursor: pointer;
  width: 33vw;
  margin-right: 2.5vw;
}

.bm-events .fixture-preview:hover {
  background-color: #edefef;
  transform: scale(1.05);
}
