.score-details {
  display: flex;
  align-items: center;
  /* width: 90vw; */
  width: 100%;
  /* width: 100vw; */
}

.cup-score {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.s-d-left {
  flex: 0.45;
  display: flex;
  justify-content: flex-end;
}

.cs-details {
  display: flex;
  align-items: center;
  flex-direction: column;
  padding-top: 20px;
  border-radius: 7.5px;
  width: 100%;
}

.s-d-mid {
  flex: 0.1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.s-d-right {
  flex: 0.45;
  display: flex;
  /* justify-content:; */
}

.score-details h2 {
  /* color: #232c2e; */
  font-size: 16px;
  color: white;
}

.sd-today h2 {
  font-size: 14px !important;
  font-weight: 500;
  opacity: 0.7;
  color: white;
}

.today-score {
  color: white;

  margin-top: 5px;
  /* margin-bottom: 10px; */
  font-size: 12px !important;
  font-weight: 500;
  opacity: 0.7;
  /* text-decoration: underline; */
}

.c-s-box {
  width: 100%;
}

.sd-today {
  margin-bottom: 20px;
}

.c-s-notice {
  margin-bottom: 10px;
  display: flex;
  justify-content: center;
}

.c-s-notice p {
  font-size: 10px;
  margin-top: 10px;
}

.points-win {
  color: white;
  font-size: 11px;
  margin-bottom: 10px;
  font-weight: 600;
  opacity: 0.7;
}
