.singles-match {
  display: flex;
  align-items: center;
  padding: 0px 20px;
  /* margin-bottom: 20px; */
  justify-content: space-around;
}

.singles-match input {
  padding: 10px !important;
  transition: 200ms ease-in-out;
}

.cup-dashboard .sm-box {
  min-width: 75vw;
}

.cup-dashboard .singles-match {
  min-width: 75vw;
}

.sm-box {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.sm-box h4 {
  margin: 0px 10px;
}

.singles-left {
  flex: 0.45;
  display: flex;
  align-items: center;
  justify-content: center;
}

.singles-middle {
  flex: 0.1;
  display: flex;
  justify-content: center;
}

.cm-match-box {
  border-bottom: 1px solid #edefef;
  /* padding: 10px 0px; */
}

.singles-right {
  flex: 0.45;
}

.singles-right input,
.doubles-right input {
  margin-left: 20px;
}

.match-header {
  padding: 10px 20px;
  display: flex;
  align-items: center;
}

.match-header input {
  width: max-content !important;
  margin-left: 20px;
  padding: 5px !important;
}

.cup-player-selector {
  display: flex;
  flex-direction: column;
  width: calc(75vw - 20px);
  align-items: center;
}

.cps-header {
  margin: 20px 0px;
  display: flex;
  justify-content: center;
}

.cup-player {
  width: 100%;
  padding-left: 20px;
  margin-bottom: 10px;
}

.doubles-match .cm-pc {
  margin-bottom: 10px;
}

.doubles-left,
.doubles-right {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.cm-pc {
  display: flex;
  justify-content: center;
  align-items: center;
}

.cm-remove {
  /* opacity: 0.5; */
  /* margin-left: 5px; */
  height: 20px !important;
  width: 20px !important;

  margin-left: 10px;
  fill: white !important;
  border-radius: 50%;
  padding: 7.5px;
  background-color: #70242a !important;
}

.doubles-right .cm-remove {
  /* margin-left: -10px; */
}

.cm-input-wrapper {
  display: flex;
  justify-content: center;
}

.singles-right .cm-pc {
  /* flex-direction: row-reverse; */
}
.doubles-right .cm-pc {
  /* flex-direction: row-reverse; */
}

.doubles-middle {
  margin-bottom: 10px;
}

.display-mh {
  background-image: linear-gradient(90deg, #1e7a69 0%, #21c17c 100%);
  margin-bottom: 10px;
}

.display-mh h5 {
  color: white;
}

.cup-matchups {
  width: 100%;
}
