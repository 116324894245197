.group-header {
  padding-top: 20px;
  padding-left: 20px;
  margin-bottom: 20px;
}

.group-header h2 {
  font-size: 14px;
  font-weight: 600;
  color: #232c2e;
}

.gh-options {
  margin-top: 20px;
  display: flex;
  align-items: center;
}

.gsr-flex {
  display: flex;
  align-items: flex-start;
}

.gs-groups {
  display: flex;
  flex-wrap: wrap;
  padding: 20px;
  overflow: scroll;
}

.gh-options input {
  width: 10vw !important;
}

.dnd-msg {
  margin-left: 20px;
  font-size: 10px;
  opacity: 0.5;
}

.gs-row {
  display: flex;
  align-items: center;
}

.cr-group {
  padding-left: 20px;
}

.tee-numbers {
  display: flex;
}

.tee-numbers p {
  margin: 0px 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 13px;
}

.drop-group {
  width: 25vw;
  margin-right: 5vw;
  border-radius: 20px;
  margin-bottom: 40px;
  box-shadow: rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
  background-color: white;
  /* box-shadow: rgb(149 157 165 / 20%) 0px 8px 24px; */
  overflow: hidden;
}

.drop-groups ul {
  display: flex;
  flex-wrap: wrap;
}

.drop-groups {
  margin: 40px 0px;
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  padding-left: 5vw;
}

.ed-gs {
  width: 90vw !important;
}

.ed-gs .loading {
  height: 50vh;
}

.gs-empty {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px 0px;
}

.big-icon {
  height: 3vw !important;
  width: 3vw !important;
}

.gs-removed {
  display: flex;
  align-items: center;
  padding: 20px;
}

.gsr-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-right: 10px;
}

.gsr-item p {
  font-size: 10px;
  margin-top: 5px;
  opacity: 0.6;
}

.gh-flex {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.gs-rem-dia {
  width: 50vw;
}

.gsrd-players {
  padding: 20px;
}

.group-selector .Dropdown-option {
  text-transform: capitalize;
}

.group-selector .Dropdown-control {
  text-transform: capitalize;
}

.bd-container {
  /* display: flex; */
  flex-wrap: wrap;
}

/* [data-rbd-draggable-id] {
  left: auto !important;
  top: auto !important;
} */
