.event-round {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  box-shadow: rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;

  /* box-shadow: rgb(149 157 165 / 20%) 0px 8px 24px; */
  background-color: white;
  width: 36vw;
  padding: 20px;
  border-radius: 20px;
}

.er-left {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 20px;
}

.er-right p {
  font-family: "MS-SM";
  font-size: 13px;
  color: #232c2e;
  font-weight: 500;
}

.er-right {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.er-edit {
  /* opacity: 0.5; */
  fill: #21c17c !important;
  cursor: pointer;
}

.ccr-icon {
  cursor: pointer;
}

.er-delete {
  fill: #e24955 !important;
  opacity: 0.75;
  cursor: pointer;
}

.icon-cursor {
  cursor: pointer;
  transition: 200ms ease-in-out !important;
}

.icon-cursor:hover {
  /* transform: scale(1.1); */
}
