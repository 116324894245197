.club-sponsor {
  display: flex;
  box-shadow: rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;

  /* box-shadow: rgb(149 157 165 / 20%) 0px 8px 24px; */
  width: 36vw;
  border-radius: 20px;
  margin-right: 40px;
  margin-bottom: 20px;
  align-items: center;
  background-color: white;
}

.sc-left {
  display: flex;
  flex: 0.8;
  padding: 20px;
  align-items: center;
}

.club-sponsors {
  min-height: calc(100vh - 110px);
}

.club-sponsors .ig-group {
  display: flex;
  flex-direction: column;
}

.scl-img .MuiAvatar-root {
  height: 5vw !important;
  width: 5vw !important;
}

.cs-box {
  padding: 20px;
}

.scl-text {
  font-weight: 500;
  color: #232c2e;
  font-size: 14px;
  margin-left: 20px;
}

.sc-right {
  display: flex;
  flex: 0.2;
  /* justify-content: space-evenly; */
  height: 100%;
  flex-direction: column;
}

.cs-delete {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
}

.cs-delete .page-header {
  width: 100%;
}

.cs-delete p {
  color: #232c2e;
  width: 75%;
  text-align: center;
  margin-bottom: 40px;
  margin-top: 20px;
  font-family: "MS-Reg";
  font-size: 14px;
}

.cs-delete span {
  font-family: "MS-SM";
  /* font-weight: 900; */
}
.cs-select {
  cursor: pointer;
}

.cs-active {
  background-color: #c7efde;
}
