.login {
  height: 100vh;
  width: 75vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 20px;
  /* justify-content: center; */
}

.login-box h5 {
  text-transform: uppercase;
  opacity: 0.5;
  letter-spacing: 1.5px;
  word-spacing: 3px;
  margin-bottom: 20px;
  font-weight: 400;
  font-family: "MS-SM";
}

.close-row {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  padding-right: 20px;
}

.forgot-password p {
  color: #232c2e;
  opacity: 0.8;
  font-size: 14px;
  cursor: pointer;
  /* text-decoration: underline; */
  transition: 100ms ease-in-out;
  font-family: "MS-SM";
}

.forgot-password p:hover {
  opacity: 0.5;
}

.pw-reset {
  color: #232c2e;
  font-weight: 600;
  font-family: "MS-Med";
  font-size: 14px;
}

.recover-p {
}

.login-box {
  box-shadow: rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;

  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 5vw;
  border-radius: 20px;
}

.login-image img {
  height: 20vh;
}

.login-image h3 {
  margin-bottom: 5vw;
  text-transform: uppercase;
  opacity: 0.5;
  font-weight: 300;
  letter-spacing: 2px;
  word-spacing: 4px;
  text-align: center;
}

.login-image {
  display: flex;
  flex-direction: column;
  align-items: center;
}
