.payment-item {
  padding: 20px;
  margin-bottom: 20px;
  box-shadow: rgb(149 157 165 / 20%) 0px 8px 24px;
  width: 40vw;
  border-radius: 20px;
  display: flex;
}

.pi-rght {
}

.payment-history {
  /* padding: 20px; */
  min-height: calc(100vh - 110px);
}

.pi-box {
  padding: 20px;
}

.payment-item p {
  opacity: 0.6;
  font-size: 13px;
  color: #10603d;
}

.payment-item h3 {
  margin: 5px 0px;
  text-transform: uppercase;
  font-weight: 400;
  letter-spacing: 1.5px;
}

.payment-item h5 {
  font-weight: 400;
  font-size: 15px;
  opacity: 0.7;
}

.payment-item span {
  font-weight: bold;
}

.payment-history .pi-icon {
  height: 40px !important;
  width: 40px !important;
}
