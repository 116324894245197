.pill-button {
  /* background-image: linear-gradient(45deg, #1e7a69 0%, #21c17c 100%); */
  background-color: #1e7a69;
  /* background-image: linear-gradient(1deg, #0f3d35, #1e7a69 99%); */
  border-radius: 20px;
  /* border-radius: 7px; */
  padding: 10px 20px;
  /* box-shadow: rgba(0, 0, 0, 0.3) 0px 1px 3px -1px; */

  margin-right: 20px;
  /* box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px,
    rgba(0, 0, 0, 0.3) 0px 3px 7px -3px; */
  transition: 200ms ease-in-out;
  /* background-image: linear-gradient(1deg, #1e7a69, #ffffff20 99%); */
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px,
    rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;
}

.quick-links .pill-button:nth-of-type(2n):not(.red-pill-button) {
  /* CSS rules for every second div with class "pill-button" */
  background-color: #0f3d35;
  background-image: linear-gradient(1deg, #0f3d35, #ffffff20 99%);

  /* background-image: linear-gradient(1deg, #1e7a69, #0f3d35 99%); */
}

.small-but {
  font-size: 16px !important;
}

.mpl-links div.pill-button:nth-of-type(2n) {
  /* CSS rules for every second div with class "pill-button" */
  background-color: #0f3d35;
}

.pill-button:hover {
  transform: scale(1.03);
  cursor: pointer;
  opacity: 0.9;
}

.pill-button p {
  color: white !important;
  opacity: 0.95;
  font-size: 12px !important;
  text-transform: uppercase;
  font-weight: bold;
  font-family: "MS-Bold" !important;
  /* font-family: "MS-SM"; */
  margin-bottom: 0px !important;
  /* filter: drop-shadow(2px 4px 6px #232c2e72); */
}

.default-button {
  /* background-image: linear-gradient(45deg, #1e7a69 0%, #21c17c 100%); */
  background-color: #1e7a69;
  /* box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px,
    rgba(0, 0, 0, 0.3) 0px 3px 7px -3px; */
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px,
    rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;
  transition: 200ms ease-in-out;
  border: none;
  color: white;
  text-transform: uppercase;
  cursor: pointer;
  font-size: 20px;
  padding: 10px 80px;
  border-radius: 40px;
  font-family: "MS-Bold";
  font-weight: 600;
  letter-spacing: 1px;
  /* background-image: linear-gradient(1deg, #1e7a69, #ffffff20 99%); */
}

.default-button:not(.black-button) {
  background-image: linear-gradient(1deg, #1e7a69, #ffffff20 99%);
}

.sdb-box button {
  padding: 10px 20px !important;
  font-size: 14px !important;
}

.default-button:hover {
  transform: scale(1.05);
  /* opacity: 0.8; */
}

.default-button:active {
  transform: scale(0.95);
}

.default-button-row {
  padding: 20px;
}

.delete-button {
  background-image: linear-gradient(1deg, #a9363f, #ffffff20 99%) !important;

  /* background-image: linear-gradient(180deg, #F0A4AA 0%, #70242A 100%); */
  background-color: #a9363f;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px,
    rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
  transition: 200ms ease-in-out;
  border: none;
  color: white;
  font-family: "MS-Bold";
  text-transform: uppercase;
  font-size: 20px;
  padding: 10px 80px;
  border-radius: 40px;
  cursor: pointer;
  font-weight: 500;
}

.dis-pill {
  background-color: grey !important;
}

.red-pill-button {
  background-color: #a9363f !important;
}

.rem-team button {
  font-size: 14px !important;
}

button:disabled {
  background-image: none !important;
  background-color: grey;
  cursor: initial;
}

button:disabled:hover {
  transform: none !important;
}

.bw-button {
  cursor: pointer;
  text-transform: uppercase;
  font-weight: 900;
  font-family: "MS-Bold";
  /* background-color: #1e7a69; */
  box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px,
    rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;

  /* background-image: linear-gradient(45deg, #1e7a69 0%, #21c17c 100%); */
  background-color: white;
  /* border: 2px solid #232c2e; */
  border: none;
  font-size: 30px;
  color: #1e7a69;
  margin-top: 20px;
  border-radius: 40px;
  width: 40vw;
  transition: 200ms ease-in-out;
  padding: 20px 0px;
}

.black-button {
  background-color: #232c2e !important;
  background-image: linear-gradient(1deg, #232c2e, #ffffff20 99%);
}

.red-button {
  background-color: #a9363f !important;
}

.icon-button {
  cursor: pointer;
  display: flex;
  align-items: center;
  border-radius: 25px;
  border: none;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px,
    rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;
  padding: 5px 20px;
  transition: 75ms ease-in-out;
}

.icon-button:hover {
  background-color: whitesmoke;
  background-image: none !important;
}

.icon-button span {
  color: whitesmoke !important;
  font-family: "MS-Bold";
  text-transform: uppercase;
  font-size: 12px;
  transition: 75ms ease-in-out;
}

.icon-button-green {
  background-color: #1e7a69;
  border: 2px solid #1e7a69;
  background-image: linear-gradient(1deg, #1e7a69, #ffffff20 99%);
}

.icon-button-black {
  background-color: #232c2e;
  border: 2px solid #232c2e;
  background-image: linear-gradient(1deg, #232c2e, #ffffff20 99%);
}

.icon-button-red {
  background-color: #a9363f;
  border: 2px solid #a9363f;
  background-image: linear-gradient(1deg, #a9363f, #ffffff20 99%);
}

.ib-ico {
  margin-right: 10px;
}

.ib-ico svg {
  transition: 75ms ease-in-out !important;
  fill: whitesmoke !important;
}

.quick-links .icon-button {
  margin-bottom: 10px;
  margin-right: 20px;
}

.icon-button-green:hover span {
  color: #1e7a69 !important;
}

.icon-button-black:hover span {
  color: #232c2e !important;
}

.icon-button-red:hover span {
  color: #a9363f !important;
}

.icon-button-green:hover svg {
  fill: #1e7a69 !important;
}

.icon-button-black:hover svg {
  fill: #232c2e !important;
}

.icon-button-red:hover svg {
  fill: #a9363f !important;
}

.dia-but-row {
  display: flex;
  /* padding-right: 20px; */
  justify-content: flex-end;
}

.dbr-but {
  background-color: var(--green);
  border-radius: 7px;
  border: none;
  /* border: 2px solid #1e7a69; */
  color: whitesmoke;
  background-color: #0f3d35;
  /* text-transform: uppercase; */
  font-family: "MS-SM";
  padding: 10px 30px;
  cursor: pointer;
  box-sizing: content-box;
  transition: 75ms ease-in-out;
}

.dbr-but-red {
  background-color: #a9363f !important;
}

.dbr-but:hover {
  /* background-color: #edefef; */
  /* background-color: transparent; */
  /* color: #232c2e !important; */
  /* color: #1e7a69 !important; */
  /* background-color: #0f3d35; */
  opacity: 0.8;
}

.dbr-txt-but {
  background-color: transparent;
  border: none;
  border-radius: 7px;
  color: #232c2e;
  cursor: pointer;
  font-family: "MS-SM";
  padding: 10px 30px;
  transition: 75ms ease-in-out;
  /* appearance: none; */
}

.dbr-txt-but:hover {
  background-color: #edefef;
}

.dtb-red {
  /* color: #a9363f !important; */
}
