.drop-item {
  list-style-type: none;
  cursor: pointer;
  /* border: 1px solid; */
  /* border-right: 1px solid; */
  transition: 100ms ease-in-out;
  /* padding: 2.5px 5px; */
  min-width: max-content;
  color: #232c2ecf;
  /* border-radius: 10px; */
  font-size: 13px;
  display: flex;
  align-items: center;
  /* justify-content: center; */
  font-weight: 400;
}

.drop-item .di-div {
  display: flex;
  align-items: center;
  padding: 5px 10px;
  /* border-radius: 15px; */
}

.drop-item .di-div:hover {
  background-color: #6ba390;
  color: whitesmoke;
  /* background-color: #209e7359; */
}

.di-active .drop-item .di-div {
  background-color: #6ba390;
  /* background-color: #209e7359; */
  color: whitesmoke;
}

.drop-item-ico {
  transform: rotate(0deg);

  transition: 200ms ease;
}

.di-active .drop-item-ico {
  transition: 200ms ease 50ms;
}

.di-active .drop-item-ico {
  transform: rotate(-180deg);
}

.drop-menu {
  margin-left: 20px;
  display: flex;
  align-items: center;
  /* border-bottom: 1.5px solid; */
  border-right: none;
  width: max-content;
  /* border-radius: 20px; */
  overflow: hidden;
  background-color: white;
}

.sub-drop {
  position: absolute;
  width: 10vw;
  border-top: none;
  background-color: #f8f8f8;
  border: 1px solid #edefef;
  max-height: 0;
  overflow: hidden;
  visibility: hidden;
  margin-top: 2px;
  /* border-radius: 10px; */
  z-index: 100;

  /* transition: all 0.5s ease  -in-out; */
  /* margin-left: -11px; */
  /* top: 10px; */
  /* margin-top: 5px; */
}

.drop-menu:hover .drop-item-ico {
  transform: rotate(180);
}

.sd-exp {
  visibility: visible;
  max-height: 1000px; /* Adjust to a suitable maximum height */
}

.sub-drop-item {
  /* cursor: pointer; */
  border-bottom: 1px solid #edefef;
  background-color: white;
  /* width: 100%; */
  /* border-right: 1px solid; */
  /* padding: 5px 12px 5px 10x; */
  font-size: 13px;
  /* padding: 5px; */
  display: flex;
  align-items: center;
  /* justify-content: space-between; */
  color: #232c2ecf;
}

.sub-drop-item:not(:last-child) {
  border-right: 1px solid #edefef;
}

.sub-drop-item p:hover {
  background-color: #6ba390;
  color: whitesmoke;
  /* border-radius: 10px; */
  cursor: pointer;
  /* background-color: #209e7359; */
}

.sub-drop-item p {
  width: 100%;
  padding: 7.5px 10px;
  /* padding-left: 10px; */
}

.drop-me-enter {
  transform: translateY(-110%);
}

.drop-me-enter-active {
  transform: translateY(0%);
}
.drop-me-exit {
}
.drop-me-exit-active {
}

.dm-mw .drop-item {
  width: max-content !important;
}
