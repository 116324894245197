.chat-preview {
  display: flex;
  /* width: 100%; */
  border-bottom: 2px solid #edefef;
  padding: 20px 0px;
  cursor: pointer;
  transition: 250ms ease-in-out;
}

.chat-preview:hover {
  background-color: #edefef;
}

.cp-left {
  flex: 0.2;
  display: flex;
  justify-content: center;
  align-items: center;
}

.cp-middle {
  display: flex;
  flex: 0.6;
  flex-direction: column;
}

.cp-middle h3 {
  font-weight: 600;
  font-size: 13px;
  line-height: 17px;

  /* Black/Black 50 */

  color: #232c2e;
}

.cp-middle p {
  font-weight: normal;
  font-size: 12px;
  line-height: 17px;
  /* identical to box height */
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  /* width: 60vw; */
  /* Black/Black 40 */
  max-width: 20vw;
  margin-top: 5px;
  color: #5c6363;
}

.cp-right {
  display: flex;
  flex-direction: column;
  flex: 0.2;
  align-items: center;
}

.cp-right h5 {
  font-weight: normal;
  font-size: 12px;
  line-height: 15px;
  /* identical to box height */

  text-align: right;

  /* Black/Black 40 */

  color: #5c6363;
}

.cpr-unreads {
  margin-top: 5px;
  background: #1e7a69;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.cpr-unreads p {
  font-size: 12px;
  line-height: 15px;
  /* identical to box height */

  text-align: center;

  /* White/White */

  color: #ffffff;
}
