.pd-dia-ctt {
  width: 50vw;
  min-height: 50vh;
}

.dispute-row {
  display: flex;
  border-bottom: 1px solid #edefef;
  padding: 5px 0px;
  align-items: center;
}

.dr-item {
  flex: calc(1 / 3);
}

.dr-header {
  border-bottom: 2px solid #232c2e;
}

.dr-header p {
  font-size: 14px;
  font-family: "MS-SM" !important;
}

.dr-item p {
  font-size: 13px;
  font-family: "MS-Reg";
}

.dr-stat {
  text-transform: capitalize;
}
