.si-tit h3 {
  color: #232c2e;
  font-size: 13px;
  font-weight: 500;
}

.client-stats .stats-item {
  justify-content: space-between;
  width: calc(28vw - 40px);
  padding: 15px 20px;
}

.si-right p {
  font-size: 13px;
  color: #232c2e;
}

.search-input input {
  width: 100% !important;
  margin-right: 10px;
}

.si-comp {
  /* margin-left: 20px; */
}

.stats-item .si-right > div {
  width: 3vw;
  display: flex;
  /* justify-content: flex-end; */
  align-items: center;
  justify-content: center;
}

.si-sta {
  justify-content: center;
}

.si-comp {
  justify-content: flex-end;
}

.stats-item .si-right {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.rs-box {
  justify-content: space-between;
}
.si-grey {
  background-color: rgba(245, 245, 245, 0.733);
}

.sic-bet p {
  color: #6ba390;
}

.sic-wor p {
  color: #db5461;
}
