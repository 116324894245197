.round-item {
  display: flex;
  align-items: center;
  background-color: white;
  border-radius: 20px;
  width: 50vw;
  padding: 20px;
  margin-bottom: 20px;
  justify-content: space-between;
  transition: 100ms ease-in-out;
  cursor: pointer;
  /* border: 0.5px solid #ced4da; */
}

.ri-sub-text p {
  color: #232c2e;
  font-size: 14px;
}

.ri-sub-text {
  margin-bottom: 20px;
}

.crl-smol .round-item {
  width: 25vw !important;
}

.crl-smol .round-item h2 {
  font-size: 12px !important;
}

.crl-smol .rir-item {
  width: 3vw !important;
}

.crl-smol .rir-item p {
  font-size: 12px !important;
}

.crl-smol .rir-item h4 {
  font-size: 13px;
}

.round-item .ri-right {
  display: flex;
  align-items: center;
}

.rir-item {
  width: 5vw;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.ri-left h2 {
  color: #232c2e;
  font-size: 500;
  font-size: 14px;
  font-family: "MS-SM";
}

.rir-item p {
  font-size: 12px;
}

.ri-left h3 {
  color: #232c2e;
  font-size: 13px;
  font-family: "MS-Reg";
}

.client-rounds .cr-left {
  padding: 20px;
}

.round-item:hover {
  box-shadow: rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;

  /* box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px; */
}

.round-item:active {
  /* transform: scale(0.95); */
}

.ri-act {
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  /* background-color: #6ba39027; */
  background-color: #1e7a69;
}

.ri-act h2,
.ri-act h3,
.ri-act h4,
.ri-act p {
  color: whitesmoke !important;
}

.rir-item h4 {
  color: #232c2e;
  font-size: 14px;
}
