.im-players {
  padding: 2.5vw 20px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.im-dialog {
  min-width: 80vw;
  min-height: 50vh;
  transition: 200ms ease-in-out;
}

.im-players .player-item {
  width: 18vw;
  margin-right: 2.5vw;
  /* transform: scale(0.75); */
}

.im-msg {
  padding-left: 20px;
}

.im-msg p {
  font-size: 11px;
  opacity: 0.5;
}

.im-dialog .player-item .MuiAvatar-root {
  width: 50px !important;
  height: 50px !important;
}

.epb-left .ig-header h3 {
  width: 12vw;
}

.im-dialog .MuiPaper-root::before {
  height: 0px !important;
}

.im-dialog .MuiPaper-root {
  margin: 0px 20px 40px 20px !important;
}

.im-dialog .player-item {
}

.MuiPaper-rounded {
  max-width: none !important;
}

.im-dialog .default-button-row {
  /* justify-content: center; */
  /* display: flex; */
}

.im-dialog .default-button {
  /* width: 50vw; */
}

.im-dialog .page-header {
  display: flex;
  justify-content: space-between;
}

.close-icon {
  cursor: pointer;
  transition: 200ms ease-in-out !important;
}

.close-icon:hover {
  opacity: 0.5;
  transform: scale(1.2);
}

.im-dialog .ig-group .radio-group {
  margin-top: 0px !important;
  padding-bottom: 0px !important;
}

.epr-ctt .ig-group .radio-group {
  margin-top: 0px !important;
  padding-bottom: 0px !important;
}
