.course-item {
  display: flex;
  box-shadow: rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;

  /* box-shadow: rgb(149 157 165 / 20%) 0px 8px 24px; */
  margin: 20px 20px;
  padding: 10px 20px;
  border: 1px solid #edefef;
  /* background-image: linear-gradient(225deg, #efefef 0%, #fff 50%); */
  cursor: pointer;
  transition: 200ms ease-in-out;
  width: 40vw;
  border-radius: 20px;
  background-color: white;
}

.ci-right {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.course-item:hover {
  background-color: #edefef;
  transform: scale(1.02);
}

.ci-bg {
  background-color: rgb(199, 239, 222);
}

.course-item:active {
  /* background-color: #EDEFEF; */
  opacity: 0.8;
}

.ci-left img {
  height: 72px;
  width: 72px;
  border-radius: 10px;
  object-fit: cover;
}

.ci-right {
  padding-left: 15px;
}

.ci-right .ne-header {
  opacity: 0.8 !important;
}

.ci-right h1 {
  font-weight: 500 !important;
  font-size: 13px !important;
  line-height: 17px;
  /* Black/Black 50 */
  margin: 5px 0px 0px 0px !important;
  color: #232c2e;
  padding-bottom: 5px;
  width: max-content;
  max-width: 50vw;
  /* border-bottom: 3px solid #90E0BD; */
  border-bottom: 2px solid #137a69;
  /* font-weight: 600; */
}

.league-cuntry {
  display: flex;
  align-items: center;
}

.league-cuntry span {
  height: 12px;
}

.league-cuntry p {
  /* margin-left: 10px !important; */
}

.league-cuntry span {
  margin-right: 10px;
}

.ci-right p {
  font-size: 12px;
  line-height: 15px;
  margin: 5px 0px;
  /* identical to box height */
  font-weight: 400;
  opacity: 0.5;
  /* Black/Black 40 */
  color: #232c2e;
  /* color: #5c6363; */
  font-family: "Inter" !important;
}

.ci-rating {
  display: flex;
  align-items: center;
}

.ci-star {
  /* fill: #21C17C !important; */
  margin-bottom: 2px;
  height: 20px !important;
}

.ci-rating p {
  font-weight: bold;
}

.course-item .ci-left {
  display: flex;
  justify-content: center;
  align-items: center;
}
