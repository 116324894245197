.cup-nassau {
    /* background-color: #E9F8F2; */
    border-radius: 10px;
    box-shadow: rgb(149 157 165 / 20%) 0px 8px 24px;
    flex-direction: column;
}

.white-mp p {
    color: white !important;
}

.cup-nassau {
margin-top: 10px;
}

.cup-nassau .matchplay-score {
    margin-bottom: 0px !important;
    border-radius: 10px;
    border: none !important;
    box-shadow: none !important;
}
