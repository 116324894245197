.cmc-sent {
  /* width: 100vw; */
  display: flex;
  justify-content: flex-end;
}

.cmc-rec {
  display: flex;
  /* width: 100vw; */
  justify-content: flex-start;
}

.chat-message {
  border-radius: 8px;
  padding: 10px;
  max-width: 75vw;
  display: flex;
  /* box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25); */
}

.cm-sent {
  position: relative;
  background: #1e7a69;
  margin-right: 30px;
  margin-top: 20px;
}

.cm-received {
  position: relative;
  background: #f5f7f8;
  margin-left: 30px;
  margin-top: 20px;
  flex-direction: row-reverse;
}

.cm-sent .cm-msg p {
  color: white;
  font-size: 12px;
}

.cm-sent .cm-time p {
  opacity: 0.6;
  color: white;
  font-size: 10px;
  margin-right: 10px;
}

.cm-received .cm-msg p {
  /* color: white; */
  margin-right: 20px;
  font-size: 12px;
}

.cm-received .cm-time p {
  opacity: 0.6;
  /* color: white; */
  font-size: 10px;
  margin-right: 10px;
}

.cm-time {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.cm-msg {
}

.cm-sent:after {
  content: "";
  position: absolute;
  right: 0;
  bottom: 0;
  width: 0;
  height: 0;
  border: 20px solid transparent;
  border-left-color: #1e7a69;
  border-right: 0;
  border-bottom: 0;
  margin-top: -18.5px;
  margin-right: -10px;
}

.cm-received:after {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0px;
  width: 0;
  height: 0;
  border: 20px solid transparent;
  border-right-color: #f5f7f8;
  border-left: 0;
  border-bottom: 0;
  margin-top: -18.5px;
  margin-left: -10px;
}

.gcm-box .cm-received {
  margin-top: 0px !important;
}

.gcm-box .cm-sent {
  margin-top: 0px !important;
}

.gcm-box {
  padding-top: 10px;
}

.cmc-sent .cm-name {
  justify-content: flex-start;
  /* margin-right: 20px; */
}

.cm-name {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 5px;
}

.cm-name p {
  font-size: 10px;
  opacity: 0.5;
}
