
.course-square {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    border-radius: 10px;
    height: 25vh;
    min-width: 15vw;
    flex: 0.4;
    margin-right: 20px;
    /* filter: grayscale(50%); */
    background-position: center;
    background-size: cover;
    cursor: pointer;
    transition: 200ms ease-in-out;
}

.course-square:hover {
    opacity: 0.8;
}

.course-square-content {
    /* position: absolute; */
    /* content:""; */
    border-radius: 10px;
    background: linear-gradient(to bottom, rgba(255,0,0,0) 0%,rgba(0, 0, 0, 0.65) 100%);
}

.cs-header h1 {
    color: white !important;
    font-size: 14px !important;
    margin: 0px !important;
    width: 90%;

}

.cs-deets {
display: flex;
align-items: center;
padding-left: 10px;
padding-bottom: 10px;
}

.cs-deets p {
    font-size: 12px;
    opacity: 0.7;
    color: white;
}

.cs-star {
    /* fill: #21c17c !important; */
    height: 20px !important;
    width: 20px !important;
}

.cs-header {
    padding-left: 10px;
    word-wrap: normal;
    white-space: normal;
}

