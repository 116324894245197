.ecl-item {
  display: flex;
  align-items: center;
  width: 100%;
}

.ecl-left {
  display: flex;
  align-items: center;
}

.eph-row {
  display: flex;
  align-items: center;
}

.eph-bord {
  border-bottom: 1px solid #232c2e53;
}

.eph-holes {
  display: flex;
  align-items: center;
  /* padding: 10px; */
}

.eph-nine {
  /* margin-bottom: 20px; */
  /* border-radius: 20px; */
}

.eph-hole {
  width: 7.5vw;
  height: 7.5vw;
  display: flex;
  justify-content: center;
  align-items: center;
}

.eph-hole p {
  font-size: 12px;
}

.ep-holes {
  display: flex;
  flex-direction: column;
  align-items: center;
  /* padding: 10px; */
}

.ephh-icon {
  font-size: 1em !important;
  fill: #1e7a69;
}

.ep-hole-head {
  width: 15vw;
}

.ep-hole-head p {
  font-size: 12px;
  font-weight: 500;
  color: #232c2e;
  opacity: 0.8;
}

.birdie p {
  color: #209e73;
  font-weight: 600;
}
.eagle p {
  color: #209e73;
  font-weight: 600;
}

.eph-holes .eagle {
  border: 2px solid #209e73;
  border-radius: 50%;
  padding: 2.5px;
}

.bogey p {
  color: #a9363f;
  font-weight: 600;
}

.double p {
  color: #a9363f;
  font-weight: 600;
}

.eph-hole .albatross {
  background-color: #209e73;
  padding: 2.5px;
  border: 2px solid #209e73;
  border-radius: 50%;
}

.albatross p {
  font-weight: 600;
  color: whitesmoke;
}

.eph-hole .triple {
  background-color: #a9363f;
  padding: 2.5px;
  border: 2px solid #a9363f;
}

.triple p {
  color: whitesmoke;
  font-weight: 600;
}

.ep-holes-box {
  /* box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px; */
  /* border-radius: 20px; */
  /* padding: 5px; */
}

.eph-num-row {
  /* background-color: whitesmoke; */
  /* border-radius: 20px 20px 0px 0px; */
}

.ep-hole-head {
  padding-left: 2.5px;
}

.ep-score {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.ep-score h3 {
  color: #232c2e;
  font-size: 14px;
  width: 35vw;
}

.ep-score p {
  font-size: 14px;
  color: whitesmoke;
  font-weight: 600;
}

.eps-box {
  background-color: #1e7a69;
  border-radius: 50%;
  height: 7.5vw;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 7.5vw;
}

.eph-total {
  font-weight: 600;
  background-color: #1e7a69;
}

.eph-total p {
  color: whitesmoke;
}

.eph-holes .double {
  border: 2px solid #a9363f;
  /* border-radius: 50%; */
  padding: 2.5px;
}

/* .eph-hole */

.eph-par {
  font-weight: 600;
  color: #232c2e;
}

.eph-par-row {
  background-color: whitesmoke;
}

.eph-num {
  font-weight: 700;
  opacity: 0.5;
  color: #232c2e;
}

.eph-par p {
  font-weight: 500;
}

.par p {
  font-weight: 600;
  color: #232c2e;
}
