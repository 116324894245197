.members-container {
  padding: 20px;
  display: flex;
  flex-wrap: wrap;
}

.members-container .player-item {
  margin-right: 2.5vw;
  width: 25vw;
}

.league-edit {
  min-height: calc(100vh - 80px);
  background-color: #f5f5fac1;
  padding-top: 20px;
}

.league-edit .back-row {
  margin-top: 0px !important;
}

.league-edit .ch-links {
  width: 100% !important;
}

.league-edit .radio-group {
  margin-top: 20px;
}

.lm-joined {
  justify-content: flex-start !important;
  margin-bottom: 20px;
  box-shadow: rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;

  /* box-shadow: rgb(149 157 165 / 20%) 0px 8px 24px; */
  border: 1px solid #edefef;
  border-radius: 20px;
}

.stats-container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 40px;
}

.stats-box {
  box-shadow: rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;

  /* box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px,
    rgba(14, 30, 37, 0.32) 0px 2px 16px 0px; */
  border-radius: 20px;
  padding: 20px;
  width: 80vw;
  background-color: white;
  margin-bottom: 40px;
}

.stats-item .ps-left {
  flex: none !important;
}

.league-stats .Dropdown-control {
  /* min-width: 30vw; */
}

.le-top {
  display: flex;
  flex-direction: column;
}

.le-top .quick-links {
  padding-top: 0px !important;
  flex-wrap: wrap;
}

.le-top .pill-button {
  margin-top: 20px;
}

.let-right {
  display: flex;
  /* padding-left: 20px; */
  flex-direction: column;
  justify-content: flex-end;

  /* align-items: flex-end; */
}

.let-left {
  min-width: 50vw;
}

.league-stats .default-button {
  font-size: 14px !important;
  padding: 10px 40px !important;
  margin-right: 5vw;
  text-decoration: none;
}

.ltl-item {
  display: flex;
  align-items: center;
  /* width: 100%; */
  padding: 10px;
  /* border-bottom: 1px solid #edefef; */
}

.ltld-bot {
  display: flex;
  align-items: center;
  overflow: hidden;
}

.ltld-top h3 {
  font-family: "Inter";
  color: #232c23;
  font-size: 12px;
  font-weight: 600;
  margin-bottom: 2.5px;
}

.ltl-box {
  /* padding: 20px; */
}

.ltl-pl p {
  font-family: "Inter";
  color: #232c23;
  font-size: 10px;
  margin-right: 2.5px;
  opacity: 0.7;
}

.ltl-rank {
  flex: 0.1;
}

.ltl-rank p {
  color: #232c23;
  font-family: "Inter";
  font-size: 12px;
  font-weight: 600;
}

.ltl-ava {
  flex: 0.1;
}

.ltl-det {
  flex: 0.7;
  margin-left: 5px;
}

.ltl-pts {
  flex: 0.1;
}

.ltl-pts p {
  color: #232c23;
  font-family: "Inter";
  font-size: 12px;
  text-align: center;
  font-weight: 600;
}

.ltl-head p {
  color: #232c2e;
  font-family: "Inter";
  /* opacity: 0.7; */
  font-size: 11px;
  font-weight: 600 !important;
}

.ltl-head {
  /* border-bottom: 1px solid #edefef; */
}
