.round-card {
  margin-top: 20px;
  /* background-color: white; */
}

.rc-sc th {
  font-weight: 500;
  font-size: 13px;
  text-align: center;
  width: 6vw;
  padding: 7.5px 0px;
  color: #232c2e;
}

.rc-sc th {
  /* display: flex; */
  /* justify-content: center; */
}

.rc-sc {
  background-color: white;
}

.rc-sc td {
  text-align: center;
  width: 6vw;
  font-size: 14px;
  padding: 5px 0px;
  height: 6vh;
}

.sum-tr td {
  font-weight: 500 !important;
}

.rc-head-ico {
  display: flex;
  justify-content: center;
}

.ico-td {
  display: flex;
  align-items: center;
  justify-content: center;
}

.succ-ico {
  fill: #6ba390;
}

.fail-ico {
  fill: #db5461;
}

.ud-par {
  color: #6ba390 !important;
}

.over-par {
  color: #db5461 !important;
}

.par {
  color: #232c2e;
}

td .eag {
  border: 1.5px solid #6ba390;
  border-radius: 50%;
  width: 2vw;
  height: 2vw;
  display: flex;
  background-color: #6ba390;
  color: whitesmoke;
  align-items: center;
  justify-content: center;
}

td .bir {
  border: 1.5px solid #6ba390;
  border-radius: 50%;
  width: 2vw;
  height: 2vw;
  display: flex;
  align-items: center;
  justify-content: center;
}

.sc-box {
  display: flex;
  justify-content: center;
}

td .bog {
  border: 1.5px solid #db5461;
  border-radius: 50%;
  width: 2vw;
  height: 2vw;
  display: flex;
  align-items: center;
  justify-content: center;
}

.rc-row:nth-of-type(odd) {
  background-color: rgba(245, 245, 245, 0.486);
}

.sum-ou {
  background-color: whitesmoke;
}

.sum-tr {
  padding: 5px 0px;
}

td .dbl {
  border: 1.5px solid #db5461;
  background-color: #db5461;
  color: whitesmoke;
  border-radius: 50%;
  width: 2vw;
  height: 2vw;
  display: flex;
  align-items: center;
  justify-content: center;
}

.rs-box {
  display: flex;
  align-items: center;
  padding: 0px 20px;
  margin-bottom: 20px;
  justify-content: flex-start;
}

.rsb-text {
  display: flex;
  align-items: center;
}

.rsb-text p {
  font-size: 12px;
  /* width: max-content; */
}

.rsb-text span {
  font-size: 12px;
  margin: 0px 2px;

  width: max-content;
}

.rs-bad {
  color: #e24955;
  font-weight: 600;
  /* text-decoration: underline; */
}

.rs-good {
  color: #209e73;
  font-weight: 600;
  /* text-decoration: underline; */
}

.rs-msg p {
  font-size: 10px;
  opacity: 0.8;
}

.rs-msg {
  padding: 20px 0px 0px 20px;
}

.round-summary {
  background-color: white;
  padding: 20px;
  min-width: 60vw;
}

.ne-header {
  color: #232c2e;
  margin-bottom: 10px;
  font-size: 16px;
  font-weight: 400;
  /* letter-spacing: 0.8px; */
  /* opacity: 0.6; */
  /* text-transform: uppercase; */
}

.rc-ifo {
  background-color: white;
  width: max-content;
  padding: 20px;
  margin-bottom: 20px;
}

.rci-hcp p {
  color: #232c2e;
  font-size: 14px;
  font-weight: 400;
  width: 13vw;
}

.rci-hcp {
  display: flex;
  align-items: center;
}

.rci-hcp h5 {
  font-size: 14px;
  color: #232c2e;
  font-weight: 500;
  text-transform: capitalize;
}

.round-stats {
  background-color: white;
  padding: 20px;
  width: 60vw;
}

.rc-sc td {
  color: #232c2e;
}
