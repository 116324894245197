@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600&display=swap");
@font-face {
  font-family: "MS-Bold";
  src: url("./assets/fonts/Montserrat-Bold.otf");
}
/* @font-face {
  font-family: "MS-Bold";
  src: url("./assets/fonts/TT-Norms-Fonts/TTNorms-Bold.otf");
} */
@font-face {
  font-family: "MS-Reg";
  src: url("./assets/fonts/Montserrat-Regular.otf");
}
/* @font-face {
  font-family: "MS-Reg";
  src: url("./assets/fonts/TT-Norms-Fonts/TTNorms-Regular.otf");
} */
/* @font-face {
  font-family: "MS-Med";
  src: url("./assets/fonts/Montserrat-Medium.otf");
} */
@font-face {
  font-family: "MS-Med";
  src: url("./assets/fonts/TT-Norms-Fonts/TTNorms-Medium.otf");
}
@font-face {
  font-family: "MS-SM";
  src: url("./assets/fonts/Montserrat-SemiBold.otf");
}
/* @font-face {
  font-family: "MS-SM";
  src: url("./assets/fonts/TT-Norms-Fonts/TTNorms-Medium.otf");
} */
@font-face {
  font-family: "TT-Med";
  src: url("./assets/fonts/TT-Norms-Fonts/TTNorms-Medium.otf");
}
@font-face {
  font-family: "TT-Bold";
  src: url("./assets/fonts/TT-Norms-Fonts/TTNorms-Bold.otf");
}
@font-face {
  font-family: "TT-Reg";
  src: url("./assets/fonts/TT-Norms-Fonts/TTNorms-Regular.otf");
}
@font-face {
  font-family: "TT-Light";
  src: url("./assets/fonts/TT-Norms-Fonts/TTNorms-Light.otf");
}
@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Raleway:wght@100;200;300;400;500;600&display=swap");
html {
  font-family: "Inter", sans-serif;
}

.poppins-thin {
  font-family: "Poppins", sans-serif;
  font-weight: 100;
  font-style: normal;
}

.poppins-extralight {
  font-family: "Poppins", sans-serif;
  font-weight: 200;
  font-style: normal;
}

.poppins-light {
  font-family: "Poppins", sans-serif;
  font-weight: 300;
  font-style: normal;
}

.poppins-regular {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-style: normal;
}

.poppins-medium {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-style: normal;
}

.poppins-semibold {
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  font-style: normal;
}

.poppins-bold {
  font-family: "Poppins", sans-serif;
  font-weight: 700;
  font-style: normal;
}

.poppins-extrabold {
  font-family: "Poppins", sans-serif;
  font-weight: 800;
  font-style: normal;
}

.poppins-black {
  font-family: "Poppins", sans-serif;
  font-weight: 900;
  font-style: normal;
}

.poppins-thin-italic {
  font-family: "Poppins", sans-serif;
  font-weight: 100;
  font-style: italic;
}

.poppins-extralight-italic {
  font-family: "Poppins", sans-serif;
  font-weight: 200;
  font-style: italic;
}

.poppins-light-italic {
  font-family: "Poppins", sans-serif;
  font-weight: 300;
  font-style: italic;
}

.poppins-regular-italic {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-style: italic;
}

.poppins-medium-italic {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-style: italic;
}

.poppins-semibold-italic {
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  font-style: italic;
}

.poppins-bold-italic {
  font-family: "Poppins", sans-serif;
  font-weight: 700;
  font-style: italic;
}

.poppins-extrabold-italic {
  font-family: "Poppins", sans-serif;
  font-weight: 800;
  font-style: italic;
}

.poppins-black-italic {
  font-family: "Poppins", sans-serif;
  font-weight: 900;
  font-style: italic;
}

.ml-10 {
  margin-left: 10px !important;
}

* {
  margin: 0px;
  outline-width: 0;

  /* background-color: #21c17c; */
  /* color: white !important; */
}
/* 
body {
  background-color: #f5f5fac1;
} */

.cm-ctt .page-header {
  width: calc(100% - 40px);
}

input {
  font-family: "Inter", sans-serif;
}
textarea {
  font-family: "Inter", sans-serif;
  resize: none;
}

.default-select {
  width: calc(20vw + 40px) !important;
}

.cur-poi {
  cursor: pointer;
}

p,
h1,
h2,
h3,
h4,
h5,
h6,
span,
textarea,
li {
  color: #232c2e;
}

.back-arrow {
  cursor: pointer;
  padding: 7.5px;
}

.back-arrow:hover {
  /* opacity: 0.5; */
  background-color: #edefef;
  border-radius: 50%;
  /* padding: 7.5px; */
}

.App {
  /* background-color: #f5f5fac1; */
  /* width: 100vw; */
  min-height: 100vh;
  font-family: "Inter", "sans-serif";
}

.tabs-container .MuiTab-root {
  background-color: white;
}

.tabs-container .MuiTab-root:hover {
  background-color: #edefef;
}

.err-icon {
  height: 40px !important;
  width: 40px !important;
  margin-top: 40px;
}

body {
  margin: 0px !important;
  /* background-image: linear-gradient(45deg, #f8fdfb92 0%, #F8FDFB 100%); */
  background-color: #f8fdfb69;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  background-color: white !important;
  /* -webkit-box-shadow: 0 0 0 30px white inset !important; */
}

.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.flex-center-col {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.flex-wrapper {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.mpa-fin p {
  opacity: 0.7;
  margin-top: 40px;
  font-size: 14px;
}

.big-tick {
  height: 20vw !important;
  width: 20vw !important;
}

.pt-0 {
  padding-top: 0px !important;
}

.pl-0 {
  padding-left: 0px !important;
}

.di-num {
  width: 5vw !important;
}

.pl-20 {
  padding-left: 20px !important;
}

.p-20 {
  padding: 20px;
}

.pb-20 {
  padding-bottom: 20px;
}

.pd-40 {
  padding: 40px;
}

.mb-5 {
  margin-bottom: 5px;
}

.mb-10 {
  margin-bottom: 10px;
}
.mb-20 {
  margin-bottom: 20px !important;
}
.MuiSnackbarContent-root {
  border-radius: 20px !important;
  font-weight: 900 !important;
  background-color: #1e7a69 !important;
}

.mt-10 {
  margin-top: 10px;
}
.mt-20 {
  margin-top: 20px !important;
}

.mr-10 {
  margin-right: 10px;
}

.mr-20 {
  margin-right: 20px !important;
}

.mb-40 {
  margin-bottom: 40px !important;
}

.MuiTabs-indicator {
  /* background-color: #21c17c !important; */
  /* background-color: #18905C !important; */
  /* background-color: #209e73 !important; */
  display: none;
}

.flex-jc {
  display: flex;
  justify-content: center;
}

.no-line {
  text-decoration: none !important;
  cursor: initial !important;
}

.MuiTab-root {
  font-family: "MS-Bold" !important;
  color: #1e7a69 !important;
  font-size: 12px !important;
}

.tabs-container .Mui-selected {
  background-image: linear-gradient(1deg, #1e7a69, #ffffff20 99%);
}

.Mui-selected {
  color: #fff !important;
  font-family: "MS-Bold" !important;

  background-color: #1e7a69 !important;
  /* background-image: linear-gradient(1deg, #1e7a69, #ffffff20 99%); */

  /* box-shadow: rgba(9, 30, 66, 0.25) 0px 4px 8px -2px, rgba(9, 30, 66, 0.08) 0px 0px 0px 1px; */
  /* position: relative !important; */
  /* box-shadow: rgb(149 157 165 / 70%) 0px 4px 12px; */
  /* border-top-right-radius: 10px !important; */
}

.mr-5v {
  margin-right: 5vw;
}
.ml-5v {
  margin-left: 5vw;
}

.tabs-container {
  margin-left: 20px;
  /* border-bottom: 1px solid #edefef; */
  border-radius: 20px;
  overflow: hidden;
  width: max-content;
  box-shadow: rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;

  /* box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px,
    rgba(0, 0, 0, 0.3) 0px 3px 7px -3px; */
  /* box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset; */
  /* background-image: linear-gradient(160deg, #fff 0%, #edefef 100%); */
  /* border-top-right-radius: 10px; */
  width: max-content;
  /* border-top: 1px solid #edefef; */
  /* background-color: #F8FDFB; */
}

.switch-row {
  display: flex;
  align-items: center;
  padding: 10px;
  box-shadow: 0 2px 5px -3px grey;
  /* width: 40vw; */
  padding-bottom: 10px;
}

.switch-row .ios-switch {
  margin: 0px 5px;
  transform: scale(0.75);
}

.switch-row p {
  color: #5c6363;
  font-size: 12px;
  /* font-weight: 500; */
}

.fp-cont {
  /* width: 40vw; */
  padding: 20px;
  display: flex;
  flex-wrap: wrap;
}

.fp-cont .fixture-preview {
  width: 40vw;
  margin-right: 5vw;
}

.sub-header {
  padding: 20px 0px 0px 20px;
}

.sub-header h3 {
  font-family: "MS-SM";
  color: #232c2e;
  /* padding-left: 10px; */
  font-size: 16px;
  /* border-left: 5px solid #1e7a69; */
  /* border-radius: 5px 0px 0px 5px; */
}

.quick-links {
  display: flex;
  align-items: center;
  padding: 10px 20px;
  flex-wrap: wrap;
}

.quick-links .pill-button {
  margin-bottom: 10px;
}

.MuiLinearProgress-root {
  background-color: #1e7a695e !important;
}

.MuiLinearProgress-bar {
  background-color: #1e7a69 !important;
  /* background-image: linear-gradient(45deg, #1e7a69 0%, #21c17c 100%); */
}

.left-radius {
  border-radius: 8px 0px 0px 8px !important;
}

.right-radius {
  border-radius: 0px 8px 8px 0px !important;
}

.svg-cont {
  display: flex;
  align-items: center;
  justify-content: center;
  /* margin-bottom: 10px; */
}

.pd-20 {
  padding: 20px;
}
.scoring-switch {
  transform: scale(0.8);
}

.info-message .page-header {
  width: 100%;
}

.Dropdown-control {
  background-color: white !important;
  box-shadow: rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;

  /* box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
    rgba(60, 64, 67, 0.15) 0px 1px 3px 1px; */
  /* box-shadow: 0px 2px 2px 1px rgb(0 0 0 / 28%); */
  border: none !important;
  border: 1px solid #8e91917e !important;
  font-size: 12px;
  border-radius: 20px !important;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 9.75px 20px !important;
  color: #5c6363;
}

.Dropdown-control:hover {
  box-shadow: 0px 2px 2px 1px rgb(0 0 0 / 28%) !important;
}
.default-input:hover {
  box-shadow: 0px 2px 2px 1px rgb(0 0 0 / 28%) !important;
}
.dz-sel:hover {
  box-shadow: 0px 2px 2px 1px rgb(0 0 0 / 28%) !important;
}

.info-icon {
  cursor: pointer;
}

.Dropdown-menu {
  border: 1px solid #f5f7f8 !important;
  border-radius: 8px !important;
  margin-top: 5px !important;

  /* background: #F5F7F8 !important; */
  box-shadow: 0 0.0625rem 0.125rem rgba(0, 0, 0, 0.15) !important;
  /* position: relative; */
}

.Dropdown-menu::after {
  content: "";
  position: absolute;
  display: block;
  width: 0;
  z-index: 1;
  border-style: solid;
  border-color: #ffffff transparent;
  border-width: 0 20px 20px;
  top: -20px;
  left: 30%;
  margin-left: -20px;
}

.Dropdown-option {
  font-size: 12px;
}

.Dropdown-option.is-selected {
  background-color: #1e7a6925 !important;
}

.Dropdown-option:hover {
  background-color: #1e7a6925 !important;
}

.Dropdown-noresults {
  font-size: 12px;
}

.selector-group {
  display: flex;
}

.hgb-ico {
  border-radius: 50%;
  padding: 7.5px;
}

.hgb-ico:hover {
  background-color: #edefef;
}

.selector-group button {
  color: #1e7a69;
  cursor: pointer;
  transition: 50ms ease-in-out;
  padding: 7.5px 20px;
  border-radius: 20px;
  border: none;
  font-size: 12px;
  background-color: transparent;
  font-weight: 700;
  font-family: "MS-SM";
}

.sg-small button {
  width: auto !important;
  padding: 5px 10px !important;
  margin: 0px !important;
}

.selector-group button:hover {
  background-color: #edefef;
}

.sg-active {
  background-color: #1e7a69 !important;
  background-image: linear-gradient(1deg, #1e7a69, #ffffff20 99%);

  color: #fff !important;
  filter: drop-shadow(1px 1px 1px #232c2e7a);
}

.flex-align-center {
  display: flex;
  align-items: center;
}

.flex-as {
  display: flex;
  align-items: flex-start;
}

.flex-ac {
  display: flex;
  align-items: center;
}

.ml-20 {
  margin-left: 20px;
}

.mr-20 {
  margin-right: 20px;
}

.mb-0 {
  margin-bottom: 0px !important;
}

.ml-40 {
  margin-left: 40px;
}

.ml-5v {
  margin-left: 5vw;
}

.color-input {
  border-radius: 20px;
  background-color: whitesmoke;
  /* box-shadow: 0px 2px 2px 1px rgb(0 0 0 / 28%) !important; */
}

.flex-apart {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.flex-w {
  flex-wrap: wrap;
}

.flex-wrap {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
}

.red-icon {
  fill: #a9363f !important;
  border-radius: 50%;
  padding: 7.5px;
}

.red-icon:hover {
  background-color: #edefef;
}

.mt-40 {
  margin-top: 40px !important;
}

.selector-group button:disabled {
  background-color: #c6c7c8 !important;
  /* border-color: ; */
}

.MuiPaper-rounded {
  border-radius: 7px !important;
  /* border-radius: 20px !important; */
}

.loading-container {
  height: 80%;
  align-items: center;
  justify-content: center;
}

.MuiMenuItem-root {
  font-size: 12px !important;
  font-family: "Inter", sans-serif !important;
}

.MuiPopover-paper {
  padding: 5px !important;
}

.MuiPopover-paper li {
  border-radius: 10px;
  color: #5c6363;
}

.event-rounds .page-header {
  justify-content: flex-start !important;
}

.event-rounds .page-header h2 {
  /* margin-right: 40px; */
}

.event-rounds .page-header svg {
  /* height: 3vw !important; */
  /* width: 3vw !important; */
  margin-left: 20px;
  padding: 7.5px !important;
}

.mpaf-cont {
  width: 100%;
  /* display: flex; */
  /* align-items: center; */
  /* justify-content: space-around; */
}

.dp-header {
  background-image: url("./assets/images/nextbg.png");
  /* border-radius: 0 0 40px 40px; */
  padding: 40px 0px;
  display: flex;
  justify-content: center;
}

.dp-header h1 {
  font-size: 56px;
  text-transform: uppercase;
  letter-spacing: 2px;
  font-weight: 900;
  color: #f5f7f8;
  font-family: "MS-Bold";
  filter: drop-shadow(2px 4px 6px #232c2e);
}

.dp-header p {
  width: 50vw;
  color: #e9f8f2;
  font-weight: 300;
  font-family: "MS-Reg";
  opacity: 0.8;
  margin: 10px 0px;
}

.ltm-players {
  padding: 20px;
  width: 75vw;
}

.ltmp-box {
  display: flex;
  flex-wrap: wrap;
}

.ltmp-box .player-item {
  width: 15vw !important;
  margin-right: 20px !important;
}

.ltm-members .player-item {
  width: 20vw !important;
  margin-right: 20px;
}

.ltm-members {
  display: flex;
  flex-wrap: wrap;
}

.ltma-input {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.fair-use {
  padding: 20px;
}

.fair-use p {
  margin-bottom: 10px;
  margin-top: 20px;
}

.privacy-policy p {
  font-size: 14px;
  margin-bottom: 10px;
}

.pr-20 {
  padding-right: 20px;
}

.ml-10 {
  margin-left: 10px !important;
}

.privacy-policy h1 {
  font-size: 20px;
}

.drop-group h2 {
  font-size: 14px !important;
}

a {
  text-decoration: none !important;
}

.grey-ico {
  cursor: pointer;
  transition: 40ms ease-in-out;
  border-radius: 50%;
  padding: 10px;
}

.grey-ico:hover {
  background-color: #edefef;
}

.sett-dia {
  min-height: 50vh;
  min-width: 50vw;
}

.opa-70 {
  opacity: 0.7;
}

.DropdownMenuContent,
.DropdownMenuSubContent {
  min-width: 220px;
  background-color: white;
  border-radius: 6px;
  padding: 5px;
  margin-left: 20px;
  box-shadow: 0px 10px 38px -10px rgba(22, 23, 24, 0.35),
    0px 10px 20px -15px rgba(22, 23, 24, 0.2);
  animation-duration: 400ms;
  animation-timing-function: cubic-bezier(0.16, 1, 0.3, 1);
  will-change: transform, opacity;
  cursor: pointer;
}
.DropdownMenuContent[data-side="top"],
.DropdownMenuSubContent[data-side="top"] {
  animation-name: slideDownAndFade;
}
.DropdownMenuContent[data-side="right"],
.DropdownMenuSubContent[data-side="right"] {
  animation-name: slideLeftAndFade;
}
.DropdownMenuContent[data-side="bottom"],
.DropdownMenuSubContent[data-side="bottom"] {
  animation-name: slideUpAndFade;
}
.DropdownMenuContent[data-side="left"],
.DropdownMenuSubContent[data-side="left"] {
  animation-name: slideRightAndFade;
}

.DropdownMenuItem,
.DropdownMenuCheckboxItem,
.DropdownMenuRadioItem,
.DropdownMenuSubTrigger {
  font-size: 12px;
  border-bottom: 1px solid #edefef;
  line-height: 1;
  /* color: var(--violet-11); */
  border-radius: 3px;
  display: flex;
  font-family: "MS-SM" !important;
  font-weight: 500;
  align-items: center;
  height: 25px;
  padding: 0 5px;
  position: relative;
  padding-left: 25px;
  letter-spacing: normal !important;
  user-select: none;
  outline: none;
  color: #232c2e;
}

.DropdownMenuLabel {
  padding-left: 25px;
  font-size: 12px;
  line-height: 25px;
  /* color: var(--mauve-11); */
}

.pl-10 {
  padding-left: 10px !important;
}

.DropdownMenuSeparator {
  height: 1px;
  background-color: #232c2e;
  opacity: 0.3 !important;
  margin: 5px;
}

.ch-link h5 {
  font-family: "MS-SM";
}

.msb {
  font-family: "MS-SM";
}

a:visited {
  text-decoration: none !important;
  color: initial;
}

.CookieConsent {
  background-color: #1e7a69 !important;
}

.CookieConsent div {
  font-family: "MS-Med";
}

.pb-20 {
  padding-bottom: 20px !important;
}

.CookieConsent button {
  background-color: whitesmoke !important;
  font-family: "MS-SM";
  color: #1e7a69;
}

.wmc {
  width: max-content;
}

.pd-10 {
  padding: 10px;
}

.hd-item .page-header h2 {
  font-size: 20px !important;
}

.mb-active-events .page-header h2 {
  font-size: 24px !important;
}

.bs-subtle {
  box-shadow: rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
  border-radius: 20px;
}

.bss-white {
  background-color: white;
}

.w-100 {
  width: 100%;
}

.ddm-but {
  cursor: pointer;
  background-color: #1e7a69;
  background-image: linear-gradient(1deg, #1e7a69, #ffffff20 99%);
  /* background-image: linear-gradient(1deg, #0f3d35, #1e7a69 99%); */

  color: whitesmoke;
  border: none;
  font-family: "MS-Bold" !important;
  border-radius: 20px;
  padding: 5px 20px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px,
    rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;
  /* box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px,
    rgba(0, 0, 0, 0.3) 0px 3px 7px -3px; */
  font-weight: 600;
  text-transform: uppercase;
  transition: 50ms ease-in-out;
  display: flex;
  align-items: center;
  margin-right: 10px;
  font-size: 12px;
}

.ddm-but:hover {
  opacity: 0.9;
}

.ddm-but:active {
  transform: scale(0.98);
}

.ddmb-box {
  padding-left: 20px;
}

.DropdownMenuItemIndicator {
  position: absolute;
  left: 0;
  width: 25px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.DropdownMenuArrow {
  fill: white;
}

.RightSlot {
  margin-left: auto;
  padding-left: 20px;
  color: var(--mauve-11);
}
[data-highlighted] > .RightSlot {
  color: white;
}
[data-disabled] .RightSlot {
  color: var(--mauve-8);
}

.DropdownMenuItem[data-highlighted],
.DropdownMenuCheckboxItem[data-highlighted],
.DropdownMenuRadioItem[data-highlighted],
.DropdownMenuSubTrigger[data-highlighted] {
  background-color: #1e7a69;
  color: whitesmoke !important;
}

.DropdownMenuItem a {
  color: #232c2e;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
}

.DropdownMenuItem[data-highlighted] a {
  color: whitesmoke !important;
}

@keyframes slideUpAndFade {
  from {
    opacity: 0;
    transform: translateY(2px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes slideRightAndFade {
  from {
    opacity: 0;
    transform: translateX(-2px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes slideDownAndFade {
  from {
    opacity: 0;
    transform: translateY(-2px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes slideLeftAndFade {
  from {
    opacity: 0;
    transform: translateX(2px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

.sub-header h2 {
  font-family: "MS-SM";
}

.sub-header h5 {
  font-family: "MS-SM";
}

.ehb-header h1 {
  font-family: "MS-SM";
}

.ddm-but span {
  color: white;
}

.pb-0 {
  padding-bottom: 0px !important;
}

.Toastify__toast--success .Toastify__toast-icon {
  fill: #1e7a69 !important;
}

.toast-success-ico {
  fill: #1e7a69 !important;
}

.Toastify__toast-body:not(.Toastify__toast-icon) {
  font-size: 14px !important;
}
.Toastify__progress-bar--success {
  background-color: #1e7a69 !important;
}

.Toastify__toast-body > div:last-child {
  font-family: "MS-SM";
}

.row-header p {
  /* cursor: pointer; */
}

.cursor-ico {
  cursor: pointer;
}

.no-items {
  display: flex;
  justify-content: center;
}

.no-items p {
  font-family: "MS-Reg";
  font-size: 13px;
}
