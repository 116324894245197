.tem-matches {
  /* width: calc(100vw - 40px); */
  padding: 20px;
}

.live .team-event-matches {
  height: calc(100vh - 110px - 84px);
  overflow: scroll;
}

.projected-team-log {
  /* width: 100vw; */
}

.team-event-matches {
  width: 100%;
}

.tem-matches .back-row {
  margin-left: 0px !important;
  margin-top: 0px !important;
}

.tem-match {
  margin-bottom: 20px;
}

.cb-box {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.csd-ctt {
  width: 90vw;
}

.csd-ctt .player-item {
  width: 20vw !important;
  margin-right: 20px;
}

.tem-filters {
  min-height: 50vh;
}

.tem-name-row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 5px;
}

.tnr-left {
  display: flex;
  align-items: center;
}

.tnr-right {
  display: flex;
  align-items: center;
  flex-direction: row-reverse;
}

.tem-name-row .MuiAvatar-root {
  height: 20px !important;
  width: 20px !important;
}

.tem-name-row p {
  font-weight: 600;
  color: #232c2e;
  font-size: 12px;
}

.tnr-left p {
  margin-left: 10px;
}

.tnr-right p {
  margin-right: 10px;
}

.ltm-teams {
  display: flex;
  flex-wrap: wrap;
}

.ltm-team {
  width: calc(50vw - 40px);
}

.ltm-members .player-score {
  width: 100% !important;
}

.ltm-members .ps-right-left {
  flex: 1 !important;
}

.ltm-members .ps-right {
  padding-left: 5px !important;
}

.points-msg {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.tem-course h3 {
  font-size: 12px;
  color: #232c2e;
}

.tem-course {
  margin-bottom: 5px;
  padding-left: 10px;
}

.points-msg p {
  opacity: 0.5;
  font-size: 12px;
}

.tem-team {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
}

.tt-right {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.tt-left {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.ttl-left {
  display: flex;
  align-items: center;
}

.ttr-right {
  display: flex;
  align-items: center;
}

.tt-mid {
  margin: 0px 20px;
}

.tem-team h2 {
  font-size: 14px !important;
  color: #232c2e;
}

.tt-left h2 {
  margin-left: 10px;
  text-align: center;
}

.tem-team span {
  font-size: 20px;
  font-weight: bold;
  border-radius: 50%;
  width: 4vw;
  height: 4vw;
  text-align: center;
  /* vertical-align: middle; */
  color: whitesmoke;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #232c2e;
}

.tt-right h2 {
  margin-right: 10px;
  text-align: center;
}

.tem-team .MuiAvatar-root {
  width: 30px !important;
  height: 30px !important;
}

.tem-teams {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.tem-team {
  box-shadow: rgb(149 157 165 / 20%) 0px 8px 24px;
  /* width: 80vw; */
  width: 90%;
  cursor: pointer;
  margin-top: 20px;
  border-radius: 20px;
}
.ttl-right {
  display: flex;
  align-items: center;
}

.ttl-right h5 {
  margin-left: 5px;
}

.ttr-left h5 {
  margin-right: 5px;
}

.ttr-left {
  display: flex;
  align-items: center;
}

.tem-team h5 {
  color: #232c2e;
}
