.ld-division {
  display: flex;
  align-items: center;
  background-color: white;
  box-shadow: rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;

  /* box-shadow: rgb(149 157 165 / 20%) 0px 8px 24px; */
  width: 30vw;
  padding: 20px;
  border-radius: 20px;
  justify-content: space-between;
  margin-right: 5vw;
  margin-bottom: 20px;
}

.ldv-right {
  display: flex;
  /* flex-direction: column; */
  justify-content: center;
}

.ldv-right .icon-cursor {
  margin-right: 10px;
}

.ld-division h2 {
  color: #232c2e;
  font-size: 15px;
}
.ld-division p {
  font-size: 13px;
  color: #232c2e;
  text-transform: capitalize;
  opacity: 0.6;
}

.dv-players {
  width: 80vw;
  display: flex;
  flex-wrap: wrap;
}

.dv-players .player-item {
  width: 20vw !important;
  margin-right: 20px;
}
