.contact-container {
  display: flex;
  margin-top: 80px;
  margin-bottom: 80px;
  flex-direction: column;
  align-items: center;
  width: 50vw;
  padding: 40px;
  border-radius: 20px;
  margin-bottom: 40px;
  background-color: white;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
    rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
  /* box-shadow: rgb(149 157 165 / 80%) 0px 8px 24px; */
}

.contact-finished {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.contact-finished h1 {
  font-size: 64px;
  text-transform: uppercase;
  width: 50vw;
  margin-top: 120px;
  text-align: center;
  letter-spacing: 2px;
  font-weight: 900;
  /* color: #f5f7f8; */
  font-family: "MS-Bold";
  /* filter: drop-shadow(2px 4px 6px #232c2e); */
  color: #1e7a69 !important;
  filter: drop-shadow(1px 2px 2px #1e7a69);
}

.contact-finished p {
  font-size: 32px;
  letter-spacing: 3px;
  font-family: "MS-Med";
  opacity: 0.85;
  font-weight: 300;
  margin-top: 20px;
  margin-bottom: 120px;
  text-transform: uppercase;
  color: #1e7a69 !important;
  filter: drop-shadow(1px 2px 2px #1e7a69);
}

.check-confirm {
  display: flex;
  align-items: center;
  margin-top: 20px;
}

.check-confirm input {
  outline-width: 0 !important;
  margin-bottom: 0px !important;
  margin-right: 20px;
}

.check-confirm p {
  font-size: 12px;
  opacity: 0.7;
  font-family: "MS-SM";
}

.contact-box {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.contact-container input {
  width: 100% !important;
}

.contact-input {
  /* border: 1px solid #232c2e; */
  border: none;
  font-size: 12px;
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
    rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
  margin-bottom: 20px;
  outline-width: initial !important;
  border-radius: 20px;
  padding: 10px;
  outline-color: #1e7a69;
  font-family: "MS-Med";
}

.contact-container textarea {
  width: 100% !important;
  resize: none;
}

.MuiCheckbox-root {
  color: #1e7a69 !important;
}

.MuiCheckbox-root:hover {
  background-color: #edefef !important;
}

.Mui-checked {
  color: #1e7a69 !important;
}

.cp-email {
  display: flex;
  justify-content: center;
  margin-top: 80px;
  flex-direction: column;
  align-items: center;
}

.cp-email p {
  width: 50vw;
  font-family: "MS-Reg";
  opacity: 0.7;
  color: #232c2e;
  font-size: 14px;
  text-align: center;
}
