.menu-bar {
  height: 80px;
  top: 0px;
  position: sticky;
  /* background-color: black; */
  display: flex;
  align-items: center;
  width: 100%;
  box-shadow: 0px 1px 2px 0px rgb(0 0 0 / 28%) !important;
  /* -webkit-box-shadow: 0px 2px 2px 1px rgb(0 0 0 / 28%) !important; */
  /* background-image: linear-gradient(45deg, #fff 0%, #e9f8f24e 40%); */
  /* background-color: #f8fdfb; */
  /* background-color: #0f3d35; */
  /* background-image: linear-gradient(1deg, #0f3d35, #ffffff20 99%); */

  background-color: white;
  z-index: 2;
}

.mb-left {
  flex: 0.25;
  padding-left: 2.5vw;
  display: flex;
  align-items: center;
}

.mbl-ico-box svg {
  /* fill: whitesmoke !important; */
  fill: #232c2e !important;
  height: 24px;
  width: 24px;
}

.mbr-box svg {
  height: 26px;
  width: 26px;
  /* fill: whitesmoke !important; */
  /* stroke: whitesmoke !important; */
}

.mbr-box {
  cursor: pointer;
}

.mbl-ico-box {
  cursor: pointer;

  display: flex;
  justify-content: center;
  align-items: center;
  padding: 7.5px;
  border-radius: 50%;
  transition: 100ms ease-in-out;
}

.mbl-ico-box:hover {
  /* background-color: #1e7a69; */
  background-color: #edefef;
}

.mb-left img {
  height: 50px !important;
  margin-left: 20px;
}

.mb-middle {
  display: flex;
  justify-content: center;
  flex: 0.5;
}

.mb-navs {
  justify-content: space-evenly !important;
}

.mbm-nav {
  text-decoration: none;
}

.mbm-nav p {
  font-size: 15px;
  opacity: 0.8 !important;
  transition: 150ms ease-in-out;
}

.mbm-nav p:hover {
  transform: scale(1.05);
}

.mbm-active p:hover {
  transform: none !important;
}

.mbm-active {
  /* text-decoration: underline; */
}

.mbm-active p {
  font-weight: 600;
  /* color: var(--dark-gre); */
  /* border-bottom: 2px solid var(--dark-gre); */
  color: #1e7a69;
  /* font-size: 16px !important; */
  padding-bottom: 2.5px;
  border-bottom: 2.5px solid #1e7a69;
}

.mb-right .default-button {
  font-size: 15px !important;
  padding: 10px 40px !important;
}

.mb-middle p {
  /* color: whitesmoke; */
  font-size: 14px;
  /* opacity: 0.8; */
  /* opacity: 0.5; */
  text-transform: uppercase;
  letter-spacing: 2.5px;
  font-family: "MS-Med";
  /* font-family: "TT-Reg"; */
}

.md-logo {
  display: flex;
  justify-content: center;
  align-items: center;
}

.md-logo img {
  /* width: 100%; */
  height: 12.5vh;
  margin: 10px 0px;
}

.mb-right {
  flex: 0.25;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-right: 2.5vw;
}

.mb-right .MuiSvgIcon-root {
  height: 30px !important;
  width: 30px !important;
  /* margin-left: 10px; */
  cursor: pointer;
  /* fill: white !important; */
  fill: #232c2e;
  transition: 200ms ease-in-out;
}

.mbr-box {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  padding: 7.5px;
}

.mbr-box:hover {
  /* opacity: 0.7; */
  background-color: #edefef;
  /* background-color: #1e7a69; */
}

.menu-draw {
  width: 30vw;
  overflow: scroll;
  /* background: rgb(30, 122, 105);
  background: linear-gradient(
    315deg,
    rgba(30, 122, 105, 0.074) 0%,
    rgba(237, 239, 239, 1) 100%
  ); */
  /* background-color: #0f3d35; */
  /* background-image: linear-gradient(45deg, #0f3d35, #ffffff20 99%); */

  background-color: white;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-image: linear-gradient(115deg, #fff 0%, #e9f8f24e 40%);
}

.menu-bar .MuiBadge-badge {
  background-color: #e24955;
  color: white;
}

.md-options {
  display: flex;
  flex-direction: column;
}

.mm-eu-user p {
  margin-top: 2.5px;
  opacity: 0.5;
  font-size: 12px !important;
}

.md-item {
  text-decoration: none;
  transition: 300ms ease-in-out;
}

.md-link {
  display: flex;
  align-items: center;
  padding: 7.5px 20px;
  /* padding-left: 10px; */
}

.md-link svg {
  background-color: #1e7a69;
  fill: whitesmoke;
  width: 20px !important;
  height: 20px !important;
  /* width: 14px !important; */
  /* height: 14px !important; */
  padding: 5px;
  border-radius: 50%;
  /* fill: #1e7a69; */
  /* width: 24px !important; */
  margin-bottom: 2px;
  /* height: 24px !important; */
}

.md-link p {
  /* text-decoration: none !; */
  color: #232c2e;
  /* color: white; */
  margin-left: 10px;
  font-size: 13px;
  letter-spacing: 1px;
  text-transform: uppercase;
  border-bottom: 2px solid transparent;
  font-family: "MS-Med";
}

.md-item:hover {
  /* opacity: 0.7; */
  padding-left: 10px;
  /* background-color: #1e7a69; */
  background-color: #edefef;
}

.md-active p {
  border-bottom: 2px solid #1e7a69;
  /* border-image:  
    linear-gradient(0.25turn,
     
      #1e7a69,
      #21c17c
    );
    padding-bottom: 1px;
    border-image-slice: 1;
    width: max-content; */
}

.md-profile {
  display: flex;
  padding-left: 10px;
  align-items: center;
  margin-bottom: 20px;
  /* border-top: 1px solid #000; */
  /* border-bottom: 1px solid #edefef; */
}

.md-profile .MuiAvatar-root {
  height: 50px;
  width: 50px;
}

.mdp-right h3 {
  font-weight: 500;
  font-family: "MS-SM";
  margin: 0px;
  font-size: 16px;
  /* color: whitesmoke; */
}

.mdp-right p {
  font-size: 12px;
  opacity: 0.5;
  margin: 5px 0px;
  font-family: "MS-Med";
  /* color: whitesmoke; */
}

.mdp-right {
  margin-left: 10px;
}

.ae-draw .MuiPaper-root {
  width: 30vw;
  left: auto;
  border-radius: 20px 0px 0px 20px;
}

.mb-ae-box {
  padding: 0px 20px 20px 20px;
}

.ae-draw .fixture-preview {
  cursor: pointer;
}

.ae-draw .fixture-preview:hover {
  background-color: #edefef;
}

.ae-draw .fixture-preview:active {
  opacity: 0.8 !important;
}

.md-bot {
  padding-left: 20px;
  padding-bottom: 20px;
}

.md-bot p {
  text-decoration: underline;
  font-family: "MS-Reg";
  /* color: whitesmoke; */
  font-size: 12px;
  margin-bottom: 5px;
  opacity: 0.7;
}

.md-bot p:hover {
  cursor: pointer;
  opacity: 0.5;
}

.nc-dialog {
  width: 65vw;
}

.nc-players {
  display: flex;
  flex-wrap: wrap;
}

.nc-dialog .player-item {
  width: 22vw !important;
  margin-right: 2.5vw;
}

.mm-eu a {
  width: 100%;
  display: flex;
  align-items: center;
}

.mm-eu-ico {
  /* border: 1px solid #edefef; */
  background-color: #1e7a69;
  border-radius: 50%;
  fill: whitesmoke !important;
  width: 20px !important;
  height: 20px !important;
  padding: 5px !important;
}

.mm-eu-user h2 {
  font-weight: 500;
  font-family: "MS-SM";
  color: #232c2e;
  margin-left: 20px;
  margin-right: 20px;
  font-size: 15px;
}

.mm-eu-user {
  display: flex;
  align-items: center;
  margin: 20px;
  border-radius: 10px;
  /* padding: 5px; */
}

.mm-eu p {
  margin-left: 20px;
  color: #232c2e;
  font-weight: 500;
  font-size: 13px;
  font-family: "MS-SM";
}

.mbr-ava {
  height: 30px !important;
  width: 30px !important;
}

.mm-eu .MuiPaper-root {
  top: 80px !important;
}
