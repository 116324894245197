.score-modal {
    transition: 500ms ease-in;
    position: fixed; /* Stay in place */
    z-index: 999; /* Sit on top */
    left: 0;
    top: 0;
    width: 100%; /* Full width */
    height: 100%; /* Full height */
    overflow: auto; /* Enable scroll if needed */
    background-color: rgb(0,0,0); /* Fallback color */
    background-color: rgba(0,0,0,0.4); /* Black w/ opacity */
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  /* Modal Content/Box */
  .score-modal-content {
    background-color: #fefefe;
    margin: 15% auto; /* 15% from the top and centered */
    padding: 10px;
    /* border: 2px solid black; */
    border-radius: 7px;
    width: 80%; /* Could be more or less, depending on screen size */
    display: flex;
    align-items: center;
    /* justify-content: center; */
    flex-direction: column;
    justify-content: center;
    height: max-content;
    padding: 50px 20px;
    font-size: 90%;
  }

  .loading-container {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 50vh;
      min-width: 50vw;
      /* background-color: white; */
      padding: 20px;
      /* border: 2px solid black; */
      border-radius: 7px;
  }

  .score-modal-profile {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-bottom: 20px;
  }

  .score-modal-profile h2 {
      margin-top: 10px;
      margin-bottom: 0px;
      font-size: 18px;
      color: #232C2E;
  }

  .score-modal-profile img {
      height: 15vh;
      width: 15vh;
      object-fit: cover;
      border-radius: 50%;
  }

  .score-modal-profile p {
    color: #8E9191 !important;
    font-size: 14px !important;
    font-weight: bold !important;
  }

  .score-modal-table td {
      font-size: 14px !important;
  } 