.full-log {
    padding: 0px 20px;
    overflow: scroll;
    /* padding: 10px; */
    /* height: calc(100vh - 162px - 40px - 66px - 40px); */
}

.league-page .full-log {
    width: 100%;
}
.league-details .full-log {
    width: 100%;
}

.full-log table {
}

.full-log td {
    text-align: center;
}

.rank-div {
    border-bottom: 1px dashed #e24955;
    margin-bottom: 20px;
    margin-right: 10px;
    margin-left: 10px;
}

.full-log th {
    text-align: center;
}

.loading-div {
    display: flex;
    align-items: center;
    justify-content: center;
    /* width: 50vw; */
}

.loading-div img {
    width: 80vw;
    height: auto;
}

.fl-fs-loader {
    width: 100vw;
    height: 100%;
    padding: 0px !important;
}