.rbc-calendar {
  margin: 20px;
  box-shadow: 0px 2px 2px 1px rgb(0 0 0 / 28%) !important;
  -webkit-box-shadow: 0px 2px 2px 1px rgb(0 0 0 / 28%) !important;
  border: 1px solid #edefef;
  border-radius: 20px;
  padding: 20px;
  background-color: white;
}

.cal-events {
  padding: 20px;
  display: flex;
  flex-wrap: wrap;
}

.rbc-row-content {
  overflow: scroll;
  z-index: 1 !important;
}

.cal-events .fixture-preview {
  margin-right: 5vw;
}

.rbc-current button {
  color: #edefef !important;
  background-color: #1e7a69;
  border-radius: 50%;
  padding: 5px;
}

.rbc-button-link {
  color: #232c2e !important;
}

.calendar-page {
  min-height: calc(100vh - 80px);
  overflow: scroll;
  background-color: #f5f5fac1;
}

.cal-search {
  margin-left: 10vw;
}

.rbc-header {
  background-color: #1e7a69;
}

.rbc-header span {
  color: white !important;
}

.rbc-today {
  background-color: transparent !important;
}
.rbc-month-view {
  border-radius: 20px;
  overflow: hidden;
}

.rbc-icons {
  display: flex !important;
  align-items: center;
}

.rbct-icon {
  cursor: pointer;
  transition: 200ms ease-in-out;
}

.rbct-icon:hover {
  opacity: 0.8;
}

.cal-events .fixture-preview {
  width: 40vw !important;
}

.rbct-today {
  margin: 0px 10px;
  cursor: pointer;
  font-size: 12px;
  text-transform: uppercase;
  font-weight: 500;
  padding: 5px 10px;
  background-color: #1e7a69;
  color: white;
  border-radius: 20px;
  font-family: "MS-Bold";
  /* opacity: 0.7;      */
  /* border-bottom: 2px solid transparent;
    border-image:  
    linear-gradient(0.25turn,
     
      #1e7a69,
      #21c17c
    );
    padding-bottom: 1px;
    border-image-slice: 1;
    width: max-content; */
}

.switch-row .radio-group {
  padding: 0px !important;
}

.radio-box .radio-group {
  padding-bottom: 0px !important;
}
.calendar-page select {
  padding: 10px 20px;
  outline-width: 0;
  /* outline-color: #21c17c; */
  border-radius: 20px;
  border-color: #edefef;
  font-family: "MS-Bold";
  box-shadow: 0px 2px 2px 1px rgb(0 0 0 / 28%) !important;
  -webkit-box-shadow: 0px 2px 2px 1px rgb(0 0 0 / 28%) !important;
  /* font-weight: bold; */
  color: #232c2e;
  font-weight: 600;
  text-transform: uppercase;
  /* opacity: 0.8; */
  color: #1e7a69;
}

.rbc-button-link {
  color: #1e7a69;
  font-weight: 600;
  opacity: 0.7;
  font-family: "MS-SM";
}

.rbc-header {
  text-transform: uppercase;
  padding: 5px 0px;
}

.rbc-header span {
  /* color: #1e7a69; */
  /* opacity: 0.8; */
  font-size: 12px !important;
  font-family: "MS-Med";
}

.rbc-off-range-bg {
  background-color: #edefef !important;
}

.rbc-toolbar {
  margin-bottom: 20px !important;
}

.rbc-month-view {
  /* border-color: pink !important; */
}

.rbc-event {
  font-size: 13px;
  padding: 0px;
  outline-width: 0;
  background-color: transparent !important;
}

.custom-event {
  border-radius: 7.5px;
  padding: 7.5px 5px;
  display: flex;
  justify-content: space-between;
  cursor: initial !important;
}

.ce-left span {
  display: block;
  width: 120px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.ce-icon {
  cursor: pointer;
}

.custom-event p {
  color: white;
  white-space: normal;
}

.custom-event span {
  color: white;
  opacity: 0.5;
  font-weight: 300;
  font-size: 10px;
}

.reg-event {
  background-color: #1e7a69 !important;
}

.maj-event {
  background-color: #d4af37 !important;
}

.fin-event {
  background-color: #5c6363;
}

.rbc-toolbar {
  justify-content: space-between !important;
}

.rbc-toolbar .rbc-toolbar-label {
  justify-content: flex-end !important;
  display: flex;
}

.cp-links {
  display: flex;
  align-items: center;
}

.calendar-page .no-msg {
  width: 100%;
}

.mob-cal {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 20px;
  margin-bottom: 40px;
}

.react-calendar {
  border: none !important;
  border-radius: 20px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

.react-calendar__navigation span {
  font-family: "MS-SM";
}

.react-calendar__navigation button {
  font-family: "MS-SM";
}

.react-calendar__month-view__weekdays__weekday abbr {
  font-family: "MS-SM";
  text-decoration: none !important;
  color: #232c2e;
}

.react-calendar__month-view__days__day--weekend {
  color: #232c2e;
}

.react-calendar__tile {
  font-family: "Inter";
  font-size: 12px;
  padding: 15px;
}

.react-calendar__tile--now {
  border-radius: 50%;
  background-color: #5c6363 !important;
  color: white;
}

.react-calendar__tile--active:enabled {
  background-color: #232c2e !important;
  border-radius: 50%;
}

.react-calendar__navigation__arrow {
  border-radius: 50%;
}

.rc-ed {
  background-color: #1e7a69 !important;
  color: white !important;
  border-radius: 50%;
}

.mc-evts {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: calc(100% - 40px);
}

.mc-evts .fixture-preview {
  width: calc(100% - 20px) !important;
}
