.helper {
  position: fixed;
  bottom: 50px;
  right: 20px;
  z-index: 999;
}

.w-100 {
  width: 100%;
}

.helper img {
  height: 30px;
  width: 30px;
}

.hi-ico {
  fill: #1e7a69 !important;
}

.helper-speeddial {
  bottom: 0px !important;
  right: 0px !important;
  top: auto !important;
}

.helper-faq {
  padding-left: 10px;
}

.faq-q {
  font-family: "MS-SM";
  font-size: 13px;
}

.faq-a {
  font-family: "MS-Med";
  font-size: 12px;
}

.helper-vid-box {
  /* z-index: 1060; */
  background-color: white;
  position: relative;
  margin-right: 65px;
}

.htb-close {
  display: flex;
  padding: 10px;
  justify-content: flex-end;
}

.helper-speeddial button {
  background-color: #1e7a69 !important;
  background-image: linear-gradient(1deg, #1e7a69, #ffffff20 99%);
}

.htb-box {
  height: 100%;
}

.help-desc {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-bottom: 20px;
}

.he-de-it {
  padding: 10px 0px 0px 10px;
}

.help-desc h3 {
  font-family: "MS-SM";
  padding-left: 10px;
}

.htb-ico {
  height: 18px !important;
  width: 18px !important;
}

.hel-de-head {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.help-desc h5 {
  font-family: "MS-SM";
  font-size: 12px;
}

.he-de-it p {
  font-family: "MS-Reg" !important;
  font-size: 11px !important;
}

.helper-text-box {
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  display: flex;
  flex-direction: column;
  background-color: white;
  width: 40vw;
  margin-right: 65px;
  min-height: 35vh;
}

.hdh-bord {
  height: 1.5px;
  margin-top: 2.5px;
  width: 75%;
  background-color: #1e7a69;
  margin-left: 10px;
}

.htbb-txt {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  /* margin-top: 20px; */
}

.htbb-txt p {
  font-family: "MS-Med";
  color: #232c2e;
  width: 75%;
  font-size: 13px;
}

.htbb-txt b {
  font-family: "MS-Bold";
}

.hvb-close {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  position: relative;
  margin-bottom: -20px;
  z-index: 1000;
}

.hvb-close .icon-cursor {
  background-color: white;
}

.hvb-box {
  /* background-color: white; */
  padding: 10px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  padding-bottom: 20px;
}

.helper-ico {
  fill: white !important;
}

.helper-image {
  /* background-color: #1e7a69; */
  box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px,
    rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
  border-radius: 50px;
  display: flex;
  align-items: center;
  justify-self: center;
  background-color: white;
}

.hi-box {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #1e7a69;
  border-radius: 50%;
  height: 56px;
  width: 56px;
  /* padding: 20px; */
}

.pb-10 {
  padding-bottom: 10px;
}
