.query-item {
border-bottom: 1px solid #edefef;
padding: 10px 20px;
color: #232c2e;
}

.query-item h2 {
    font-size: 14px;
}

.query-item h3 {
font-size: 12px;
margin: 2.5px 0px;
text-transform: capitalize;

}

.query-item h5 {
    opacity: 0.5;
    margin-bottom: 5px;
}

.query-item p {
    font-size: 13px;
}

