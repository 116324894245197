.refund-row {
  display: flex;
  align-items: center;
  padding: 5px 0px;
  border-bottom: 1px solid #edefef;
}

.refund-page {
  height: 100vh;
  overflow-y: scroll;
  width: 85vw;
}

.rr-date {
  flex: 0.3;
}

.rr-header {
  border-bottom: 2px solid #232c2e;
}

.rr-header p {
  font-weight: bold;
  font-family: "MS-SM" !important;
  font-size: 14px !important;
}

.refund-row p {
  font-size: 13px;
  font-family: "MS-Reg";
}
