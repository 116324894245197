.matchplay-tournaments {
  min-height: calc(100vh - 110px);
  background-color: #f5f5fac1;
}

.mpl-editor {
  min-height: calc(100vh - 110px);
  /* overflow: scroll; */
}

.mpl-home .ts-header {
  background-color: transparent !important;
}

.mpl-link {
  font-weight: 600;
  font-size: 13px;
}

.mpmb-full {
  justify-content: center;
  background-color: #1e7a6923;
}

.mpmb-full .player-item {
  width: 30vw !important;
}

.mp-start {
  padding: 20px;
}

.mp-start .mpb-match {
  cursor: pointer;
}

.mp-cir-box {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}

.mp-log-box {
  width: 75vw;
}

.mini-pi .player-item {
  width: 20vw;
}

.mini-pi h3 {
  text-align: center;
}

.mini-pi .ps-right {
  margin-left: 0px !important;
}

.mini-pi {
  display: flex;
  align-items: center;
  justify-content: center;
}

.mpr-rounds {
  display: flex;
  flex-direction: column;
  /* flex-wrap: wrap; */
}

.res-pee p {
  font-size: 12px;
  margin-bottom: 2.5px;
}

p.loss {
  color: #a9363f;
}

p.win {
  color: #1e7a69;
}

.mpr-match h3 {
  font-size: 14px;
  font-family: "MS-Bold";
  color: #232c2e;
}

.gr-filts {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.mpr-rounds .mpb-match {
  width: max-content;
}

.mps-players .player-item {
  width: 20vw;
  margin-right: 20px;
}

.mps-players {
  display: flex;
  flex-wrap: wrap;
  padding: 20px;
}

.mp-man-holes {
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}

.mp-man-holes .default-input {
  width: 2.5vw !important;
}

.delete-popup .page-header {
  width: 100%;
}

.mp-start .mpb-match-small .player-item {
  width: 10vw !important;
}

.mp-start-dia {
  width: 90vw;
}

.select-group select {
  width: 100%;
  padding: 12.5px 20px 12.5px 20px;
  font-size: 12px;
  border-radius: 20px;
  /* box-shadow: 0px 2px 2px 1px rgba(0, 0, 0, 0.28); */
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
    rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
  border: 1px solid #8e91917e;

  /* border: 1px solid #8E9191;    */
  /* background-color: #F5F7F8; */
  /* height: 52px; */
  /* font-weight: bold; */
  color: #5c6363;
  border: none;
  /* font-size: 14px !important; */
  -webkit-appearance: initial;
}

.select-group {
  width: calc(20vw + 40px) !important;
}

.mpl-links {
  display: flex;
  flex-wrap: wrap;
  width: 75vw;
  align-items: center;
  padding-left: 20px;
  margin-bottom: 10px;
}

.mpl-links .pill-button {
  margin-bottom: 20px;
}

.mpl-fixtures {
  padding: 0px 20px;
}

.mpls-head {
  margin-bottom: 10px;
}

.mpls-head p {
  font-size: 13px;
  color: #232c2e;
  font-weight: 500;
  border-bottom: 2px solid #1e7a69;

  /* border-image: 
linear-gradient(0.25turn,
  #21c17c, 
  #1e7a69
);
border-image-slice: 1; */
  width: max-content;
}

.mpl-round-header h2 {
  border-bottom: 2px solid #1e7a69;
  /* border-image: linear-gradient(0.25turn, #21c17c, #1e7a69); */
  /* border-image-slice: 1; */
  width: max-content;
  font-size: 20px;
  margin: 40px 0px 20px 0px;
  color: #232c2e;
  font-weight: 600;
  font-family: "MS-SM";
  /* margin-bottom: 10px; */
}

.mpl-selector {
  width: 75vw;
  padding: 20px;
}

.mpg-right svg {
  height: 20px !important;
  width: 20px !important;
  box-shadow: 1px 1px 1px 1px #edefef;
  fill: #1e7a69 !important;
  border-radius: 50%;
  padding: 5px;
  /* fill: ; */
}

.mpg-changer .info-circle {
  margin-right: 0px !important;
  width: auto !important;
}

.mpg-pills {
  display: flex;
  align-items: center;
  padding-left: 20px;
  margin-top: 10px;
}

.mpg-changer {
  display: flex;
  align-items: center;
  padding-left: 20px;
}

.mpl-dia {
  width: 75vw;
  padding: 20px;
  height: 60vh;
  flex-direction: column;
  display: flex;
  justify-content: center;
}

.mpg-player {
  display: flex;
  align-items: center;
}

.mpgs {
  width: 40vw;
}

.mplg-box {
  display: flex;
  flex-wrap: wrap;
}

.mpg-icon {
  margin-bottom: 10px;
  height: 20px !important;
}

.mpg-player .player-score {
  /* width: 80% !important; */
}

.mpg-player .mpl-groups > .input-group {
  margin-top: 10px !important;
  margin-bottom: 20px;
}

.mtp-pills {
  display: flex;
  align-items: center;
  padding-left: 20px;
}

.mpg-changer p {
  font-size: 12px;
  font-weight: 600;
  color: #232c2e;
  width: 20vw;
  margin-right: 10vw;
}

.mpg-right {
  display: flex;
  align-items: center;
}

.mpg-right h5 {
  margin: 0px 10px;
  color: #232c2e;
  font-weight: 600;
}

.mpg-right .info-circle {
  background-color: transparent !important;
}

.mpl-mps {
  width: 100%;
}

.mpl-groups .shuffle-players {
  margin-bottom: 0px !important;
}

.mpb-match {
  display: flex;
  align-items: center;
  margin-bottom: 2.5vh;
  box-shadow: rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
  background-color: white;
  /* border: 2px solid #1e7a69; */
  border-radius: 40px;
  padding: 20px;
}

.mpb-match .player-item {
  width: 15vw !important;
  margin-bottom: 0px !important;
}

.mpbm-circle {
  width: 2.5vw;
  height: 2.5vw;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0px 1.25vw;
  border-radius: 50%;
  background-color: #1e7a69;
}

.mpbm-circle p {
  color: white;
  font-size: 13px;
  font-weight: bolder;
}

.mprm-group p {
  color: #232c2e;
  font-size: 12px;
  opacity: 0.7;
  font-weight: 600;
  margin-bottom: 10px;
}

.mp-gt-group .neh-box {
  padding-bottom: 0px !important;
}

.mpl-home .eh-box-box {
  align-items: flex-start !important;
  /* margin-top: 40px; */
}

.mpl-home .ig-header h3 {
  font-family: "MS-SM";
  font-size: 16px;
}

.mpls-players {
  display: flex;
  flex-wrap: wrap;
}

.mpls-players .player-item {
  width: 12.5vw !important;
  margin-right: 2.5vw;
}

.group-headef h2 {
  font-size: 20px;
  opacity: 0.9;
  margin-bottom: 10px;
  color: #232c2e;
  font-weight: 600;
  border-bottom: 2px solid #1e7a69;

  /* border-image: linear-gradient(0.25turn, #21c17c, #1e7a69);
  border-image-slice: 1; */
  width: max-content;
}

.restrictions p {
  color: white !important;
}

.mpl-edit .ms-active p {
  font-size: 12px !important;
}

.mpl-display {
  overflow: scroll;
}

.mpl-display .mpl-fixtures {
  /* height: 100%; */
  overflow: scroll;
  margin-top: 20px;
}

.mpl-display .cdi-row h2 {
  color: white !important;
}

.mpl-deets {
  /* background-image: linear-gradient(45deg, #379fdc 0%, #2977a4 50%); */
  background-color: #2977a4;
  /* background-image: linear-gradient(45deg, #A9363F 0%, #70242A 50%); */
}

.mpt-log {
  width: 100%;
}

.mp-log-box {
  /* width: 100%; */
  padding-top: 10px;
}

.mpl-deets .fdl p {
  color: white !important;
  text-decoration: underline;
  opacity: 0.8;
}

.mpt-log-msg {
  padding: 20px 0px 0px 20px;
}

.mpt-log-msg h3 {
  font-size: 12px;
  color: #232c2e;
  font-weight: 600;
}

.mpt-log-msg p {
  font-size: 10px;
  color: #232c2e;
  opacity: 0.5;
}

.mpl-deets .fdl-div p {
  color: white !important;
}

.mpl-deets h3 {
  color: white !important;
}

.mplg-box {
  padding: 0px 20px;
}

.mpl-deets .ne-header {
  font-weight: 600 !important;
}

.mpl-deets h1 {
  color: white !important;
}

.mpl-deets h5 {
  color: white !important;
  opacity: 0.7;
}

.mpl-matches {
  /* width: 90vw; */
  display: flex;
  /* flex-direction: column; */
  align-items: center;
  flex-wrap: wrap;
  justify-content: space-around;
}

.mpl-mps > p {
  margin-bottom: 2.5px;
  font-size: 11px;
  color: #232c2e;
  font-weight: 500;
  opacity: 0.5;
}

.mpl-mpc {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.mpl-mpc-icon {
  margin-bottom: 20px;
  height: 18px !important;
}

.mp-log-item {
  display: flex;
  align-items: center;
}

.mpli-left {
  flex: 0.4;
  display: flex;
  align-items: center;
}

.mp-log-item .MuiBadge-badge {
  /* color: white !important; */
  /* background-color: #21c17c; */
  font-weight: bold !important;
  color: #232c2e;
  font-size: 12px;
  border-bottom: 1px solid #edefef;
  /* border-radius: 0px !important; */
}

.mpli-right {
  flex: 0.4;
  display: flex;
  align-items: center;
}

.mpli-right p {
  flex: 0.3;
  font-size: 12px;
  text-align: center;
}

.mpt-fixtures {
  width: 100%;
}

.mpl-groups {
  width: 100%;
}

.mtm-teams {
  padding: 20px;
  display: flex;
  flex-wrap: wrap;
}

.mtm-team {
  border-radius: 20px;
  box-shadow: rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
  background-color: white;
  /* box-shadow: 0 8px 24px hsla(210, 8%, 62%, 0.2); */
  margin-bottom: 40px;
  margin-right: 5vw;
  width: 25vw;
}

.mtm-team .player-item {
  width: calc(25vw - 40px) !important;
  background-color: transparent !important;
}

.mtmt-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.mpl-matches .player-item .dg-icon {
  padding: 7.5px;
  border-radius: 50%;
}

.mpl-matches .player-item .dg-icon:hover {
  background-color: #edefef;
}

.mpr-rds .matchplay-score {
  cursor: pointer;
}
