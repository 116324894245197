.pl-stats-home {
  min-height: calc(100vh - 110px);
  padding-bottom: 40px;
  background-color: #f5f5fac1;
  padding-top: 20px;
}

.pl-stats-home .back-row {
  margin-top: 0px;
}

.psh-members {
  display: flex;
  flex-wrap: wrap;
  padding: 20px;
}

.psh-members .player-item {
  width: 20vw;
  margin-bottom: 0px !important;
  background-color: transparent !important;
  /* margin-right: 20px; */
}

.psh-nav:hover {
  background-color: #edefef;
}

.psh-nav {
  background-color: white;
  margin-right: 20px;
  margin-bottom: 10px;
  border-radius: 20px;
}

.psc-players {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  padding: 20px;
}

.psp-players .player-item {
  width: 20vw !important;
}

.psc-players .player-item {
  width: 15vw !important;
}

.psp-dia-ctt {
  width: 90vw !important;
}

.psp-dia-ctt .radio-group {
  padding-bottom: 0px !important;
}

.psp-change {
  display: flex;
  align-items: center;
  border-bottom: 1px solid #edefef;
  padding: 5px 0px;
}

.pspc-head p {
  font-family: "MS-SM";
}

.psp-change p {
  flex: 0.3;
  font-size: 14px;
}

.psp-addition {
  color: #1e7a69;
}

.psp-removal {
  color: #a9363f;
}

.pspc-type {
  text-transform: capitalize;
  font-weight: bold;
}

.pspp-removal {
  opacity: 0.7;
}
