.lp-top {
  /* background-image: linear-gradient(45deg, #1e7a69 0%, #21c17c 100%); */
  background-image: url("../../assets/images/nextbg.png");
  /* background-image: url("../../assets/images/bgs/3.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover; */
  height: 75vh;
  display: flex;
  justify-content: space-around;
  /* border-radius: 0px 0px 40px 40px; */
}

.landing-page {
  background-color: white;
  min-height: calc(100vh - 180px);
}

.ltp-left {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  padding: 0px 40px;
}

.lp-divider h2 {
  font-size: 40px !important;
}

.lp-top .ltp-left h2 {
  filter: drop-shadow(2px 4px 6px #232c2e);
}

.ltp-left h2 {
  font-size: 64px;
  text-transform: uppercase;
  letter-spacing: 2px;
  font-weight: 900;
  color: #f5f7f8;
  /* font-family: "TT-Bold"; */
  font-family: "MS-Bold";
  /* filter: drop-shadow(2px 4px 6px #232c2e); */
}

.ltp-left h3 {
  font-size: 32px;
  letter-spacing: 3px;
  filter: drop-shadow(2px 4px 6px #232c2e);
  /* font-family: "TT-Med"; */
  color: #f5f7f8;
  /* opacity: 0.85; */
  font-weight: 300;
  text-transform: uppercase;
}

.ltp-left p {
  width: 40vw;
  color: #e9f8f2;
  font-weight: 300;
  /* font-family: "TT-Light"; */
  font-family: "MS-Reg";
  opacity: 0.8;
  letter-spacing: 0.5px;
  margin: 10px 0px;
}

.ltp-left button {
  cursor: pointer;
  text-transform: uppercase;
  font-weight: 900;
  font-family: "MS-Bold";
  /* background-color: #1e7a69; */
  box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px,
    rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;

  /* background-image: linear-gradient(45deg, #1e7a69 0%, #21c17c 100%); */
  background-color: white;
  /* border: 2px solid #232c2e; */
  border: none;
  font-size: 30px;
  color: #1e7a69;
  margin-top: 20px;
  border-radius: 40px;
  width: 40vw;
  transition: 200ms ease-in-out;
  padding: 20px 0px;
}

.ltp-left button:hover {
  /* background-color: #C7EFDE; */
}

.carousel-slider {
  border-radius: 40px;
}

.carousel-root {
  border: 5px solid white;
  border-radius: 40px;
}

.lps-box {
  box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px,
    rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
  border-radius: 50%;
  width: 60vw;
}

.lp-slider {
  padding: 10vh 40px;
  border-radius: 40px;
  /* background-image: url("../../assets/images/nextbg.png"); */

  background-image: linear-gradient(180deg, #1e7a69, #232c2e);
  align-items: center;
  /* background-color: #1e7a69; */
  display: flex;
}

.lps-left {
}

.lp-divider {
  /* background-color: #edefef; */
  background-color: #f9f9f9;
  /* background-image: url("/src/assets/images/greybg.jpg"); */
  /* border-radius: 40px; */
}

.lp-divider h2 {
  color: #1e7a69 !important;
  letter-spacing: 5px !important;
  text-align: center;
  /* filter: none; */
  margin: 20px 0px 0px 0px;
  /* font-family: "TT-Med"; */
  /* filter: drop-shadow(1px 2px 2px #1e7a69); */
}

.ltp-right img {
  height: 50vh;
  /* box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px,
    rgba(0, 0, 0, 0.3) 0px 30px 60px -30px,
    rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset; */
}

.btn-20,
.btn-20 *,
.btn-20 :after,
.btn-20 :before,
.btn-20:after,
.btn-20:before {
  border: 0 solid;
  box-sizing: border-box;
}
.btn-20 {
  -webkit-tap-highlight-color: transparent;
  -webkit-appearance: button;
  /* background-color: #000; */
  /* background-image: none; */
  cursor: pointer;
  font-size: 100%;
  font-weight: 900;
  line-height: 1.5;
  margin: 0;
  -webkit-mask-image: -webkit-radial-gradient(#000, #fff);
  padding: 0;
  text-transform: uppercase;
}

.btn-20:hover {
  color: white;
}

.btn-20:disabled {
  cursor: default;
}
.btn-20:-moz-focusring {
  outline: auto;
}
.btn-20 svg {
  display: block;
  vertical-align: middle;
}
.btn-20 [hidden] {
  display: none;
}
.btn-20 {
  border-radius: 99rem;
  border-width: 2px;
  overflow: hidden;
  padding: 0.8rem 3rem;
  position: relative;
}
.btn-20 span {
  mix-blend-mode: difference;
}
.btn-20:before {
  background: #1e7a69;
  content: "";
  display: block;
  height: 100%;
  z-index: -1;
  left: -100%;
  position: absolute;
  top: 0;
  transform: skew(0deg) translateX(-20%);
  transition: transform 0.2s ease;
  width: 120%;
}
.btn-20:hover:before {
  transform: skew(45deg) translateX(75%);
}

.lp-features {
  display: flex;
  justify-content: space-evenly;
  padding: 5vw;
  border-radius: 40px;
  background-image: url("../../assets/images/greybg.png");
  /* background-color: #e9f8f2; */
  border-top: 5px solid #1e7a69;
  border-bottom: 5px solid #1e7a69;
  margin-bottom: 5px;
  /* background-image: url("/src/assets/images/greybg.jpg"); */
}

.lpl-box {
  width: 40vw;
  height: 60vh;
  padding: 20px;
  background-color: #1e7a69;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.lp-features h1 {
  font-family: "MS-Bold";
  font-size: 24px;
  margin-bottom: 20px;
  /* opacity: 0.8; */
}

.lp-features p {
  font-family: "MS-Med";
  /* color: #7F511F; */
  /* opacity: 0.8; */
}

.lpl-bot h1 {
  color: #e9f8f2 !important;
  opacity: 1;
  filter: drop-shadow(2px 4px 4px #232c2e31);
}
.lpl-bot p {
  color: #e9f8f2;
  filter: drop-shadow(2px 4px 4px #232c2e4f);
  /* opacity: 0.8; */
}

.lpl-bot {
  padding-bottom: 30px;
}

.lpr-box {
  min-height: 40vh;
  width: 40vw;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.lprb-top {
  background-color: #209e73;
  border-radius: 20px;
}

.lprb-bot {
  border-radius: 20px;
  margin-top: 5vh;
  background-color: #232c2e;
}

.lprb-top h1 {
  color: #e9f8f2 !important;
}

.lprb-top p {
  color: #e9f8f2 !important;
}

.lprb-bot h1 {
  color: #e9f8f2 !important;
  opacity: 1 !important;
}

.lprb-bot p {
  color: #e9f8f2 !important;
  opacity: 1 !important;
}

.lpr-bot {
  padding-bottom: 20px;
}

.lpl-top img {
  height: 35vh;
  margin-bottom: 5vh;
}

.lpr-box img {
  height: 25vh;
  margin-bottom: 5vh;
}

.lp-video {
  /* background-image: linear-gradient(45deg, #1e7a69 0%, #21c17c 100%); */
  display: flex;
  padding: 20px;
  align-items: center;
  background-color: white;
}

.lpv-left {
  padding: 20px;
}

.lpv-frame iframe {
  border-radius: 20px;
}

.lpv-right h3 {
  font-size: 24px;
  filter: none !important;
  color: #1e7a69 !important;
  /* filter: drop-shadow(1px 2px 2px #1e7a69); */
}
.lpv-right h2 {
  font-size: 56px;
  color: #1e7a69 !important;
  /* filter: drop-shadow(1px 2px 2px #1e7a69); */
}

.lp-top h5 {
  color: white;
  text-transform: uppercase;
  font-weight: 300;
  margin-bottom: 10px;
  opacity: 0.5;
  letter-spacing: 3px;
  /* font-family: "TT-Reg"; */
}
