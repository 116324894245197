.sp-item {
  display: flex;
  align-items: center;
  margin-right: 5vw;
  box-shadow: rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;

  /* box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px; */
  padding: 20px;
  border-radius: 20px;
  /* border: 1px solid #edefef; */
  background-color: white;
  margin-bottom: 40px;
}

.settings-page {
  min-height: calc(100vh - 100px);
  background-color: #f5f5fac1;
}

.sp-group {
  display: flex;
  flex-wrap: wrap;
  padding: 20px;
}

.set-sds {
  display: flex;
  align-items: center;
}

.settings-page .ec-box {
  align-items: center;
}

.settings-page .Dropdown-root {
  min-width: 20vw !important;
}

.sp-group p {
  font-size: 12px;
  opacity: 0.8;
  font-weight: 600;
  width: 12vw;
}

.sp-item .scoring-switch {
  transform: scale(0.75);
}

.spi-but {
  transition: 200ms ease-in-out;
}

.spi-but:active {
  transform: scale(0.95);
}

.spi-but:hover {
  cursor: pointer;
  background-color: #edefef;
}

.sp-icon-green {
  fill: rgb(32, 158, 115) !important;
}

.sp-icon-red {
  fill: #a9363f !important;
}

.sub-details {
  display: flex;
  align-items: center;
  padding-left: 20px;
  margin-top: 10px;
}

.sd-item {
  display: flex;
  align-items: center;
  margin-right: 5vw;
}

.sd-item p {
  font-size: 14px;
  opacity: 0.7;
  margin-right: 10px;
}

.sd-item h5 {
  text-transform: capitalize;
}
