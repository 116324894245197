.fixture-preview {
  border: none;
  padding: 20px 10px 20px 10px;
  display: flex;
  /* margin: 20px 10px;    */
  border: 1px solid #edefef;
  /* border-left: 10px solid #21c17c !important;
     */
  /* border-top: 2px solid #EDEFEF; */
  /* background-image: linear-gradient(225deg, #edefef8e 0%, #fff 50%); */
  box-shadow: rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;

  /* box-shadow: rgb(149 157 165 / 20%) 0px 8px 24px; */
  border-radius: 30px;
  background-color: white;
  margin-bottom: 20px;
  /* cursor: pointer; */
  transition: 200ms ease-in-out;
}

.fixture-preview:hover {
  /* transform: scale(1.025); */
  /* background-image: none; */
  /* background-color: #EDEFEF; */
}

.fp-winner {
  display: flex;
  align-items: center;
  margin-top: 2.5px;
}

.fp-winner h3 {
  font-size: 10px !important;
  font-weight: 500 !important;
  width: max-content;
  color: #232c2e;
  opacity: 0.5;
}

.fpw-icon {
  /* opacity: 0.5; */
  height: 20px !important;
  fill: #1e7a69 !important;
}

.fp-icons svg {
  cursor: pointer;
  transition: 200ms ease-in-out;
}
.fp-icons svg:hover {
  /* transform: scale(1.2); */
}

.upcoming-events-container .fixture-preview {
  margin: 0px 10px 20px 10px;
  background-image: none;
}

.fixture-preview:active {
  /* background-color: #EDEFEF; */
  /* opacity: 0.7; */
}

.fixture-page .fixture-preview {
  padding-left: 10px !important;
}

.fp-left {
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 0.3;
}

.fp-right {
  display: flex;
  flex: 0.7;
  flex-direction: column;
  padding: 2px 2px 2px 10px;
  justify-content: center;
  /* align-items: center; */
}

.fp-right h3 {
  font-size: 13px;
  color: #232c2e;
  font-weight: 600;
}

.fp-right-button-row {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
}

.fp-right-button-row button {
  width: 30vw !important;
  background-color: #21c17c;
  border: 1px solid #21c17c;
  box-shadow: none;
  margin-bottom: 20px;
}

.fp-right h1 {
  text-decoration: none !important;
  font-size: 14px;
  color: #232c2e;
  padding-bottom: 5px;
  width: max-content;
  max-width: 18vw;
  /* border-bottom: 3px solid #90E0BD; */
  /* border-bottom: 3px solid #21C17C; */
  border-bottom: 3px solid #1e7a69;
  /* border-image: 
    linear-gradient(0.25turn,
      #21c17c, 
      #1e7a69
    );
    border-image-slice: 1; */
  margin: 0px 0px 5px 0px;
  font-weight: 500;
  /* font-family: "MS-SM"; */
}

.fp-right h2 {
  font-size: 12px;
  opacity: 0.5;
  margin-bottom: 2.5px;
  font-weight: normal;
  color: #232c2e;
  /* font-family: "MS-Med"; */
}

.fp-left img {
  /* border-left: 10px solid #21c17c !important; */

  width: 75px;
  height: 75px;
  object-fit: cover;
  border-radius: 7px;
}

.fp-icons {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.fpr-bot {
  display: flex;
  align-items: center;
  margin-top: 5px;
}

.fprb-item {
  display: flex;
  align-items: center;
}

.mr-10 {
  margin-right: 10px;
}
.mr-20 {
  margin-right: 20px;
}

.fprb-item h3 {
  font-weight: 500 !important;
  font-size: 10px !important;
  width: max-content;
  color: #232c2e !important;
  opacity: 0.5;
}

.fp-icon {
  height: 20px !important;
  /* fill: #232C2E !important; */
  opacity: 0.8;
  /* fill: #209E73 !important; */
  /* fill: #21C17C !important; */
  fill: #1e7a69 !important;
}
