.stats-correl .sub-msg {
  width: 50vw;
  margin-left: 0px !important;
}

.stats-correl {
  /* margin-bottom: 40px; */
}

.correl-item {
  width: 30vw;
  cursor: pointer;
}

.correl-item-sel {
  background-color: rgba(245, 245, 245, 0.733);
}

.correl-right {
  /* background-color: rgba(245, 245, 245, 0.733); */
}

.sl-tr-rg {
  background-color: lightgray !important;
}

.sl-rg {
  background-color: #1e7a69 !important;
}

.si-left {
  display: flex;
  align-items: center;
  width: 100%;
}

.correl-right p {
  font-weight: bold;
}

.correl-item .si-tit {
  /* width: 50%; */
  margin-right: 20px;
}

.ssa-neg {
  background-color: #a9363f;
  padding: 5px;
  border-radius: 5px;
}

.ssa-pos {
  background-color: #1e7a69;
  padding: 5px;
  border-radius: 5px;
}

.ssa-neg p,
.ssa-pos p {
  color: white !important;
}

.si-rds {
  margin-left: 20px;
}

.si-rds p {
  font-size: 12px;
}
