.price-boxes {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  margin: 40px 0px;
}

.price-item {
  width: calc(25vw - 40px);
  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow: 0rem 0.125rem 0.5rem -0.125rem rgba(65, 75, 90, 0.1),
    0rem 0.25rem 1.25rem 0rem rgba(65, 75, 90, 0.1);
  height: 60vh;
  padding: 20px;
}

.pi-st {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 10px;
}

.pi-st h5 {
  text-transform: uppercase;
  text-align: center;
  letter-spacing: 2px;
  opacity: 0.75;
  font-family: "MS-Reg";
}

.pi-icos {
  width: 75%;
  display: flex;
  flex-direction: column;
  /* align-items: center; */
}

.pii-item {
  display: flex;
  align-items: center;
  padding: 5px;
  /* width: 100%; */
  /* justify-content: space-between; */
}

.pii-item p {
  font-family: "MS-Reg";
  font-size: 15px;
  opacity: 0.8;
}

.pii-item svg {
  fill: #1e7a69 !important;
  margin-right: 20px;
  height: 24px !important;
  /* padding: 5px; */
  width: 24px !important;
  /* border: 2px solid #1e7a69; */
  /* border-radius: 50%; */
}

.pi-title {
  margin: 20px 0px 40px 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.pi-title h2 {
  color: #232c2e;
  font-family: "MS-Bold";
  font-size: 28px;
  text-transform: uppercase;
}

.pi-desc {
  display: flex;
  justify-content: center;
}

.pi-desc p {
  text-align: center;
  width: 75%;
  font-family: "MS-Reg";
  font-size: 15px;
  opacity: 0.8;
}

.pi-div {
  height: 1px;
  background-color: #edefef;
  width: 75%;
  margin: 20px 0px;
}
