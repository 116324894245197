.account-page {
  min-height: calc(100vh - 110px);
  background-color: #f5f5fac1;
}

.account-costs {
  /* height: 100%; */
  min-height: 80vh;
  padding-bottom: 40px;
}

.account-costs .loading {
  height: 80vh;
}

.ac-graph {
  box-shadow: rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
  width: 90vw;
  padding: 20px;
  border-radius: 20px;
}

.ac-top {
  padding: 0px 20px;
}

.ac-bd-bs {
  box-shadow: rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
  border-radius: 20px;
  display: flex;
  flex-wrap: wrap;
  row-gap: 5px;
}

.acg-box {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.ac-bd-item {
  display: flex;
  align-items: center;
  margin-right: 20px;
}

.ac-bd {
  margin-left: 40px;
}

.ac-bd-item h3 {
  color: #232c2e;
  font-family: "MS-SM";
  font-size: 14px;
  margin-right: 10px;
}

.ac-bd-item h5 {
  color: #232c2e;
  font-family: "MS-Reg";
}

.account-costs .gre-meu .MuiTab-root {
  text-transform: none !important;
}

.acc-bd {
  padding: 20px;
}

.acc-bd-opts {
  display: flex;
  align-items: center;
}

.abo-item {
  cursor: pointer;
  /* margin-right: 10px; */
  padding: 10px 20px;
  border-radius: 20px;
}

.abo-item p {
  font-size: 13px;
  font-weight: 600;
  font-family: "MS-SM";
}

.abs-item {
  display: flex;
  align-items: center;
}

.abs-item h3 {
  font-size: 13px;
  font-family: "MS-Reg";
  width: 10vw;
}

.abs-head h3 {
  font-family: "MS-SM";
  font-size: 14px;
  color: #232c2e;
}

.abc-head {
  display: flex;
  align-items: center;
}

.abc-head h3 {
  font-family: "MS-SM";
  font-size: 15px;
  margin-right: 10px;
}

.abc-head p {
  font-size: 15px;
  font-family: "MS-Reg";
}

.abs-head h5 {
  color: #232c2e;
  font-size: 12px;
  font-family: "MS-Reg";
  opacity: 0.5;
  margin-bottom: 5px;
}

.abs-item p {
  font-family: "MS-Reg";
  font-size: 13px;
}

.acc-bd-sum {
  margin-top: 20px;
}

.abs-total p {
  font-family: "MS-SM";
}

.abs-total h3 {
  font-family: "MS-SM";
}

.fpb-items {
  display: flex;
  flex-wrap: wrap;
}

.fpb-item {
  display: flex;
  align-items: center;
  width: 20vw;
}

.fp-change {
  border-bottom: 1px solid #edefef;
  padding: 10px 0px;
}

.fpc-abled {
  display: flex;
  align-items: center;
}

.fp-change h3 {
  font-size: 14px;
  color: #232c2e;
  margin-bottom: 2.5px;
}

.fpc-abled p {
  font-size: 13px;
}

.abled-feat {
  margin-right: 20px;
  display: flex;
  align-items: center;
}

.abled-feat svg {
  height: 12px !important;
  width: 12px !important;
  margin-left: 10px;
  padding: 0px !important;
}

.app-head {
  border-bottom: 2px solid #232c2e !important;
}

.ac-ps-player {
  display: flex;
  padding: 5px 0px;
  align-items: center;
  border-bottom: 1px solid #edefef;
}

.ac-ps-player p {
  width: 20vw;
  font-size: 14px;
  margin-right: 10px;
  font-family: "MS-Reg";
}

.ac-ps-status {
  width: 15vw;
}

.acc-details {
  display: flex;
  justify-content: center;
}

.acc-details .bs-subtle {
  width: 80vw;
}

.acc-det-box {
  display: flex;
  flex-wrap: wrap;
}

.acc-group p {
  font-size: 12px;
  opacity: 0.7;
  text-transform: uppercase;
  font-family: "MS-Reg";
}

.acc-group h3 {
  font-size: 14px;
  margin-top: 5px;
  /* margin-left: 5px; */
  text-transform: capitalize;
  font-family: "MS-SM";
}

.acc-group {
  width: max-content;
  margin-right: 40px;
}

.pay-history-row {
  display: flex;
  align-items: center;
  border-bottom: 1px solid #edefef;
}

.phr-header {
  padding-bottom: 5px;
  border-bottom: 2px solid #232c2e;
}

.phr-item {
  display: flex;
  justify-content: center;
  flex: calc(1 / 7);
}

.phr-header p {
  font-size: 14px;
  font-family: "MS-SM" !important;
}

.pay-history-row p {
  font-size: 13px;
  font-family: "MS-Reg";
}

.phr-suc p {
  color: #1e7a69 !important;
}

.phr-opt {
  display: flex;
  justify-content: center;
}

.op-msg {
  background-color: #a9363f;
  border-radius: 40px;
  padding: 20px;
}

.outstanding-payments {
  min-height: calc(100vh - 100px);
  overflow-y: scroll;
}

.op-msg p {
  color: whitesmoke;
  font-size: 14px;
  font-family: "MS-SM";
}

.op-msg a {
  color: whitesmoke;
  text-decoration: underline !important;
  cursor: pointer;
}

.opm-box {
  margin-top: 20px;
  display: flex;
  justify-content: center;
}

.outstanding-payments .back-row {
  margin-top: 0px !important;
}

.outstanding-payments {
  padding-top: 20px;
}

.op-row {
  display: flex;
  align-items: center;
  padding: 5px 0px;
  border-bottom: 1px solid #edefef;
}

.op-row div {
  flex: 0.2;
}

.opr-header p {
  font-size: 13px;
  font-family: "MS-SM" !important;
}

.opr-header {
  border-bottom: 2px solid #232c2e;
}

.op-row p {
  font-size: 13px;
  font-family: "MS-Reg";
}
