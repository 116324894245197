.emc-dia {
  width: 40vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
}

.big-red-icon {
  width: 12vw !important;
  height: 12vw !important;
}

.emc-dia p {
  margin-top: 20px;
  font-weight: 600;
  color: #232c2e;
  text-align: center;
  /* font-size: ; */
}

.emc-close {
  display: flex;
  justify-content: flex-end;
  width: 100%;
}

.info-message .page-header {
  padding: 0px !important;
}
