.closest-longest {
  width: calc(100% - 40px);
  /* padding: 0px 20px; */
  height: 100%;
  overflow: scroll;
  padding-bottom: 20px;
}

.closest-longest .neh-box {
  padding-left: 0px !important;
}

.cl-row-active p {
  color: white !important;
}
.cl-row-active h5 {
  color: white !important;
}

.closest-longest .sub-header {
  padding-left: 0px !important;
  margin-bottom: 20px;
}

.cl-row {
  display: flex;
  /* border-bottom: 1px solid #edefef; */
  width: max-content;
  align-items: center;
  padding: 5px 10px;
  border-radius: 2.5px;
  border-radius: 20px;
}

.cl-row:nth-of-type(even) {
  background-color: #e9f8f28f;
}

.cl-row p {
  font-size: 12px;
  width: 24vw;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #232c2e;
  font-weight: 500;
  margin-top: 0px !important;
}

.cl-row h5 {
  color: #232c2e;
  text-align: center;
  width: 10vw;
}
