.fd-l {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  width: 100%;
  padding: 10px 0px;
}

.fdl-left {
  display: flex;
  align-items: center;
  justify-content: center;
}

.ri-loader {
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-evenly;
}

.ril-left {
  flex: 0.8;
}

.player-loader {
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-evenly;
}

.player-loader .pi-right {
  flex: 0.8;
}

.cal-loader {
  width: 90%;
}

.live-load {
  height: calc(100vh - 130px);
  overflow: scroll;
  padding-top: 20px;
}

.score-loader {
  display: flex;
  justify-content: center;
}

.slc {
  display: flex;
  flex-direction: column;
  width: 90%;
}

.time-loader {
  margin-bottom: 20px;
  padding-left: 20px;
}

.tl-header {
  /* width: 25%; */
}

.tl-times {
  width: 50%;
}

.pl-top {
  display: flex;
  padding: 20px;
  align-items: center;
}

.plt-right {
  width: 30vw;
  padding-left: 20px;
}

.pl-mid {
  padding: 20px;
}

.copi-loader {
  padding: 20px;
}

.cpl-courses {
  display: flex;
}

.backdrop-loader {
  z-index: 1000;
  top: 50px;
}

.MuiCircularProgress-svg {
  color: #1e7a69 !important;
}

.bl-circle {
  background-color: white;
  border-radius: 10px;
  padding: 16px;
  box-shadow: 0px 2px 4px 1px rgb(0 0 0 / 28%);
}

.backdrop-loader .MuiBackdrop-root {
  z-index: 2 !important;
}

.bp-progger {
  width: 75vw;
  background-color: whitesmoke;
  padding: 20px;
  border-radius: 20px;
}

.bdpl-pro {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #232c2e6f;
  padding: 60px;
}

.bdpl-txt p {
  color: white;
  font-family: "MS-Reg";
}
