.di-ctt img {
  height: 10vw;
  width: 10vw;
}

.di-ctt {
  cursor: pointer;
  padding: 5px;
}
.di-ctt textarea {
  width: 10vw !important;
}

.drill-image {
  position: relative;
  margin-right: 20px;
  cursor: pointer;
}

.di-own {
  position: absolute;
  left: 10px;
  top: 5px;
  fill: white !important;
  z-index: 100;
}

.dd-desc p {
  font-size: 13px;
  color: #232c2e;
  font-family: "MS-Reg";
}

.dri-selected {
  border: 2px solid #6ba390;
}

.di-desc p {
  font-size: 12px;
  color: #232c2e;
  opacity: 0.5;
  padding: 10px;
  /* overflow: hidden; */
  display: -webkit-box;
  -webkit-line-clamp: 2; /* number of lines to show */
  line-clamp: 2;
  /* line-height: 12px; */
  overflow: scroll;
  -webkit-box-orient: vertical;
}

.ac-dri-box {
  padding-top: 0px !important;
}

.ad-filters {
  align-items: flex-start;
}

.academy-drills .cao-item {
  /* width: 10vw !important; */
  margin-bottom: 10px;
}
.flex-col {
  flex-direction: column;
}

.ac-dr-ch {
  /* width: 20vw !important; */
  justify-content: flex-start !important;
  row-gap: 5px;
}

.di-Easy {
  background-color: #6ba390;
}

.di-Medium {
  background-color: #232c2e;
}

.di-Hard {
  background-color: #db5461;
}

.di-diff {
  margin-left: 20px;
  border-radius: 5px;
  padding: 5px;
}

.di-diff p {
  color: whitesmoke;
  font-size: 12px;
}

.academy-drills .def-ip {
  width: 15vw !important;
}

.drill-item:last-of-type {
  margin-right: 0px !important;
}

.drill-item {
  flex: 0.25;
  /* margin-right: 20px !important; */
}

.drill-box {
  /* justify-content: space-evenly; */
}

.dd-img img {
  height: 16vw;
  width: 16vw;
  border-radius: 5px;
}

.di-sel {
  border: 2px solid #6ba390;
  background-color: whitesmoke;
  /* box-sizing: border-box; */
}

.aca-coach-card {
  padding-top: 20px;
  padding-bottom: 10px;
  width: 22vw;
  background-color: white;
  box-shadow: 0 1px 2px rgba(56, 65, 74, 0.15);
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 40px;
  margin-right: 2vw;
  border-radius: 20px;
}

.acc-bg-box img {
  height: 22vh;
  width: 22vw;
  object-fit: cover;
}

.ac-box {
  /* justify-content: space-between; */
  padding: 20px 2.5vw;
}

.acc-top-bg {
  position: relative;
  /* height: 10vh; */
}

.acc-bg-box::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  background: linear-gradient(to top, #171e32, #405189);
  opacity: 0.6;
}

.acc-ava {
  border: 5px solid white;
  display: flex;
  justify-content: center;
  margin-top: -6vh !important;
}

.acc-ava .MuiAvatar-root {
  height: 12vh;
  width: 12vh;
  border: 5px solid white;
}

.acc-deets {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.acc-deets h2 {
  color: #232c2e;
  font-weight: 500;
  font-size: 15px;
}

.acc-deets h3 {
  color: #232c2e;
  font-size: 13px;
  font-weight: 400;
  /* opacity: 0.5; */
}

.acc-stats {
  display: flex;
  align-items: center;
  margin-top: 10px;
  justify-content: space-evenly;
  width: 75%;
}

.accs-left,
.accs-right {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.acc-stats h4 {
  font-weight: 500;
  color: #232c2e;
  opacity: 0.8;
}

.acc-stats h2 {
  font-size: 12px;
  font-weight: 400;
  opacity: 0.5;
  color: #232c2e;
}

.accs-div {
  height: 75%;
  width: 1px;
  background-color: #edefef;
}

.acc-horiz {
  position: absolute;
  z-index: 100;
  background-color: transparent;
  border: none;
  right: 10px;
  top: 5px;
  cursor: pointer;
  border-radius: 50%;
}

.acc-horiz:hover {
  background-color: #edefef3a;
}

.acc-horiz .MuiSvgIcon-root {
  fill: white !important;
}

.acc-top-bg button {
}

.aca-cli-card .acc-top-bg {
  height: 22vh;
  width: 100%;
}

.ac-list-box {
  background-color: white;
  width: 100%;
}

.ac-dri-box .Dropdown-control {
  padding-top: 6.5px !important;
  padding-bottom: 6.5px !important;
}

.drill-creator {
  min-width: 90vw;
}

.drill-creator .Dropdown-control {
  padding-top: 6.5px !important;
  padding-bottom: 6.5px !important;
}
.aclb-box {
  padding: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.zer-reqs {
  margin-top: 10vh;
  display: flex;
  justify-content: center;
}

.zer-reqs p {
  font-size: 12px;
  color: #232c2e;
  font-family: "MS-Reg";
  opacity: 0.6;
}

.drill-creator .input-group {
  width: 25vw;
  margin-right: 5vw;
}

.dc-ctt-box {
  display: flex;
  flex-wrap: wrap;
}

.IconButton {
  background-color: transparent;
  border: none;
}

.acc-drill-row {
  display: flex;
  align-items: flex-start;
}

.acc-drill-ctt {
  height: 90vh;
  width: 90vw;
}

.acc-drill-row {
  width: 100%;
  padding: 0px 20px;
  margin-top: 20px;
}

.adr-item {
  padding: 0px 20px;
  flex: 0.2;
}

.adr-idx p {
  font-family: "MS-SM";
}

.acc-drill-row .input-group {
  margin-top: 0px !important;
}

.acc-drill-row .Dropdown-control {
  width: 10vw;
}

.acc-drill-row input {
  width: 10vw !important;
}

.cpl-box {
  height: 100%;
  display: flex;
  justify-content: center;
}

.sce-details {
  display: flex;
}

.sce-details .MuiAvatar-root {
  height: 12vw;
  width: 12vw;
}

.sce-details p {
  font-size: 14px;
  font-family: "MS-Reg";
}

.sd-text {
  padding-left: 20px;
}

.sd-text h3 {
  font-family: "MS-SM";
  margin-bottom: 5px;
}

.amd-row {
  display: flex;
  align-items: center;
  border-bottom: 1px solid #edefef;
}

.adr-item {
  flex: 0.2;
}

.amd-row p {
  font-size: 13px;
  font-family: "MS-Reg";
}

.amd-header p {
  font-family: "MS-SM" !important;
}

.amd-header {
  border-bottom: 2px solid #232c2e;
  padding-bottom: 2.5px;
}

.amd-dia-ctt {
  width: 90vw;
  height: 90vh;
}

.amd-dia-row {
  display: flex;
  align-items: center;
  padding: 5px 0px;
  border-bottom: 1px solid #edefef;
}

.adr-ite {
  flex: 0.25;
}

.amd-dia-ctt .sub-header {
  padding-left: 0px !important;
}

.adr-header {
  border-bottom: 2px solid #232c2e;
}

.adr-header p {
  font-family: "MS-SM" !important;
}

.adr-ite p {
  font-size: 13px;
  font-family: "MS-Reg";
}

.adr-perc p {
  font-family: "MS-SM";
}

.adr-item h3 {
  font-size: 16px;
}

.ddb-box {
  display: flex;
}

.ddb-left .MuiAvatar-root {
  height: 12vw;
  width: 12vw;
}

.ddb-right {
  padding-left: 20px;
}
