.page-header h2 {
  /* border-left: 5px solid #21c17c; */
  border-radius: 5px 0px 0px 5px;
  color: #232c2e;
  /* padding-left: 10px; */
  text-transform: uppercase;
  letter-spacing: 2px;
  transition: 300ms ease-in-out;
  width: max-content;
  font-weight: 500;
  font-size: 26px;
  /* border-bottom: 5px solid #1e7a69; */
  font-family: "MS-SM";
  /* font-family: "TT-Med"; */
}

.sett-ph .page-header h2 {
  max-width: 65vw !important;
}

.ph-link h2:hover {
  cursor: pointer;
  opacity: 0.7;
}

.page-header h2 {
  max-width: 75vw;
}

.sub-msg p {
  font-size: 13px;
  color: #232c2e;
}

.page-header svg {
  border-radius: 50%;
  cursor: pointer;
  padding: 2.5px;
}

.sett-ph-ico {
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  background-color: white;
}

.sett-ph {
  max-width: 90vw;
  display: flex;
  align-items: center;
  /* justify-content: space-between; */
  /* width: 60vw; */
}

.sett-ph .page-header {
  margin-right: 20vw;
}

.page-header svg:hover {
  background-color: #edefef;
}

.ph-top {
  padding-left: 20px;
}

.ph-top p {
  opacity: 0.5;
  text-transform: uppercase;
  letter-spacing: 1.5px;
  font-family: "MS-Reg";
}

.page-header {
  padding: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.page-header > h2 {
  /* max-width: 75vw; */
}

.back-row {
  cursor: pointer;
  display: flex;
  background-color: white;
  align-items: center;
  transition: 200ms ease-in-out;
  width: max-content;
  padding: 10px 20px 10px 20px;
  margin: 20px 0px 0px 20px;
  border-radius: 20px;
  transition: 200ms ease-in-out;
  /* box-shadow: rgba(9, 30, 66, 0.25) 0px 4px 8px -2px,
    rgba(9, 30, 66, 0.08) 0px 0px 0px 1px; */
  /* box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
    rgba(0, 0, 0, 0.3) 0px 1px 3px -1px; */
  box-shadow: rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
}

.hd-item .page-header {
  padding-left: 0px !important;
}

.back-row:hover {
  /* opacity: 0.5; */
  background-color: #edefef;
  /* background-color: #1e7a69; */
  /* transform: scale(1.1); */
}

.back-row:hover p {
  /* color: white !important; */
}

.back-row:hover svg {
  /* fill: white !important; */
}

.back-row:active {
  transform: scale(0.95);
}

.back-row p {
  margin-left: 20px;
  font-size: 16px;
  text-transform: uppercase;
  letter-spacing: 1.5px;
  color: #1e7a69 !important;
  margin-top: 0px !important;
  font-family: "MS-Bold" !important;
  filter: none !important;
  /* opacity: 0.7; */
}

.green-header {
  color: #1e7a69 !important;
  /* filter: none; */
  font-weight: 900;
  filter: drop-shadow(1px 2px 2px #1e7a69);
}

.back-row svg {
  fill: #1e7a69 !important;
}

.page-header p {
  margin-top: 5px;
  font-weight: 300;
  font-size: 16px;
  color: #232c2e;
  opacity: 0.5;
}

.bot-grad {
  border-bottom: 2px solid #1e7a69;
  /* border-image:  
  linear-gradient(0.25turn,
    #21c17c, 
    #1e7a69
  );
  padding-bottom: 1px;
  border-image-slice: 1;
  width: max-content; */
}

.align-center {
  align-items: center;
}

.page-header h5 {
  text-transform: capitalize;
  margin-top: 2.5px;
  font-weight: 400;
  color: #232c2e;
  opacity: 0.7;
}

.sub-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.sub-header p {
  color: #1e7a69;
  font-size: 14px;
  font-weight: 600;
  cursor: pointer;
}

.sub-header p:hover {
  text-decoration: underline;
}

.sub-header h5 {
  /* border-left: 5px solid #1e7a69; */
  /* border-radius: 5px 0px 0px 5px; */
  /* padding-left: 10px; */
  color: #232c2e;
  font-size: 16px;
}

.page-header .aboh-border {
  width: 75%;
}

.default-input {
  -webkit-appearance: none !important;
  box-shadow: rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;

  /* box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
    rgba(60, 64, 67, 0.15) 0px 1px 3px 1px; */
  /* box-shadow: 0px 2px 2px 1px rgb(0 0 0 / 28%) !important;
  -webkit-box-shadow: 0px 2px 2px 1px rgb(0 0 0 / 28%) !important; */
  padding: 12.5px 20px;
  width: 20vw;
  font-size: 12px;
  border-radius: 20px;
  border: none;
  border: 1px solid #8e91917e;
  /* background-color: #edefef; */
  color: #5c6363;
  min-height: 10px;
}

.ig-header {
  display: flex;
  align-items: center;
}

.input-group {
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  justify-content: flex-end;
  width: max-content;
}

.nassau-group {
  width: 25vw !important;
}

.ip-30 {
  width: 30vw;
}

.input-group p {
  font-family: "MS-SM";
  font-weight: 600;
  font-size: 13px;
  color: #232c2e;
  margin-bottom: 10px;
  margin-top: 0px;
}

.error-message {
  padding: 20px;
}

.error-message p {
  color: #e24955;
}

.green {
  color: #1e7a69 !important;
  cursor: pointer;
  transition: 200ms ease-in-out;
}

.sub-header h3 {
  position: relative;
}

.sub-header h3::after {
  content: "";
  height: 2px;
  background-color: #1e7a69;
  /* width: 75%; */
  position: absolute;
  top: 0px;
}

.green:hover {
  text-decoration: underline;
}

.green-link {
  border: none !important;
  background-color: #1e7a69;
  color: white !important;
  padding: 7.5px 15px;
  font-size: 13px;
  font-family: "MS-Bold";
  text-transform: uppercase;
  border-radius: 20px;
  cursor: pointer;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 2px;

  /* box-shadow: 0 6px 6px -2px rgb(50 50 93 / 25%),
    0 3px 5px -3px rgb(0 0 0 / 30%); */
  width: max-content;
  transition: 200ms ease-in-out;
}

.green-link:hover {
  transform: scale(1.05);
}

.green-link:active {
  transform: scale(1);
}
.black-link {
  border: none !important;
  background-color: #232c2e;
  color: white !important;
  padding: 7.5px 15px;
  font-size: 14px;
  font-family: "MS-Bold";
  text-transform: uppercase;
  border-radius: 20px;
  cursor: pointer;
  box-shadow: 0 6px 6px -2px rgb(50 50 93 / 25%),
    0 3px 5px -3px rgb(0 0 0 / 30%);
  width: max-content;
  transition: 200ms ease-in-out;
}

.black-link:hover {
  transform: scale(1.05);
}

.black-link:active {
  transform: scale(1);
}

.group-selector .black-link {
  font-size: 12px !important;
}

.red-link {
  border: none !important;
  background-color: #a9363f;
  color: white !important;
  padding: 5px 10px;
  font-size: 14px;
  font-family: "MS-Bold";
  text-transform: uppercase;
  border-radius: 20px;
  cursor: pointer;
  box-shadow: 0 6px 6px -2px rgb(50 50 93 / 25%),
    0 3px 5px -3px rgb(0 0 0 / 30%);
  width: max-content;
}

.ne-header {
  /* border-left: 5px solid #1e7a69;
  border-radius: 5px 0px 0px 5px;
  padding-left: 10px; */
}

.neh-box {
  padding: 20px;
}

.ne-header {
  font-size: 16px;
  font-family: "MS-SM";
}

.event-home .ne-header {
  font-size: 16px;
}

.error-message-row p {
  color: #e24955 !important;
  opacity: 0.7;
  font-weight: 600 !important;
  font-size: 14px;
  text-align: center;
  /* width: 80vw; */
  max-width: 25vw;
  margin-top: 20px;
}

.ph-msg {
  padding-left: 20px;
}

.ph-msg p {
  font-size: 11px;
  opacity: 0.5;
  font-family: "MS-Reg";
}

.round-input .ph-msg p {
  font-size: 16px;
}

.header-group {
  display: flex;
  align-items: center;
}

.dg-icon {
  fill: #1e7a69 !important;
  /* padding: 7.5px; */
}

.dg-icon-bg {
  /* background-color: #1e7a69 !important; */
  /* fill: white !important; */
  fill: #1e7a69 !important;
  border-radius: 50% !important;
  background-color: white;
  padding: 7.5px;
  /* border: 1px solid #edefef; */
  /* box-shadow: rgba(99, 99, 99, 0.1) 0px 2px 8px 0px; */
  /* box-shadow: rgba(0, 0, 0, 0.3) 0px 1px 3px -1px; */
  /* box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px,
    rgba(0, 0, 0, 0.3) 0px 3px 7px -3px; */
  box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 2px;
}

.dg-icon-bg:hover {
  background-color: #edefef !important;
}

.red-icon-bg:hover {
  background-color: #edefef !important;
}

.red-icon-bg {
  fill: #a9363f !important;
  /* background-color: #a9363f !important; */
  /* fill: white !important; */
  border-radius: 50% !important;
  padding: 7.5px;
  background-color: white;
  /* box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px,
    rgba(0, 0, 0, 0.3) 0px 3px 7px -3px; */
  /* box-shadow: rgba(99, 99, 99, 0.1) 0px 2px 8px 0px; */
  /* box-shadow: rgba(0, 0, 0, 0.3) 0px 1px 3px -1px; */
  box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 2px;
}

.span-link {
  font-size: inherit !important;
  cursor: pointer;
  text-decoration: underline;
}

.span-link:hover {
  opacity: 0.7;
}

.details-item {
  display: flex;
  align-items: center;
}

.details-item h3 {
  margin-right: 10px;
  font-size: 14px;
  font-family: "MS-SM";
}

.details-item p {
  font-size: 14px;
  font-family: "MS-Reg";
}

.red {
  color: #a9363f !important;
}
