.footer {
  display: flex;
  /* align-items: center; */
  justify-content: space-between;
  width: 100vw;
  bottom: 0;
  height: max-content;
  /* height: 80px; */
  background-color: #0f3d35;
  /* background-image: linear-gradient(135deg, #1e7a69 0%, #21c17c 100%);   */
  opacity: 0.95;
  /* background-image: linear-gradient(180deg, #1e7a69 0%, #232c2e 100%); */
}

.footer-left {
  flex: 0.3;
  display: flex;
  padding-left: 5vw;
  /* align-items: center; */
  flex-direction: column;
  /* justify-content: center; */
}

.footer-left p {
  color: white;
  font-size: 14px;
  /* margin-bottom: 10px; */
}

.link-pee {
  color: white;
  text-decoration: underline;
  font-size: 14px;
  /* margin-bottom: 10px; */
  cursor: pointer;
  /* opacity: 1; */
}

.link-pee:hover {
  opacity: 1 !important;
}

.footer-middle {
  flex: 0.3;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 75%;
  justify-content: space-evenly;
}

.footer-middle p {
  color: #fff;
  opacity: 0.7;
  font-size: 12px;
  /* margin-bottom: 10px; */
}

.footer-right {
  flex: 0.3;
  display: flex;
  /* align-items: center; */
  flex-direction: column;
  align-items: flex-end;
  padding-right: 5vw;
  /* justify-content: center; */
}

.fr-header {
  margin-top: 20px;
  margin-bottom: 10px;
}

.fr-header h2 {
  color: white;
  font-family: "MS-Bold";
  font-size: 20px;
  text-transform: uppercase;
}

.footer-logos {
  display: flex;
  align-items: center;
  /* justify-content: space-evenly; */
  width: 50%;
  margin-top: 10px;
  justify-content: flex-end;
}

.footer-logo {
  fill: #fff !important;
  height: 30px;
  width: 30px;
  margin-left: 10px;
  cursor: pointer;
}

.fl-reg {
  margin-top: 10px;
}

.fl-reg input {
  border-radius: 20px;
  margin-bottom: 20px;
  padding: 10px 20px;
  border: none;
  outline-color: #21c17c;
}

.ib-buttons {
  display: flex;
  flex-direction: column;
}

.ib-buttons button {
  /* width: 10vw !important; */
}

.fl-reg .ib-buttons {
  justify-content: flex-start;
  margin-bottom: 20px;
}

.fl-reg button {
  width: max-content;
  padding: 10px 30px;
  font-family: "MS-Bold";
  font-size: 20px !important;
}

@media all and (min-width: 1024px) and (max-width: 1280px) {
}

@media all and (min-width: 768px) and (max-width: 1024px) {
}

@media all and (min-width: 480px) and (max-width: 768px) {
  .footer-logo {
    height: 25px;
    width: 25px;
  }

  .footer-logos {
    width: 100%;
  }
  .fr-header h2 {
    /* display: none; */
  }

  .footer-right {
    align-items: center;
    order: 2;
  }

  .footer-middle {
    order: 3;
  }

  .footer-logos {
    justify-content: center;
  }

  .fr-header {
    justify-content: center;
  }

  .fl-reg {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .fl-reg input {
    width: 75vw;
  }

  .fl-reg button {
    width: 50vw !important;
  }

  .footer-left {
    align-items: center !important;
    padding-left: 0px !important;
  }

  .footer {
    padding: 20px 0px;
    flex-direction: column;
  }
}

@media all and (max-width: 480px) {
  .footer-logo {
    height: 25px;
    width: 25px;
  }

  .footer-logos {
    width: 100%;
  }
  .fr-header h2 {
    /* display: none; */
  }

  .footer-right {
    align-items: center;
    order: 2;
  }

  .footer-middle {
    order: 3;
  }

  .footer-logos {
    justify-content: center;
  }

  .fr-header {
    justify-content: center;
  }

  .fl-reg {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .fl-reg input {
    width: 75vw;
  }

  .fl-reg button {
    width: 50vw !important;
  }

  .footer-left {
    align-items: center !important;
    padding-left: 0px !important;
  }

  .footer {
    padding: 20px 0px;
    flex-direction: column;
  }
}
