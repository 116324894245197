.matchplay-score {
  display: flex;
  /* align-items: center; */
  width: 100%;
  margin-bottom: 20px;
  box-shadow: rgb(149 157 165 / 20%) 0px 8px 24px;
  border-radius: 7.5px;
  border: 1px solid #edefef;
  box-sizing: border-box;
}

.matchplay-score:active {
  opacity: 0.8;
  /* background: #edefef !important; */
}

.c-s-box .matchplay-score {
  cursor: pointer;
}

.ms-left {
  flex: 0.4;
  display: flex;
  align-items: center;
  /* background-color: transparent; */
  /* justify-content: center; */
  /* height: 12.5vw;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px; */
}

.ms-left p,
.ms-right p {
  font-weight: 600;
  font-size: 13px;
  color: #232c23;
}

.ms-right-box {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 20px;
  border-top-right-radius: 10px;
  width: 100%;

  border-bottom-right-radius: 10px;
}

.rd-matchups .matchplay-score {
  margin-top: 10px;
}

.ms-left-box {
  height: 100%;
  width: 100%;

  display: flex;
  justify-content: center;
  align-items: center;
  padding-right: 20px;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}

.ms-left p {
  padding-left: 5px;
}

.ms-right p {
  padding-right: 5px;
  text-align: end;
}

.ms-mid {
  flex: 0.2;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.msm-top {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #21c17c;
  width: 6vw;
  height: 6vw;
  border-radius: 50%;
  z-index: 1;
}

.msm-top p {
  color: white;
  font-size: 13px;
  font-weight: bold;
  text-align: center;
}

.msm-bot {
  margin-top: 5px;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.msm-bot p {
  font-weight: 600;
  font-size: 12px;
  opacity: 0.7;
}

.ms-right {
  flex: 0.4;
  display: flex;
  /* height: 12.5vw; */
  align-items: center;
  justify-content: flex-end;
  /* border-top-right-radius: 10px; */
  /* border-bottom-right-radius: 10px; */
}

.ms-active {
  /* background-color: #21c17c; */
}

.ms-active p {
  color: white;
  opacity: 0.9;
}

/* .msra {
    padding-left: 35px;
    margin-left: -35px;
} */

.msla {
  border-radius: 10px;
}

.ms-left-border {
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 50px 0 50px 30px;
  /* background-color: white; */
  /* border-color: #21c17c; */
  /* border-color: transparent transparent transparent #21c17c; */
}

.ms-right-border {
  width: 0;
  height: 0;
  border-style: solid;
  /* border-width: 50px 0 50px 30px; */
  border-width: 50px 30px 50px 0px;
  /* background-color: white; */
  /* border-color: #21c17c; */
  /* border-color: transparent transparent transparent #21c17c; */
}

.mphs-box {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  padding: 5px 5px;
}

.mphs-hole {
  border: 1px solid #edefef;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  flex: calc(1 / 19);
  height: 2.5vh;
  /* padding: 5px; */
}

.mphs-hole p {
  font-size: 10px;
  font-weight: bold;
}

.irr {
  background: linear-gradient(
      to top left,
      rgba(0, 0, 0, 0) 0%,
      rgba(0, 0, 0, 0) calc(50% - 0.8px),
      rgba(0, 0, 0, 0.5) 50%,
      rgba(0, 0, 0, 0) calc(50% + 0.8px),
      rgba(0, 0, 0, 0) 100%
    ),
    linear-gradient(
      to top right,
      rgba(0, 0, 0, 0) 0%,
      rgba(0, 0, 0, 0) calc(50% - 0.8px),
      rgba(0, 0, 0, 0) 50%,
      rgba(0, 0, 0, 0) calc(50% + 0.8px),
      rgba(0, 0, 0, 0) 100%
    );
}
