.event-home {
  /* height: calc(100vh - 110px); */
  /* overflow: scroll; */
  /* height: 100%; */
  min-height: calc(100vh - 80px);
  background-color: #f5f5fac1;
}

.ehb-header {
  display: flex;
  align-items: center;
  /* justify-content: space-between;
    padding-right: 20px; */
  padding: 20px;
}

.ehb-header h1 {
  margin-right: 20px !important;
  width: 50px;
}

.err-icon {
  width: 10vw !important;
  height: 10vw !important;
}

.eh-invite-add svg {
  margin-left: 20px;
  height: 20px !important;
  width: 20px !important;
}

.p-r-course {
  display: flex;
  align-items: center;
  /* padding-left: 20px; */
  /* margin-bottom: 20px; */
  /* margin-top: 20px; */
}

.eh-box-box .p-r-course {
  margin-left: 5vw;
}

.eh-ql {
  width: 60vw;
  flex-wrap: wrap;
}

.eh-ql .pill-button {
  margin-bottom: 20px;
}

.p-r-course h2 {
  color: #232c23;
  padding-right: 20px;
  /* font-size: 20px; */
}

.p-r-course .MuiAvatar-root {
  height: 16vw !important;
  width: 16vw !important;
}

.ehbh-item svg {
  height: 20px !important;
  width: 20px !important;
  box-shadow: 1px 1px 1px 1px #edefef;
  fill: #1e7a69 !important;
  background-color: white;
  border-radius: 50%;
  padding: 5px;
}

.eh-box-box {
  display: flex;
  align-items: center;
}

.ehb-header svg {
  height: 20px !important;
  width: 20px !important;
}

.ehbh-item .info-circle {
  background: transparent !important;
}

.part-players-box {
  display: flex;
  flex-wrap: wrap;
}

.part-players-box .player-item {
  margin-right: 5vw;
}

.dc-ic-op {
  opacity: 0;
}

.no-players p {
  font-size: 12px;
  /* text-align: center; */
  opacity: 0.7;
}

.no-players {
  margin-bottom: 20px;
}

.ehbh-item p {
  margin-left: 10px;
}

.ehbh-item {
  width: 50vw;
  display: flex;
  align-items: center;
  padding-left: 20px;
  margin-bottom: 10px;
}

.eh-box {
  display: flex;
  flex-direction: column;
  /* padding-left: 20px; */
}

.eh-box p {
  font-weight: 400;
  font-size: 12px;
  font-family: "MS-Med";
  color: #232c2e;
}

.eh-pee {
  font-weight: normal !important;
}

.ehb-rounds {
  padding: 0px 20px;
}

.delete-popup {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
}

.delete-popup p {
  text-align: center;
  margin-top: 20px;
  font-weight: 600;
  font-size: 16px;
  width: 75%;
  color: #232c2e;
  margin-bottom: 20px;
}

.event-home .ts-header {
  background-color: transparent !important;
}

.delete-button:hover {
  transform: scale(1.05);
}

.delete-button:active {
  opacity: 0.7;
}

.cancel-button-row {
  display: flex;
  width: 100%;
  justify-content: center;
}

.no-mt {
  margin-top: 0px !important;
}

.err-icon {
  /* height: 40px !important;
  width: 40px !important; */
  margin-top: 40px;
  fill: #a9363f !important;
}

.ec-box h3 {
  font-size: 13px;
  font-family: "MS-SM";
}

.no-rounds p {
  font-size: 13px;
  font-family: "MS-Med";
}

.event-home h1 {
  /* margin-bottom: 10px !important; */
}

.event-home .player-item {
  width: 20vw !important;
}

.eh-players-box {
  display: flex;
  padding: 0px 20px;
}

.epu-players {
}

.epup-box {
  padding: 20px;
  display: flex;
  flex-direction: column;
  width: 50vw;
  /* flex-wrap: wrap; */
}

.epup-box p {
  margin-right: 20px;
  color: #232c23;
  margin-bottom: 2.5px;
}

.new-guest-row input {
  width: 10vw;
}

.new-guest-row {
  width: 75vw;
  justify-content: space-between;
}

.golf-id input {
  margin-right: 20px;
}

.new-guest-row .input-group {
  margin-top: 0px !important;
}

.ng-del {
  background-color: #a9363f;
  fill: white !important;
  border-radius: 50% !important;
  padding: 7.5px;
  margin-left: 10px;
}

.eg-guests {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.eg-guests .player-item {
  width: 15vw !important;
  margin-right: 5vw;
  margin-bottom: 20px;
}

.rt-display {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}

.rt-edit-player {
  display: flex;
  align-items: center;
  padding-left: 20px;
}

.rt-ep-pl p {
  font-size: 14px;
  width: 20vw;
}

.pcs-player {
  display: flex;
  align-items: center;
}

.pcs-player .player-item {
  margin-right: 20px;
}

.epl-pl {
  padding: 20px;
  margin-bottom: 100px;
}

.epl-player {
  margin-bottom: 10px;
}

.epl-header > div {
  display: flex;
  align-items: center;
  justify-content: center;
}

.epl-header p {
  text-transform: uppercase;
  letter-spacing: 1px;
  padding-bottom: 2px;
  opacity: 0.8;
  font-size: 15px !important;
  text-align: center;
  border-bottom: 2px solid #1e7a69;
  font-family: "MS-SM";
}

.epl-player {
  display: flex;
  align-items: center;
  padding: 5px 0px;
}

.epl-name {
  flex: 0.2;
}

.epl-crs {
  flex: 0.2;
  padding: 0px 10px;
}

.epl-tee {
  flex: 0.1;
  padding: 0px 10px;
}

.epl-hi {
  flex: 0.1;
  padding: 0px 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.epl-hi p {
  color: #232c2e;
  font-size: 13px;
}

.epl-edit {
  flex: 0.15;
}

.epl-player p {
  /* font-family: "MS-Med"; */
}

.evt-player-list .epl-player .Dropdown-control {
  padding-top: 5px !important;
  padding-bottom: 5px !important;
}

.epl-edit svg {
  height: 18px !important;
  width: 18px !important;
}

.epl-tee .Dropdown-placeholder {
  text-transform: capitalize;
  /* font-size: 13px; */
}

.epl-tee .Dropdown-option {
  text-transform: capitalize;
}

.epl-name p {
  color: #232c2e;
  font-weight: 500;
  font-size: 13px;
}

.epl-ph input {
  width: 5vw !important;
}

.epr-ctt .player-item {
  width: 20vw !important;
}

.epr-ctt {
  min-width: 90vw;
  min-height: 75vh;
}

.epb-left .radio-group {
  padding-bottom: 0px !important;
  margin-top: 0px !important;
  margin-left: 20px;
}
