.pp-friends {
  padding: 20px;
  display: flex;
  flex-wrap: wrap;
}

.pp-friends .player-item {
  width: 25vw !important;
  margin-right: 2.5vw;
}

.friend-remove {
  padding: 20px 40px;
}

.friend-remove button {
  margin: 20px 0px;
}

.sis-ig {
  display: flex;
  align-items: center;
}

.sis-ig button {
  margin-left: 5vw;
}

.sis-loading {
  padding-top: 20vh;
}

.players-page {
  min-height: calc(100vh - 110px);
}

.players-page .no-msg {
  width: 100%;
}
