.em-close-row {
  display: flex;
  justify-content: flex-end;
  width: 100%;
}

.err-mod-dia p {
  color: #232c2e;
  font-size: 14px;
}

.err-mod-dia .MuiPaper-root {
  border-radius: 20px !important;
  width: 50vw;
}

.err-mod-ctt {
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.err-ico {
  fill: #db5461 !important;
  width: 10vh !important;
  height: 10vh !important;
}

.cm-ctt {
  padding-top: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-width: 30vw;
  min-height: 20vw;
}

.confirm-modal .MuiPaper-root {
  border-radius: 20px !important;
}

.ct-txt {
  width: 50%;
}

.ct-txt p {
  text-align: center;
  color: #232c2e;
  font-size: 15px;
  opacity: 0.7;
  font-family: "MS-Reg";
}

/* .cm-ctt  */
