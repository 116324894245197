.teams-container {
  display: flex;
}

.teams-column {
  flex: 0.5;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
}

.team-name-input {
  display: flex;
  width: 100%;
}

.teams-column input {
  width: 50% !important;
}

.team-name-input {
  margin-bottom: 20px;
}

.tc-name {
  width: 100%;
}

.tc-name h5 {
  font-weight: 500 !important;
  opacity: 0.7;
  font-size: 13px !important;
  margin-bottom: 5px;
}

.team-selector .player-profile {
  cursor: pointer;
}
