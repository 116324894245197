.cup-score-blocks {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin-top: 20px;
}

.csb-mid {
    width: 100%;
    display: flex;
    border-radius: 10px;

}

.csp-left {
    display: block;
    align-items: center;
    justify-content: center;
}

.csp-right {
    display: block;
    align-items: center;
    justify-content: center;
}

.csp-block {
    height: 10vh;
    border-right: 1px solid #edefef;
    border-radius: 3px;
}