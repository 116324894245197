.player-item {
  border-radius: 20px;
  padding: 20px;
  box-shadow: rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;

  /* box-shadow: rgb(149 157 165 / 20%) 0px 8px 24px; */
  width: 40vw;
}

.player-item .MuiAvatar-root {
  height: 50px;
  width: 50px;
}

.hcp-container svg {
  height: 16px !important;
}

.player-list-item {
  display: flex;
  align-items: center;
  width: 100%;
  border-bottom: 1px solid #edefef;
}

.plih-sel-up p {
  color: #e24955 !important;
}
.plih-sel-dw p {
  color: #1e7a69 !important;
}

.player-list-item p {
  color: #232c2e;
  font-size: 13px;
}

.pli-item {
  flex: 0.25;
}

.pli-head p {
  text-transform: uppercase;
  font-weight: bold;
  cursor: pointer;
}

.pp-friends .pi-add-icon {
  margin-top: 0px !important;
  margin-bottom: 10px;
}

.player-score {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  border-radius: 20px;
  /* transition: opacity 0.3s linear; */
}

.ps-right-middle h3 {
  margin: 0px !important;
}

@keyframes scale-in-ps {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.scale-in-ps {
  -webkit-animation: scale-in-ps 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  animation: scale-in-ps 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

.ps-left {
  display: flex;
  align-items: center;
  /* justify-content: center; */
  flex: 0.2;
}

.ps-right {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex: 0.9;
  margin-left: 20px;
}

.ps-right-left {
  display: flex;
  flex-direction: column;
  flex: 0.6;
}

.hcp-container {
  display: flex;
  align-items: center;
}

.hcp-container p {
  margin-left: 10px;
}

.player-score p {
  padding: 0px !important;
  font-weight: normal;
  color: #5c6363;
  font-size: 13px;
  margin-top: 0px !important;
}

.player-score h3 {
  color: #232c2e;
  font-weight: 600;
  font-size: 15px !important;
  margin-bottom: 5px !important;
}

.player-score h3:hover {
  cursor: pointer;
  text-decoration: underline;
}

.ps-right-middle {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 0.2;
}

.mb-10 {
  margin-bottom: 10px !important;
}

.ps-right-right {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 0.2;
}

.player-score .MuiBadge-badge {
  /* color: white !important; */
  /* background-color: #21c17c; */
  font-weight: bold !important;
  color: #232c2e;
  font-size: 12px;
  border-bottom: 1px solid #edefef;
  /* border-radius: 0px !important; */
}

.leaderboard .player-score .MuiBadge-root {
  margin-left: 10px;
}

.ps-bg-badge .MuiBadge-badge {
  color: white !important;
  background-color: #21c17c;
  font-size: 0.75em !important;
  background-image: linear-gradient(
    125deg,
    #21c17c 0%,
    #209e73 40%,
    #21c17c 80%
  );
}

.fd-major .ps-bg-badge .MuiBadge-badge {
  background-color: #d4af37;
}
.fd-final .ps-bg-badge .MuiBadge-badge {
  background-color: #8e9191;
}

.vertical-ruler {
}

.pi-icons {
  display: flex;
  flex-direction: column;
}

.pi-icon {
  cursor: pointer;
}
