.rule-item h2 {
    color: #232c2e;
    font-size: 14px;
    font-weight: 600;
}

.rule-item p {
font-size: 12px;
color: #232c2e;
opacity: 0.8;
font-weight: 500;
padding: 0px;
margin: 0px;
}

.rules-container {
    padding: 0px 20px;
}

.custom-rules .svg-cont {
    margin-left: 20px;
    margin-bottom: 10px;
}

.rule-item {
    /* margin-bottom: 20px; */
    display: flex;
    /* border-top: 1px solid #edefef; */
    padding: 10px;
    margin: 10px 0px;
    border-radius: 20px;
    cursor: pointer;
}

.rule-item:hover {
    background-color: #e9f8f291;
}

.ri-sel {
    background-color: #e9f8f2;
}

.ri-left {
    flex: 0.2;
    display: flex;
    align-items: center;
    /* justify-content: center; */
}

.ri-right {
    flex: 0.8;
}

.add-rule {
    padding: 0px 20px;
}