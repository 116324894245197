.news-pills {
  padding: 0px 20px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-top: 20px;
}

.news-pill p {
  font-size: 13px;
  /* opacity: 0.8; */
  margin: 0px !important;
  color: #1e7a69;
  font-weight: 600;
  padding: 0px !important;
}

.news-pill {
  /* border: 2px solid #1e7a69; */
  border-radius: 20px;
  padding: 5px 15px;
  margin-right: 5px;
  margin-bottom: 5px;
  cursor: pointer;
  background-color: white;
  /* text-transform: uppercase; */
  box-shadow: rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;

  /* box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px,
    rgba(0, 0, 0, 0.3) 0px 3px 7px -3px; */
}

.news-pill:active {
  background-color: #edefef;
}

.rankings .news-pill:active {
  background-color: #209e73 !important;
  color: white;
}

.news-pill-active {
  /* background: #209e73; */
  color: white;
  font-weight: 600;
  background-color: #1e7a69;
  /* border-color: #209e73; */
  /* background-image: linear-gradient(125deg, #209e73 0%, #1e7a69 100%); */
}

.news-pill-active p {
  color: white !important;
}

.rankings .news-pill {
  padding: 2.5px 20px;
  border-color: #209e73;
  /* borderw */
}

.rankings .news-pill p {
  font-size: 12px;
  font-weight: 600;
  color: #209e73;
}
