.home-dash {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 2.5vw 5vw;
  /* background-color: white; */
}

.home {
  /* background-image: url('../../assets/images/greenbg.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center; */
  min-height: calc(100vh - 80px);
  overflow: scroll;
  background-color: #f5f5fac1;
}

.hd-item {
  width: 40vw;
  height: 30vw;
  overflow: scroll;
  padding: 20px;
  background-color: white;
  border-radius: 30px;
  margin-bottom: 10vh;
  box-shadow: rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
  /* box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px,
    rgba(14, 30, 37, 0.32) 0px 2px 16px 0px; */
}

.home-events {
  height: 100%;
}

.he-loader {
  height: calc(100% - 80px);
  display: flex;
  justify-content: center;
  align-items: center;
}

.hae-events {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  padding: 20px;
}

.hae-events .fixture-preview {
  width: 30vw;
  margin-right: 5vw;
}

.hae-events .fixture-preview:hover {
  background-color: #edefef;
  cursor: pointer;
}

.no-msg {
  display: flex;
  align-items: center;
  justify-content: center;
  /* height: 100%; */
  margin-top: 10vh;
}

.no-msg p {
  text-transform: uppercase;
  color: #232c2e;

  opacity: 0.75;
  font-size: 18px;
  font-weight: 400;
  letter-spacing: 1.5px;
  font-family: "MS-Med";
}
