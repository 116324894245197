.clubs {
  min-height: calc(100vh - 110px);
}

.club-editor .player-item {
  width: 25vw !important;
  margin-right: 5vw;
}

.club-editor {
  padding-bottom: 40px;
  min-height: 80vh;
}

.cc-sel {
  background-color: rgb(199, 239, 222);
}

.clp-ico {
  height: 14px !important;
  width: 14px !important;
}

.tv-code-display {
  padding: 20px 0px 0px 20px;
}

.club-stats {
  min-height: calc(100vh - 110px);
  background-color: #f5f5fac1;
  padding-top: 20px;
}

.tv-code-display p {
  color: #232c2e;
  font-size: 15px;
  border-bottom: 2px solid #1e7a69;
  padding-bottom: 5px;
  width: max-content;
}

.tv-code-display span {
  font-weight: 600;
}

.cs-rounds-info {
  display: flex;
  align-items: center;
  padding: 20px 20px 0px 20px;
}

.cs-rounds-info p {
  font-size: 16px;
  color: #232c2e;
  margin-right: 5px;
}

.cs-rounds-info h5 {
  color: #232c2e;
  font-size: 16px;
  border-bottom: 2px solid #1e7a69;
}

.ch-leagues .course-item {
  margin-bottom: 20px !important;
}

.crop-box {
  /* position: relative; */
  /* z-index: 999; */
  /* position: absolute; */
  /* display: flex; */
  height: 50vh;
  width: 50vh;
  /* flex-direction: column; */
}

.crop-but {
  margin-top: 100px;
  z-index: 999;
  padding-bottom: 20px;
}

.cd-close {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  padding-right: 10px;
  padding-top: 10px;
  z-index: 1000;
}

.cd-close svg {
  background-color: white;
  border-radius: 50%;
  cursor: pointer;
}

.crop-drop {
  z-index: 999 !important;
}

.crop-co {
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 999;
  position: absolute;
}

.crop-box-box {
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  /* position: absolute; */
}

.reactEasyCrop_Container {
  z-index: 999;
}

.cbo-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
}

.club-course {
  display: flex;
  width: 25vw;
  margin-right: 2.5vw;
  box-shadow: rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;

  /* box-shadow: rgb(149 157 165 / 20%) 0px 8px 24px; */
  border-radius: 20px;
  padding: 20px;
  align-items: center;
  background-color: white;
}

.cc-right {
  display: flex;
  padding-left: 20px;
  justify-content: space-between;
  width: 100%;
}

.ce-admins {
  width: 75vw;
  padding: 20px;
}

.club-player-stats {
  min-height: calc(100vh - 110px);
  background-color: #f5f5fac1;
  padding-top: 20px;
}

.club-players .player-item .MuiAvatar-root {
  height: 50px !important;
  width: 50px !important;
}

.club-players .player-item {
  /* width: 20vw !important; */
}

.club-players .back-row,
.club-leagues .back-row,
.club-events .back-row,
.club-player-stats .back-row,
.club-stats .back-row {
  margin-top: 0px !important;
}

.club-players {
  min-height: calc(100vh - 110px);
  background-color: #f5f5fac1;
  padding-top: 20px;
}

.cea-players {
  display: flex;
  flex-wrap: wrap;
}

.club-events {
  min-height: calc(100vh - 150px);
  background-color: #f5f5fac1;
  padding-top: 20px;
}

.club-leagues {
  min-height: calc(100vh - 150px);
  background-color: #f5f5fac1;
  padding-top: 20px;
}
.ce-admins .player-item {
  width: 18vw;
  margin-right: 20px;
}

.cc-right h2 {
  color: #232c2e;
  font-size: 12px;
  width: 75%;
}

.cc-right p {
  font-size: 10px;
  opacity: 0.5;
  color: #232c2e;
}

.club-course .MuiAvatar-root {
  height: 80px !important;
  width: 80px !important;
}

.ccr-icons {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.cc-box {
  display: flex;
  align-items: center;
}

.cc-box p {
  text-align: center;
}

.club-course .MuiCheckbox-root {
  color: #1e7a69 !important;
}

.ce-events {
  padding: 20px;
  display: flex;
  flex-wrap: wrap;
  /* justify-content: spaceb; */
}

.ce-events .fixture-preview {
  width: 40vw;
  margin-right: 5vw;
}

.ce-filters {
  display: flex;
  align-items: center;
}

.ce-filters .Dropdown-root {
  margin-bottom: 10px !important;
  margin-left: 5vw;
  min-width: 20vw;
}

.cp-box {
  padding: 20px;
  box-shadow: rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
  border-radius: 20px;
  background-color: white;
  width: 95vw;
}

.clp-list {
  padding-bottom: 40px !important;
}

.cpb-box {
  display: flex;
  /* margin-bottom: 40px; */
  padding-bottom: 40px;
  justify-content: center;
}

.cp-box .player-item {
  width: 18vw !important;
}

.ch-links {
  display: flex;
  flex-wrap: wrap;
  width: 80vw;
}

.ch-cs .club-course {
  cursor: pointer;
}

.csp-courses .club-course {
  cursor: pointer;
  margin-bottom: 40px;
}

.ch-leagues .course-item {
  width: calc(100% - 40px) !important;
  margin: 0px;
}

.cl-box {
  padding: 20px;
}

.club-leagues .course-item {
  width: 40vw !important;
}

.cp-box .player-item {
  margin-right: 20px !important;
}

.csh-hole {
  display: flex;
  align-items: center;
}

.csh-hole p {
  width: 3.8vw;
  text-align: center;
  color: #232c2e;
  font-size: 12px;
  padding: 10px 0px;
  /* margin-bottom: 5px; */
  border-bottom: 1px solid #edefef;
  /* line-height: 20px; */
}

.club-stats .sdb-box {
  padding: 0px 0px 20px 20px;
}

.rdrDayToday .rdrDayNumber span:after {
  background-color: #1e7a69 !important;
}

.rdrDateDisplayItem {
  border-radius: 20px !important;
}

.rdrMonthAndYearWrapper button {
  background-color: #1e7a69 !important;
  border-radius: 50%;
}

.rdrNextButton i {
  border-color: transparent transparent transparent white !important;
}

.rdrPprevButton i {
  border-color: transparent white transparent transparent !important;
}

.cso-item {
  display: flex;
  align-items: center;
  /* justify-content: space-between; */
  padding: 0px 20px;
  margin-bottom: 10px;
}

.csh-header {
  border-bottom: 2px solid #232c2e;
}

.cso-item p {
  width: 20vw;
  color: #232c2e;
  font-size: 12px;
}

.cso-item h5 {
  color: #232c2e;
  text-align: center;
  font-size: 12px;
  width: 7.5vw;
}

.csv-sb {
  padding: 10px 20px !important;
  font-size: 14px !important;
  text-decoration: none;
}

.csh-header p {
  opacity: 0.5;
  text-transform: uppercase;
  font-size: 10px;
  font-family: "MS-SM";
  cursor: pointer;
}

.csh-active {
  font-weight: 600;
}

.csh-active {
  color: #1e7a69 !important;
}

.cpi-row {
  display: flex;
  width: max-content;
  align-items: center;
  padding-bottom: 5px;
}

.cpi-header {
  border-bottom: 2px solid #232c2e;
  margin-bottom: 10px;
  padding-bottom: 5px;
}

.cpid-dia .sub-header {
  justify-content: flex-start !important;
}

.pids-box-box {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 5vh;
  padding-top: 5vh;
}

.pids-box {
  overflow: scroll;
  box-shadow: rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
  background-color: white;
  /* box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px,
    rgba(14, 30, 37, 0.32) 0px 2px 16px 0px; */
  border-radius: 20px;
  width: max-content;
}

.cpid-dia .sub-header h5 {
  margin-right: 5vw !important;
}

.cpid-item {
  display: flex;
  align-items: center;
}

.cpid-dia {
  min-width: 60vw;
  min-height: 60vh;
}

.cpi-row p {
  width: 20vw;
  border-right: 1px solid #edefef;
  height: 100%;
  font-size: 13px;
  color: #232c2e;
}

.dib-dis {
  fill: grey !important;
}

.cpi-header p {
  font-weight: bold;
  font-size: 14px !important;
}

.cpi-row h5 {
  width: 10vw;
  font-size: 15px;
  font-weight: normal;
  text-align: center;
  border-right: 1px solid #edefef;
}

.cpi-header h5 {
  font-weight: 600;
  font-size: 15px !important;
}

.clp-header th div {
  display: flex;
  /* margin: auto; */
  align-items: center;
  /* vertical-align: middle; */
}

.clp-header th {
  cursor: pointer;
  padding: 2.5px 5px;
}

.clp-header p {
  font-size: 13px;
  color: #232c2e;
}

.cl-player td {
  font-size: 12px;
  color: #232c2e;
  padding: 2.5px 5px;
}

.hup-row {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
}

.ch-di-ctt {
  /* margin: 20px 20px 0px 0px; */
}

.cl-player {
  border-bottom: 1px solid #edefef;
}

.cl-player td {
  padding: 5px 0px;
}

.clp-list table {
  border-collapse: collapse;
  width: 100%;
}

.hh-name {
  width: 30vw;
}

.hh-hcp {
  width: 10vw;
  text-align: center;
}

.hup-row p {
  color: #232c2e;
  font-size: 14px;
}

.hup-head p {
  font-weight: 600;
}

.club-home .sub-header {
  justify-content: flex-start !important;
}

.club-editor .ig-header {
  margin-bottom: 10px;
}

.club-home .sub-header .dg-icon {
  margin-left: 20px;
}

.cdc-txt p {
  font-family: "MS-Med";
  color: #232c2e;
  font-size: 13px;
}

.cdc-txt {
  margin-bottom: 40px;
}

.club-editor .ig-header p {
  margin-bottom: 0px !important;
}

.clp-header div {
  display: flex;
  justify-content: center;
}

.cl-player td {
  /* font-size: 11px !important; */
  text-align: center;
}

.club-analytics {
  min-height: calc(100vh - 110px);
  background-color: #f5f5fac1;
  padding-top: 20px;
}

.clps-tb {
  background-color: white;
  padding: 10px;
}

.csh-box {
  padding: 20px;
  display: flex;
  justify-content: center;
}

.course-stats-holes {
  box-shadow: rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
  background-color: white;
  border-radius: 20px;
  /* width: 100%; */
  display: flex;
  flex-direction: column;
  align-items: center;
}

.colorPicker {
  appearance: none; /* Remove default styling */
  -webkit-appearance: none; /* Remove default styling for WebKit browsers */
  border: none;
  width: 40px; /* Width of the circle */
  height: 40px; /* Height of the circle */
  border-radius: 50%; /* Make it a circle */
  cursor: pointer;
  padding: 0;
}

/* Ensure the color picker shows the selected color */
.colorPicker::-webkit-color-swatch {
  border-radius: 50%; /* Keep the swatch a circle */
  border: none; /* Remove the default border */
}

.colorPicker::-moz-color-swatch {
  border-radius: 50%;
  border: none;
}
