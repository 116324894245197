.etm-ig {
  flex-direction: row;
}

.etm-ig .ig-header {
  margin-right: 5vw;
}

.evt-dnd {
  display: flex;
  /* align-items: center; */
  flex-wrap: wrap;
  padding: 20px;
}

.event-team-manager .sub-header {
  justify-content: flex-start !important;
}

.event-team-manager button {
  /* margin-left: 10vw; */
}

.event-team-manager .group-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.group-header .black-link {
  margin-right: 10px;
  font-size: 12px !important;
}

.group-header .red-link {
  margin-right: 10px;
  font-size: 12px !important;
}
.group-header .green-link {
  /* margin-right: 20px; */
  font-size: 12px !important;
}

.team-edit-dia .dz-sel {
  height: 10vw !important;
  width: 10vw !important;
}

.team-edit-dia .dz-sel img {
  height: 10vw !important;
  width: 10vw !important;
}
