.alert-tray {
  position: fixed;
  bottom: 0;
  right: 0;
  padding: 20px;
}

.alert-item {
  background-color: white;
  overflow: hidden;
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
    rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
  /* box-shadow: rgba(0, 0, 0, 0.3) 0px 1px 3px -1px; */
  border-radius: 20px;
  width: 35vw;
  margin-top: 20px;
}

.alert-item .ni-right {
  width: 100%;
}

.alert-item .ni-box {
  width: 100%;
}

.ai-close {
  display: flex;
  justify-content: flex-end;
  /* align-items: flex-end ; */
}

.alert-item .notification-item:hover {
  background-color: white !important;
}
