.event-creator {
  overflow: scroll;
  /* padding-bottom: 20px; */
  /* background-color: #FAFCFF; */
  min-height: calc(100vh - 80px);
}

.ec-box {
  padding: 0px 20px;
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 30px;
  justify-content: flex-start !important;
  /* align-items: flex-end; */
}

.ec-tabs .Mui-selected {
  background-color: transparent !important;
  color: #1e7a69 !important;
  box-shadow: rgb(149 157 165 / 70%) 0px 4px 6px;
  /* background-color: #edefef !important; */
  background-color: white !important;
  border-top-right-radius: 10px;
}

.event-creator .ec-box .input-group {
  width: 25vw;
}

.ec-tabs {
  box-shadow: 0px 1px 2px 0px rgb(0 0 0 / 28%) !important;
}

.sel-ad-ctt .player-item {
  width: 20vw !important;
}

.sh-ig-header {
  display: flex;
  align-items: center;
  /* justify-content: flex-start !important; */
}

.ec-box .input-group {
  margin-right: 5vw;
}

.ec-box .Dropdown-root {
  width: calc(20vw + 40px);
}

.event-creator input:not(.color-input) {
  -webkit-appearance: none !important;
  box-shadow: rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;

  /* box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
    rgba(60, 64, 67, 0.15) 0px 1px 3px 1px; */
  /* box-shadow: 0px 2px 2px 1px rgb(0 0 0 / 28%) !important; */
  /* -webkit-box-shadow: 0px 2px 2px 1px rgb(0 0 0 / 28%) !important; */
  padding: 12.5px 20px;
  width: 20vw;
  font-size: 12px;
  border-radius: 20px;
  border: none;
  /* border: 1px solid #8E9191; */
  /* background-color: #edefef; */
  color: #5c6363;
  border: 1px solid #8e91917e;

  min-height: 10px;
}

.event-creator h5 {
  font-size: 14px;
  color: #232c2e;
  font-weight: 600;
}

.image-buttons {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.image-buttons .pill-button {
  margin-right: 0px !important;
}

.dz-or {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 20px 0px !important;
}

.dz-sel {
  cursor: pointer;
  height: 18vw;
  width: 18vw;
  padding: 20px;
  border: 1px solid #8e91917e;

  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
    rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
  /* box-shadow: 0px 2px 2px 1px rgb(0 0 0 / 28%); */
  /* border: 1px solid #5C6363; */
  border-radius: 20px;
  background-color: white;
}

.dz-sel h5 {
  text-align: center;
  font-size: 12px;
  font-weight: 400;
  width: 75%;
  font-family: "MS-Reg";
  opacity: 0.5;
}

.dz-sel .filename {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.image-buttons button {
  cursor: pointer;
}

.image-buttons button:hover {
  text-decoration: underline;
}

.image-show-button {
  background-color: #21c17c;
  border: #21c17c;
  border-radius: 5px;
  color: white;
  font-weight: bold;
  text-transform: uppercase;
  font-size: 14px;
  padding: 5px 20px;
  box-shadow: 0px 2px 4px 1px rgba(0, 0, 0, 0.28);
}

.event-creator .MuiPaper-elevation {
  box-shadow: none !important;
  background-color: transparent;
}

.event-creator .MuiAccordionSummary-root {
  padding: 0px 20px 0px 0px !important;
}

.event-creator .MuiAccordionDetails-root {
  padding: 0px !important;
}

.event-creator .Mui-expanded {
  /* min-height: 20px !important; */
  margin: 0px !important;
}

/* .Dropdown-control {
    border: 1px solid #9c9c9c;
    border-radius: 8px;
}

.Dropdown-placeholder {
    color: #929292;
    font-weight: bold;
    font-size: 14px;
} */

.event-creator .ig-header p {
  /* width: 40vw; */
}

.mt-0 {
  margin-top: 0px !important;
}

.ec-box .msl {
  width: 85vw !important;
}

.ec-box .msl-options {
  /* max-height: 200px !important; */
}

.ec-header {
  display: flex;
  align-items: center;
}

.ec-header h1 {
  margin-right: 20px !important;
}

.ec-button-row {
  display: flex;
  justify-content: center;
  margin-top: 20px;
  margin-bottom: 20px;
}

.ec-button-row button {
  color: white;
  /* background-color: #21C17C; */
  /* border: 1px solid #21C17C; */
  background-image: linear-gradient(45deg, #1e7a69 0%, #21c17c 100%);
  border: none;
  text-transform: uppercase;
  font-size: 14px;
  width: 7;
  font-weight: bold;
  padding: 10px 0px;
  border-radius: 4px;
  transition: ease-in-out 100ms;
  width: 70vw;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.28);
}

.image-buttons p {
  margin-bottom: 0px !important;
}

.invite-button {
  background-color: #232c2e !important;
  border-color: #232c2e !important;
  /* background-image: none !important; */
  background-image: linear-gradient(45deg, #000 0%, #1e7a69 100%) !important;
}

.event-creator .MuiPaper-rounded {
  border-radius: 20px !important;
}

.ec-button-row button:active {
  transform: scale(1.1);
}

.part-players-box {
  padding: 20px 30px 0px 0px;
}

.members-box {
  padding-right: 30px;
}

.rci {
  opacity: 0.75;
  height: 20px !important;
  fill: #e24955 !important;
}

.event-creator .ts-header {
  background-color: transparent !important;
}
.league-edit .ts-header {
  background-color: transparent !important;
}

.ec-box > h3 {
  width: max-content;
  color: #232c2e;
  padding-bottom: 2.5px;
  font-weight: 600;
  border-bottom: 2px solid transparent;
  border-image: linear-gradient(0.25turn, #21c17c, #1e7a69);
  border-image-slice: 1;
}
.ec-box .ig-header > h3 {
  width: max-content;
  color: #232c2e;
  font-weight: 600;
  padding-bottom: 2.5px;
  border-bottom: 2px solid transparent;
  border-image: linear-gradient(0.25turn, #21c17c, #1e7a69);
  border-image-slice: 1;
}

.ec-pi {
  display: flex;
  align-items: center;
}

.elc {
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 0px 20px;
  justify-content: center;
}

/* .elc p {
    margin-top: 40px;
    font-size: 14px;
    opacity: 0.7;
} */

.event-creator .error-message-row {
  /* padding-left: 20px; */
  /* justify-content: center; */
  margin-top: 20px !important;
  display: flex;
  justify-content: center;
}

.ec-stats li {
  font-size: 13px;
}

.ec-box .ig-header .info-icon {
  height: 18px;
  width: 18px !important;
}

.ph-msg span {
  font-size: 13px;
}

.info-icon {
  /* opacity: 0.5; */
  /* fill: #21C17C; */
  /* linear- */
  /* background: linear-gradient(125deg, #21c17c 0%, #209e73 40%, #21c17c 80%); */

  /* margin-bottom: 10px; */
  margin-left: 20px;
}

.event-home > .ec-box .part-players-box {
  padding-right: 0px !important;
}

.info-message {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
}

.big-info-icon {
  /* opacity: 0.5; */
  height: 50px !important;
  width: 50px !important;
  margin-bottom: 20px;
  /* fill: #21C17C !important; */
}

.info-message p {
  font-family: "MS-Med";
  font-weight: 500;
  text-align: center;
  color: #232c2e;
  font-size: 14px;
}

.drop-icon {
  height: 20px !important;
  width: 20px !important;
}

.image-selector button {
  /* background-color: #21C17C; */
  background-image: linear-gradient(45deg, #1e7a69 0%, #21c17c 100%);
  border: none;
  color: white;
  text-transform: uppercase;
  font-weight: bold;
  border: 2px solid #21c17c;
  border-radius: 5px;
  padding: 5px 10px;
}

.ec-is button {
  background-color: transparent;
  border: none;
  font-size: 13px;
  color: #21c17c;
  font-weight: bold;
}

.event-img {
  margin-top: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.event-img img {
  width: 18vw;
  height: 18vw;
  /* width: fit-content; */
  /* height: fit-content; */
  object-fit: contain;
  border-radius: 5px;
}

.se-header {
  padding: 10px 20px;
}

.se-header h2 {
  font-size: 20px;
}

.ec-box .input-group {
  /* justify-content: flex-start; */
}

.tab-toggle {
  width: max-content;
}

.ld-ig {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.event-creator .ig-header p {
  margin-bottom: 0px;
}

.event-creator .ig-header {
  margin-bottom: 10px;
  /* align-items: flex-start !important; */
}

.tt-container {
  width: calc(20vw + 40px);
}

.tab-toggle .right-radius {
  border-left: none !important;
}

.tab-toggle .left-radius {
  border-right: none !important;
}

.tab-toggle button {
  /* border-radius: 10px !important; */
  /* border: 2px solid #1e7a69; */
  font-weight: 500 !important;
  text-transform: none;
}

.tab-toggle .MuiTabs-flexContainer {
  border: 2px solid #1e7a69;
  border-radius: 10px;
}

.tab-toggle button span {
  border-bottom: none !important;
}

.tab-toggle .MuiTabs-indicator {
  display: none;
}

.tab-toggle .Mui-selected {
  background-image: linear-gradient(45deg, #1e7a69 0%, #21c17c 100%);
  color: white !important;
  border-color: transparent !important;
}

.tt-border {
  border-right: 2px solid #1e7a69 !important;
  border-left: 2px solid #1e7a69 !important;
}

.ttb-sel {
  border-right: 2px solid #1e7a69 !important;
  border-left: 2px solid #1e7a69 !important;
}

.tab-toggle button {
  height: 36px !important;
  font-size: 13px !important;
  min-height: 36px !important;
}

.ec-box textarea {
  resize: none;
  width: 20vw;
  height: calc(18vw - 25px);
  padding: 12.5px 20px;
  font-size: 12px;
  color: #5c6363;
  border-radius: 20px;
  border: 1px solid #8e91917e;

  box-shadow: var(--bs1);
  /* border: 1px solid #8E9191; */
  /* box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
    rgba(60, 64, 67, 0.15) 0px 1px 3px 1px; */
  /* box-shadow: 0px 2px 2px 1px rgb(0 0 0 / 28%); */
  /* border: none !important; */
}

.ec-box .nassau-group {
  width: calc(15vw);
  align-items: center;
}

.ec-box .nassau-group .scoring-switch {
  transform: scale(0.8);
  margin-bottom: 10px;
}
