:root {
  /* Colours */
  --black: #0e0e0f;
  --green: #1e7a69;
  --dark-gre: #0f3d35;
  --red: #a9363f;
  --blue: #86a5d9;
  --grey: #8e9191;
  --lightgrey: #edefef;
  --lightlightgrey: #edefef7b;
  --gold: #d4af37;
  --bone: #ddd8c4;
  --silver: #bcb8b1;
  --white: #fffaff;
  --othergrey: #e2e8f0;
  --otherblack: #232838;

  /* Font Sizes */
  --smaller: 12px;
  --small: 14px;
  --medium: 18px;
  --medlarge: 20px;
  --large: 24px;
  --larger: 30px;
  --largest: 36px;

  /* Box Shadow */
  --bs1: rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
  --bs2: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -2px rgba(0, 0, 0, 0.1);

  /* Trassies */
  --ts1: 50ms ease-in-out;
}
