.pm-add {
  min-width: 75vw;
}

.prize-manager .scoring-switch {
  transform: scale(0.8);
  margin-bottom: 10px;
}

.prize-manager .nassau-group {
  align-items: center;
}

.prize-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 40vw;
  padding: 20px;
  margin-bottom: 20px;
  border-radius: 20px;
  box-shadow: rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
  background-color: white;

  /* box-shadow: rgb(149 157 165 / 20%) 0px 8px 24px; */
  margin-right: 5vw;
}

.pic-arrows {
  width: 10vw;
}

.pi-winner span {
  font-weight: 900;
  /* font-size: 18px; */
}

.pi-winner p {
  font-size: 16px;
}

.prize-item-container {
  display: flex;
  align-items: center;
}

.pic-arrows {
  display: flex;
  align-items: center;
}

.prize-item h2 {
  font-size: 16px;
  color: #232c2e;
}

.prize-item p {
  color: #232c2e;
  opacity: 0.5;
  text-transform: capitalize;
  font-size: 14px;
}

.prize-item h5 {
  color: #232c2e;
  opacity: 0.8;
}
