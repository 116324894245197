.ui-inputs {
  padding: 0px 20px;
  display: flex;
  flex-wrap: wrap;
  /* justify-content: space-between; */
}

.ui-inputs .input-group {
  margin-right: 5vw;
}

.player-names {
  display: flex;
  flex-wrap: wrap;
  padding: 20px;
}

.player-names p {
  width: 20vw;
  color: #232c2e;
}

.pn-header h1 {
  font-size: 28px;
  color: #232c2e;
}

.MuiAutocomplete-root input::placeholder {
  font-size: 12px !important;
  font-family: "Inter" !important;
  letter-spacing: normal !important;
  opacity: 0.8;
}

.select-group input {
  -webkit-appearance: none !important;
  box-shadow: rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
  border: 1px solid #8e91917e !important;

  /* box-shadow: 0px 2px 2px 1px rgb(0 0 0 / 28%) !important;
  -webkit-box-shadow: 0px 2px 2px 1px rgb(0 0 0 / 28%) !important; */
  padding: 12.5px 20px !important;
  width: 20vw !important;
  font-size: 12px;
  border-radius: 20px;
  border: none;
  /* border: 1px solid #8E9191; */
  /* background-color: #edefef; */
  background-color: white;
  color: #5c6363;
  min-height: 10px;
}

.select-group .MuiInput-root {
  border-bottom: none !important;
  padding-right: 0px !important;
}

.MuiAutocomplete-root {
  width: auto !important;
}

.select-group .MuiInput-root:after {
  border-bottom: none !important;
}

.MuiAutocomplete-popper {
  font-size: 12px !important;
}

.MuiAutocomplete-noOptions {
  font-size: 12px !important;
}

.MuiAutocomplete-listbox {
  font-size: 12px !important;
  color: #232c2e;
}

.MuiInput-root::before {
  border-bottom: none !important;
}

.di-error {
  border: 2px solid #f0a4aa;
}

.di-auto-error input {
  border: 2px solid #f0a4aa;
}

.ui-waiting {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 50vh;
}

.ui-waiting p {
  margin-top: 50px;
  color: #5c6363;
  letter-spacing: 1px;
}

.uiw-spinner {
  display: flex;
  justify-content: center;
  align-items: center;
}

.bi-box {
  display: flex;
  align-items: center;
}

.bi-info p {
  font-size: 14px;
  width: 50%;
  color: #232c2e;
}

.bi-info ul {
  margin-top: 20px;
  margin-bottom: 20px;
}

.bi-info li {
  color: #232c2e;
  font-size: 14px;
  font-weight: 900x;
  opacity: 0.8;
}

.bi-info {
  padding-left: 20px;
}

.bulk-import .dz-sel h5 {
  font-size: 14px !important;
}

.bi-box .green-link {
  text-decoration: none;
}

.bi-player {
  display: flex;
  align-items: center;
  /* justify-content: space-between; */
  border-bottom: 1px solid #edefef;
}

.bi-exists {
  background-color: #a9364062;
}

.bi-header {
  padding-bottom: 10px;
}

.bip-item {
  flex: calc(1 / 12);
  display: flex;
  justify-content: center;
  align-items: center;
}

.bi-header p {
  font-weight: 600;
}

.bis-player {
  display: flex;
  align-items: center;
}

.bips-item {
  flex: calc(1 / 3);
}

.bip-item p {
  /* overflow: scroll; */
  text-align: center;
}

.bi-players {
  padding: 20px;
}

.bip-item p {
  font-size: 12px;
}
.bips-item p {
  font-size: 12px;
}

.bip-mail {
  overflow: hidden;
  flex: calc(2.5 / 12) !important;
}

.bicc-item {
  display: flex;
  align-items: center;
}

.bicc-item p {
  color: #232c2e;
  font-size: 13px;
}

.bicc-ct {
  width: 30vw;
}

.bicc-head p {
  font-weight: 600;
  font-size: 14px !important;
}

.bicc-cd {
  display: flex;
  justify-content: center;
}

.bi-codes {
  min-height: 90vh;
}

.bis-player {
  padding: 0px 20px;
}
