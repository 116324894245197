.manual-player-add {
  min-width: 95vw;
  min-height: 95vh;
}

.mpa-players {
  display: flex;
  /* justify-content: space-between; */
  flex-wrap: wrap;
}

.mpa-players .player-item {
  width: 20vw;
  margin-right: 5vw;
}

.mpa-players .player-item .MuiAvatar-root {
  width: 50px !important;
  height: 50px !important;
}

.manual-player-add .loading {
  height: 50vh;
}

.mpa-fin {
  height: 50vh;
}

.ig-group {
  display: flex;
  align-items: flex-end;
}

.mpaf-slider {
  width: 20vw;
}

.MuiSlider-track {
  color: #1e7a69 !important;
}

.MuiSlider-thumb {
  color: #1e7a69 !important;
}

.MuiSlider-valueLabelOpen {
  background-color: #1e7a69 !important;
  border-radius: 50% !important;
}

.mpa-filters {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-evenly;
}

.MuiSlider-valueLabelLabel {
  color: white;
  font-size: 13px;
  font-weight: 600;
  /* font-family: "MS-SM"; */
}

.mpaf {
  display: flex;
  align-items: center;
  width: 24vw;
  padding: 20px;
  flex-direction: column;
  /* margin-right: 2.5vw; */
}

.mpaf-header {
  margin-bottom: 50px;
  display: flex;
  align-items: center;
}

.mpaf-header p {
  color: #232c2e;
  margin-right: 20px;
  font-size: 13px;
  font-weight: 600;
}

.manual-player-add .MuiPaper-root {
  margin: 0px 20px 20px 20px !important;
}

.manual-player-add .MuiPaper-root::before {
  height: 0px !important;
}

.manual-player-add .MuiCollapse-root {
  margin: 0px 20px !important;
}

.manual-player-add .scoring-switch {
  transform: scale(0.8);
}

.mpaf-pill {
  display: flex;
}

.mpafs {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: space-evenly;
}

.mpaf-drop .Dropdown-root {
  width: 25vw;
}

.mpaf-drop .mpaf-header {
  margin-bottom: 20px !important;
}

.mpaf-header .scoring-switch {
  transform: scale(0.7);
}

.manual-player-add .ig-group .radio-group {
  margin-top: 0px !important;
  padding-bottom: 0px !important;
}
