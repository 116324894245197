.apg-ctt .input-group span {
  opacity: 1 !important;
}

.apg-ctt .mpaf-cont {
  width: max-content !important;
}

.apg-ctt .ec-box {
  align-items: flex-start !important;
}

.pg-item {
  border-radius: 20px;
  box-shadow: rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;

  /* box-shadow: rgb(149 157 165 / 20%) 0px 8px 24px; */
  padding: 20px;
  width: 25vw;
  margin-right: 2.5vw;
  display: flex;
  justify-content: space-between;
  background-color: white;
  margin-bottom: 20px;
}

.pg-players {
  display: flex;
  flex-wrap: wrap;
  padding: 20px;
}

.pg-players .player-item {
  width: 20vw !important;
  margin-right: 20px;
}

.pg-groups {
  display: flex;
  flex-wrap: wrap;
}

.pg-item h3 {
  color: #232c2e;
  font-size: 15px;
  font-family: "MS-SM";
}

.pg-item p {
  color: #232c2e;
  font-size: 12px;
  text-transform: capitalize;
  opacity: 0.7;
}

.pg-dia-ctt {
  width: 90vw;
  padding: 20px;
}

.pdc-pls {
  padding: 20px 0px;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  flex-wrap: wrap;
}

.pdc-pls .player-item {
  width: 20vw !important;
}
