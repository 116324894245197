.leaderboard {
  /* width: 90%; */
  display: flex;
  /* align-items: center; */
  justify-content: center;
  /* border-top: 1px solid black;
    border-bottom: 1px solid black; */
  /* box-shadow: 0px 3px 15px rgba(0,0,0,0.2); */
  /* padding: 10px; */
  padding-top: 10px;
  height: 100%;
}

.ed-lb .Dropdown-control {
  /* width: 30vw !important; */
}

.csv-vals {
  display: flex;
  flex-wrap: wrap;
  width: 50vw;
}

.pdf-opts .player-item {
  width: 20vw !important;
  margin-right: 20px;
}

.lbp-buts {
  display: flex;
  justify-content: space-evenly;
  width: 100%;
  align-items: center;
}

.lbp-buts button {
  width: max-content !important;
  font-size: 14px !important;

  padding: 10px 20px !important;
}

.pdf-opts {
  display: flex;
  flex-wrap: wrap;

  width: calc(40vw + 120px);
}

.ed-lb .Dropdown-root {
  /* width: 30vw !important; */
}

.lb-export {
  width: 500px;
  height: 500px;
}

.lb-export-box {
  display: none;
}

.mp-slider-box {
  width: 100%;
}

.leaderboard table {
  width: 90vw;
}

.leaderboard td {
  text-align: center;
}

.leaderboard-rounds {
  /* width: 90vw; */
  width: 100%;
  margin-bottom: 20px;
}

.start-td {
  text-align: start !important;
}

.loading-div {
  margin-top: 20vh;
}

.leaderboard-players {
  display: flex;
  flex-direction: column;
  width: 95%;
  align-items: center;
}

.mp-container {
  width: 100%;
  display: flex;
  justify-content: center;
}

.leaderboard-headerr {
  display: flex;
  width: calc(100% - 20px);
  /* margin-left: 20px; */
  /* margin-right: 20px; */
  border-bottom: 1px solid #edefef;
  padding-bottom: 20px;
  margin-left: -20px;
}

.leaderboard-headerr h2 {
  font-size: 16px;
  color: #232c2e;
  font-weight: 600;
  font-size: 14px;
}

.leaderboard-header {
  margin-bottom: 20px;
  /* background-color: #232c23; */
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 0px 0px 10px 0px;
  border-radius: 10px;
  align-items: center;
}

.leaderboard-header h2 {
  /* color: white; */
  text-transform: uppercase;
  color: #232c2e;
  font-size: 18px;
  letter-spacing: 2.5px;
  padding: 0px 20px;
  border-left: 10px solid #21c17c;
  border-right: 10px solid #21c17c;
  border-radius: 5px;
}

.green-line {
  background-color: #21c17c;
  width: 20vw;
  height: 2px;
}

.team-leaderboard {
  width: 100%;
  height: 100%;
  overflow: scroll;
}

.team-board-box {
  padding: 0px 20px;
  width: 100%;
}

.leaderboard-players .tl-header {
  width: 100% !important;
}

.leaderboard .loading {
  margin-bottom: 84px;
}

.ed-lb {
  height: 100%;
}
