.nassau-score {
    width: 100%;
}

.nassau-score .score-details {
    width: 100% !important;
    margin-bottom: 10px;
    padding: 10px 0px;
    border-radius: 7.5px;
}

.ns-header {
    display: flex;
    /* justify-content: center; */
    margin-bottom: 5px;
    font-size: 12px;
}

.ns-header p {
    margin-left: 10px;
    font-weight: 600;
}

.nassau-score .ls-header {
    padding: 0px !important;
    /* margin-bottom: 0px !important; */

}

.nassau-score .ls-header h1 {
    margin: 0px !important;
}

.ns-header h3 {
    color: #232c2e;
    font-weight: 600;
}