.team-color {
  display: flex;
}

.team-color p {
  /* margin-right: 20px; */
  font-size: 13px;
  font-weight: 500;
  color: white;
  font-weight: bold;
}

.color-selector {
  height: 10vh;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 10vw;
  cursor: pointer;
  height: 10vw;
  margin-right: 20px;
  border: 1px solid #edefef;
  border-radius: 50%;
}

.color-selector-drawer {
  /* height: 50vh; */
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  /* background-color: tra; */
}

.csd-item {
  width: 33.3vw;
  box-sizing: border-box;
  /* flex: 0.33; */
  height: 15vh;
  border: 2px solid;
  /* height: 20vw;
    width: 20vw; */
}

.csdi-active {
  border: 2px solid #fff !important;
}
