.round-input .Dropdown-placeholder {
  text-transform: capitalize;
}

.round-input .Dropdown-option {
  text-transform: capitalize;
  font-size: 12px;
}

.input-group span {
  /* font-weight: normal; */
  opacity: 0.7;
}

.ig-edit {
  margin-top: 10px;
}

.round-input .input-group .svg-cont svg {
  margin-bottom: 10px;
}

.round-input .ig-header {
  margin-bottom: 0px !important;
}

.ig-edit button {
  background-color: #21c17c;
  border-radius: 5px;
  color: white;
  font-weight: bold;
  text-transform: uppercase;
  border: 1px solid #21c17c;
  padding: 5px 20px;
}

.input-header {
  flex-direction: row !important;
  align-items: center;
}

.input-header p {
  margin-right: 20px;
  margin-bottom: 0 !important;
}

.round-input {
  /* width: calc(100vw - 40px); */
  padding: 0px 20px;
}

.round-input .back-row {
  margin-left: 0px !important;
}

.round-switch {
  display: flex;
  width: 100%;
  /* padding-left: 20px; */
}

.round-switch .switch-row {
  box-shadow: none;
  padding-left: 0px !important;
}

.rs-box {
  display: flex;
  flex-wrap: wrap;
}

.rs-box .input-group {
  margin-right: 5vw;
  justify-content: flex-start !important;
  width: 25vw;
}

.ths {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  cursor: pointer;
}

.ths p {
  font-size: 12px;
  /* color: #1e7a69; */
}

.thsl p {
  width: auto !important;
}

.ig-header .ighr {
  /* margin-bottom: 10px; */
}

.ths p {
  margin-bottom: 0px !important;
  font-family: "MS-Bold";
}

.round-input .radio-group {
  padding: 0px !important;
}

.thsl {
  border-radius: 50%;
  /* padding: 2.5px 0px; */
  width: 2.5vw;
  height: 2.5vw;
  display: flex;
  justify-content: center;
  align-items: center;
}

.cr-star {
  height: 20px !important;
}
.thsr {
  /* border-radius: 0px 10px 10px 0px; */
  border-radius: 50%;
  /* padding: 2.5px 0px; */
  width: 2.5vw;
  height: 2.5vw;
  /* height: 3.5vw; */
  display: flex;
  cursor: pointer;
  align-items: center;
  justify-content: center;
}

.thsr p {
  width: auto !important;
}

.tshl-active {
  background-color: #1e7a69;
  filter: drop-shadow(1px 2px 2px #232c2eab);
  /* background-image: linear-gradient(45deg, #1e7a69 0%, #21c17c 100%); */
}

.tshl-active p {
  color: white !important;
}

.ighr {
  margin-left: 20px;
}

.round-input .scoring-switch {
  transform: scale(0.7) !important;
}

.round-switch h3 {
  font-weight: 500;
  font-size: 14px;
}

.round-input .ig-header p {
  margin-bottom: 10px;
}
