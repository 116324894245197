.cup-home {
  min-height: calc(100vh - 110px);
}

.cup-seed {
  min-height: calc(100vh - 110px);
}

.cup-seed-drop {
  padding: 0px 20px;
}

.cs-player {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.cs-player > p {
  margin-right: 20px;
  font-size: 14px;
}

.cs-up-icon {
  fill: #21c17c !important;
  border: 2px solid #21c17c;
  border-radius: 50%;
  margin-right: 10px;
  height: 20px !important;
  width: 20px !important;
}

.cs-down-icon {
  fill: #e24955 !important;
  border: 2px solid #e24955;
  border-radius: 50%;
  height: 20px !important;
  width: 20px !important;
}

.cs-down-icon:active {
  background-color: #e2495680;
}

.cs-up-icon:active {
  background-color: #21c17c80;
}

.cs-fill-icon {
  border: 2px solid white;
}

.cup-seed > h4 {
  font-size: 14px;
  margin-left: 20px;
  color: #232c2e;
  margin-bottom: 20px;
}

.cs-player .player-score {
  margin-bottom: 0px !important;
}

.cup-home .quick-links {
  /* width: 50vw; */
  flex-wrap: wrap;
}

.cup-home .quick-links .pill-button {
  margin-bottom: 10px;
}

.cm-match-box .dg-icon {
  padding: 5px;
  border-radius: 50%;
}

.cm-match-box .dg-icon:hover {
  background-color: #edefef;
}
