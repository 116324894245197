.credit-row {
  display: flex;
  align-items: center;
  padding: 5px 0px;
  border-bottom: 1px solid #edefef;
}

.cr-item p {
  font-size: 13px;
  font-family: "MS-Reg";
}

.cr-header {
  border-bottom: 2px solid #232c2e;
}

.cr-header p {
  font-size: 14px;
  font-family: "MS-SM" !important;
}

.cr-item {
  flex: 0.2;
}

.cri-add p {
  color: #1e7a69;
  font-family: "MS-SM";
}

.cri-subtract p {
  color: #a9363f;
  font-family: "MS-SM";
}
