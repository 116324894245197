.round-graphs {
  min-width: 60vw;
  background-color: white;
  padding: 20px;
}

.apexcharts-legend-text {
  color: #232c2e !important;
  font-family: "Poppins" !important;
}

.apexcharts-legend {
  margin-top: 20px !important;
}

.apexcharts-canvas text {
  font-family: "Poppins" !important;
}
