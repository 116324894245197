.evt-exports {
  width: 80vw;
}

.evt-exports .player-item {
  width: 15vw !important;
  margin-right: 20px;
}

.evt-exports .error-message-row {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}
