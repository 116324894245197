.playoff-holes {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  /* width: 55%; */
  padding: 20px;
}

.hole-selector {
  display: flex;
  flex-direction: column;
  /* align-items: center; */
}

.playoff-hole {
  flex: 0.33;
  display: flex;
  align-items: center;
  justify-content: center;
  /* margin-right: 10vw; */
  margin-bottom: 20px;
  cursor: pointer;
  transition: 200ms ease-in-out;
}

.playoff-hole:hover {
  opacity: 0.8;
}

.ph-inner {
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px solid #1e7a69;
  width: 10vw;
  height: 10vw;
  border-radius: 50%;
  background-color: #edefef;
  box-sizing: border-box;
}

.playoff-hole p {
  color: #1e7a69;
  font-weight: bold;
}

.ph-container {
  display: flex;
  justify-content: center;
}

.ph-active {
  background: #1e7a69;
  border: none;
  /* background-color: #21c17c !important; */
}

.ph-active p {
  color: white !important;
}
