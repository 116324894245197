.tlp-top {
  background-image: url("/src/assets/images/bgs/cellrtl.png");
  /* background-image: url("/src/assets/images/backgrounds/bg1.png"); */
  background-repeat: no-repeat;
  background-position: center;
  height: calc(100vh);
  background-size: cover;
  display: flex;
}

.tlp-left {
  flex: 0.5;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-left: 10vw;
}

.tlp-right {
  flex: 0.5;
}

.tlp-top .big-header {
  font-size: 72px;
  text-transform: uppercase;
  font-family: "TT-Bold";
}

.tlp-left .txt-lk {
  font-size: 18px;
  font-family: "TT-Med";
}

.tlp-left p {
  font-family: "TT-Reg";
  margin-top: 10px;
}

.tour-login {
  background-image: url("../../assets/images/bgs/mobbg2.png");
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(100vh);
}

.tl-box {
  box-shadow: var(--bs1);
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px 40px;
  border-radius: 20px;
}

.tl-logo img {
  width: 20vw;
}

.tl-box h5 {
  text-transform: uppercase;
  opacity: 0.5;
  letter-spacing: 1.5px;
  word-spacing: 3px;
  margin-bottom: 20px;
  font-weight: 400;
  font-family: "MS-SM";
}

.tl-more {
  font-family: "MS-SM";
}

.tl-more a {
  text-decoration: underline !important;
}

.tl-more a:hover {
  opacity: 0.7;
}
