.cc-profile {
  /* background-color: white; */
}

.cc-profile td {
  font-size: 12px !important;
  /* width: 4vw !important; */
}

.ccp-right {
  width: 70vw !important;
}

.ac-tf input::placeholder {
  font-size: 13px !important;
}

.cc-profile .round-item {
  border-bottom: 1px solid #edefef;
}

.ac-tf {
  /* border-bottom: 1px solid #ced4da !important; */
}

.ac-tf .MuiOutlinedInput-root {
  padding: 0px !important;
}
