.log-page {
}

.log-menu {
  height: 40px !important;
}

.log-container {
  /* height: calc(100vh - 200px); */
  overflow: hidden;
  width: 90%;
  /* box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px; */
  border-radius: 20px;
  background-color: white;
  box-shadow: rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
}

.lc-box {
  margin-top: 5vh;
  margin-bottom: 5vh;
  display: flex;
  /* width: 90vw; */
  justify-content: center;
}

.log-page .log-container {
  padding-top: 20px;
}

.log-page .Dropdown-control {
  width: 30vw;
}

.league-details .log-container {
  /* height: calc(100vh - 300px); */
}
.season-page .log-container {
  /* height: calc(100vh - 300px); */
}

.log-page .input-group {
  padding: 0px 20px;
}

.log-page .Dropdown-control {
  padding-top: 4.75px !important;
  padding-bottom: 4.75px !important;
}
.stats-selector-row .Dropdown-control {
  padding-top: 4.75px !important;
  padding-bottom: 4.75px !important;
}

.lss-drop .Dropdown-control {
  padding-top: 4.75px !important;
  padding-bottom: 4.75px !important;
}

.leaderboard .Dropdown-control {
  padding-top: 4.75px !important;
  padding-bottom: 4.75px !important;
}

.log-page .input-group .ne-header {
  margin-bottom: 10px;
  font-size: 14px;
  color: #232c2e;
  font-weight: 600;
}

.records-box {
  display: flex;
  flex-direction: column;
}
