.player-round {
  display: flex;
  flex-direction: column;
  width: 100%;
  /* overflow: hidden; */
}

.load-player-round {
  height: calc(100vh - 110px);
  overflow: scroll;
}

.player-round-header {
  display: flex;
  /* align-items: center; */
  flex-direction: column;
  padding-left: 10px;
  /* padding-top: 20px; */
  /* padding-top: 10px; */
  /* border-top: 1px solid #edefef; */
  /* border-bottom: 1px solid #edefef; */
}

.player-round > .player-round-header {
  border-top: 1px solid #edefef;
  padding-top: 10px;
}

.prh-left {
  display: flex;
  align-items: center;
  /* flex: 0.2; */
}

.prh-right {
  /* flex: 0.8; */
}
.prh-icon {
  /* height: 40px; */
  transform: scale(1.5);
  fill: #5c6363;
}

.player-round-header h2 {
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  color: #232c2e;
  margin-bottom: 2.5px;
}

.player-round-header p {
  font-weight: normal;
  font-size: 12px;
  color: #5c6363;
  margin-top: 0px !important;
  padding: 0px !important;
}

.player-round-header h3 {
  font-size: 13px;
  opacity: 0.9;
  font-weight: 600;
  color: #232c23;
}

.back-arrow-row {
  display: flex;
  align-items: center;
  padding: 10px;
}

.back-arrow {
  margin-right: 10px;
  transition: 100ms ease-in-out;
  color: #5c6363;
}

.back-arrow:active {
  opacity: 0.5;
}

.p-r-course {
  display: flex;
  align-items: center;
  padding-left: 20px;
  margin-bottom: 20px;
  /* margin-top: 20px; */
}

.p-r-course h2 {
  color: #232c23;
  padding-right: 20px;
  /* font-size: 20px; */
}

.p-r-course .css-1wlk0hk-MuiAvatar-root {
  height: 50px;
  width: 50px;
}

.prtc-header th {
  font-weight: 400 !important;
  font-size: 12px;
  text-align: center;
  color: #5c6363;
}

.under-par {
  color: #1e7a69 !important;
}

.under-par span {
  color: #1e7a69 !important;
}
.over-par {
  color: #e24955 !important;
}

.double-over-par span {
  border: 2px solid #e24955;
  border-radius: 50%;
  padding: 5px;
}

.double-under-par span {
  border: 2px solid #1e7a69;
  border-radius: 50%;
  padding: 5px;
}
.over-par span {
  color: #e24955 !important;
}

.bold-td {
  font-weight: 600;
}

.player-round .score-table-container {
  margin-top: 10px;
}

.prtc {
  /* margin-top: 10px; */
}

.normal-td {
  color: #5c6363;
  font-size: 12px;
}

.score-td {
  font-weight: 600;
  color: #232c23;
}

.hit {
  fill: #1e7a69 !important;
}

.missed {
  fill: #e24955 !important;
}

.pr-menu {
  display: flex;
  align-items: center;
  margin-top: 10px;
  border-top: 1px solid #edefef;
}

.prm-item {
  /* flex: 0.5; */
  display: flex;
  align-items: center;
  justify-content: center;
  color: #5c6363;
  font-weight: bold;
  font-size: 14px;
  padding: 10px 10px;
  border-bottom: 2px solid transparent;
}

.prm-active {
  color: #21c17c !important;
  border-bottom: 2px solid #21c17c;
}

.round-select-circles {
  display: flex;
  align-items: center;
  padding-left: 20px;
}

.rsc {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  border: 2px solid #1e7a69;
  border-radius: 50%;
  margin-top: 10px;
  margin-right: 10px;
  margin-bottom: 20px;
  cursor: pointer;
}

.rsc p {
  font-weight: bold;
  color: #1e7a69;
}

.rsc-active {
  background-color: #1e7a69;
}

.rsc-active p {
  color: white;
}

.mp-round .ts-header {
  padding-top: 0px !important;
}
