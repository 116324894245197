.tcs {
  height: calc(100vh - 110px);
  overflow: scroll;
  padding: 0px 0px;
  background-color: #fafcff;
}
.tcs-full {
  height: calc(100vh) !important;
  overflow: scroll;
  /* padding: 0px 0px 20px 0px; */
}

.tcs h2 {
  font-size: 14px;
}

.tcs-content {
  padding: 20px;
}

.tcs p {
  font-size: 12px;
  margin-bottom: 5px;
}

.privacy-policy {
  /* height: calc(100vh - 150px); */
  overflow: scroll;
}

.tc-down {
  position: fixed;
  bottom: 20px;
  right: 20px;
  color: #21c17c !important;
  transform: scale(1.5);
  /* fill: #21c17c !important; */
}

.tc-down path {
  -webkit-appearance: none !important;
  box-shadow: 0px 2px 2px 1px rgb(0 0 0 / 28%) !important;
  -webkit-box-shadow: 0px 2px 2px 1px rgb(0 0 0 / 28%) !important;
}
