.ri-ip {
  border: 1px solid #ced4da;
  text-align: center;
  width: 3.5vw;
  border-radius: 5px;
}

.fir-ico-td {
  cursor: pointer;
  border-radius: 50%;
  padding: 5px;
  height: 2vw;
  width: 2vw;
}

.rs-ip-box {
  width: 100%;
  /* padding-left: 20px; */
}

.fir-ico-td:not(.fit-sel):hover {
  background-color: whitesmoke;
}

.sri-ta {
  width: 100%;
}

.sri-ta .input-group {
  width: 75%;
}

.sri-ta textarea {
  resize: none;
  width: 100%;
}

.cp-loader {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 40px;
}

.fit-box {
  display: flex;
  align-items: center;
  justify-content: center;
  /* text-align: center; */
}

.sel-fir-ico {
  stroke: whitesmoke !important;
  fill: whitesmoke !important;
}

.fit-sel {
  background-color: #1e7a69;
}

.ri-sc {
  width: max-content;
}

.ri-sc-box {
  border-radius: 20px;
  box-shadow: var(--bs1);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-color: white;
  padding: 20px;
}

.rsb-box {
  display: flex;
  justify-content: center;
  padding-bottom: 40px;
}

.ri-sc thead {
  position: sticky;
  background-color: white;
  top: 70px;
}

.rdi-cc-res .course-item {
  width: 20vw !important;
}

.rdi-cc-res .course-item .ci-right h1 {
  max-width: 15vw !important;
}

.rdi-cc-res {
  justify-content: flex-start !important;
}

.es-box {
  width: 55vw;
  justify-content: space-between;
  margin: 20px 0px;
}

.ri-sc td,
.ri-sc th {
  width: 4.5vw !important;
}

.ri-submitted {
  background-color: white;
  margin-left: 20px;
  padding: 20px;
  width: 70vw;
}

.ri-submitted p {
  font-family: "MS-Reg";
  color: #232c2e;
  font-size: 13px;
}
