.chat-drop {
  transition: 300ms cubic-bezier(0.66, 0.31, 0.83, 0.67);
  z-index: 999;
  /* position: absolute; */
  background-color: white;
  /* margin-bottom: 100vh !important; */
  overflow: scroll;
  display: flex;
  flex-direction: column;
  width: 100%;
  background-image: linear-gradient(225deg, #edefef8e 0%, #fff 50%);
  height: calc(100vh - 84px);

  border-radius: 20px 0px 0px 20px;
}

.nc-dialog .player-item .MuiAvatar-root {
  width: 50px !important;
  height: 50px !important;
}

.chat-pills {
  padding-left: 20px;
  display: flex;
  padding-bottom: 20px;
  /* margin-bottom: 20px; */
  border-bottom: 2px solid #edefef;
}

.chat-drop .chat-page {
  height: 100% !important;
}

.chat-container {
  height: 100%;
  overflow: scroll;
}

.chat-drop .np-header {
  /* border-bottom: 1px solid #edefef; */
}

.no-messages {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.no-messages p {
  text-align: center;
  font-size: 13px;
  /* width: 75%; */
  font-weight: 600;
  color: #232c2e;
}

.new-chat-message {
  display: flex;
  justify-content: center;
  padding: 20px 20px;
}

.new-chat-message p {
  font-weight: 600;
  text-align: center;
  font-size: 14px;
}

.nc-players .player-item {
  transition: 200ms ease-in-out;
}

.nc-players {
  padding: 0px 40px 20px 40px;
  max-height: 60vh;
  overflow: scroll;
}

.nc-players .player-item:hover {
  cursor: pointer;
  background-color: #edefef !important;
}

.chat-drawer .MuiPaper-root {
  margin-top: 80px;
  width: 30vw;
  left: auto !important;
  border-radius: 20px;
}

.chat-drawer .MuiBackdrop-root {
  top: 80px;
  background-color: transparent !important;
}

.chat-drawer {
  top: 80px !important;
}

.np-header {
  display: flex;
  align-items: center;
  padding: 20px;
  font-family: "MS-SM";
}

.np-header h3 {
  font-size: 16px;
}

.np-header svg {
  margin-left: 10px;
  cursor: pointer;
}
