.cp-head h2 {
    font-size: 15px;
    color: #232C2E;
    margin-bottom: 10px;
}

.cp-head {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 20px 20px;
}

.cp-head p {
    font-size: 13px;
    color: #5C6363;
}

.right-arrow {
    position: absolute;
    right: 2.5vw;
    top: 10vh;
    z-index: 10;
}

.left-arrow {
position: absolute;
left: 2.5vw;
top: 10vh;
transform: rotate(180) !important;

}

.left-arrow svg {
    height: 5vh !important;
width: 5vh !important;
cursor: pointer;


fill: #edefef !important;
}

.right-arrow svg {
    transition: 200ms ease-in-out;
    height: 5vh !important;
    width: 5vh !important;
    cursor: pointer;
    
    /* box-shadow: rgba(0, 0, 0, 0.2) 0px 12px 28px 0px, rgba(0, 0, 0, 0.1) 0px 2px 4px 0px, rgba(255, 255, 255, 0.05) 0px 0px 0px 1px inset; */
    fill: #edefef !important;
    
}

.right-arrow svg path {
    box-shadow: rgba(0, 0, 0, 0.17) 0px -23px 25px 0px inset, rgba(0, 0, 0, 0.15) 0px -36px 30px 0px inset, rgba(0, 0, 0, 0.1) 0px -79px 40px 0px inset, rgba(0, 0, 0, 0.06) 0px 2px 1px, rgba(0, 0, 0, 0.09) 0px 4px 2px, rgba(0, 0, 0, 0.09) 0px 8px 4px, rgba(0, 0, 0, 0.09) 0px 16px 8px, rgba(0, 0, 0, 0.09) 0px 32px 16px;
    /* box-shadow: rgba(0, 0, 0, 0.2) 0px 12px 28px 0px, rgba(0, 0, 0, 0.1) 0px 2px 4px 0px, rgba(255, 255, 255, 0.05) 0px 0px 0px 1px inset; */
}

.right-arrow svg:active {
    opacity: 0.7;
}

.right-arrow svg:hover {
    transform: scale(1.1);
}

.cp-head-arr {
    display: flex;
}

.cp-info-text p {
    font-size: 12px;
}

.cp-info-text {
    padding-left: 20px;
    margin-bottom: 20px;
}

.cp-info-text .info-pee {
    font-size: 10px;
    margin-top: 10px;
}

.cp-head .info-pee {
    font-size: 10px;
    margin-top: 10px;
}

.cp-head-arr h2 {
    margin-left: 20px;
}

.cp-icon {
    fill: #21c17c !important;
}

.cp-location {
    margin-top: 10px;
    display: flex;
    align-items: center;
    /* justify-content: space-between; */
    padding: 0px 20px;
}

.cp-location h5 {
    font-size: 13px !important;
    color: #232C2E;
    margin-right: 20px;
}

.cp-location svg {
    cursor: pointer;
}

.cpl-left {
    display: flex;
    align-items: center;
}

.cp-search {
    display: flex;
    align-items: center;
    padding: 0px 20px;
    margin-bottom: 20px;
}

.cp-search input {
    border: none !important;
    /* font-size: 14px !important;   */
    /* width: 100% !important; */
    /* padding: 5px !important; */
}

.cps-svg {
    width: 20px !important;
    height: 20px !important;
    margin-right: 10px;
}

.cp-hr {
    border-bottom: 1px solid #edefef;
}

.nc-head {
    display: flex;
    justify-content: space-between;
    padding-right:  20px;
}

.nc-head h2 {
    font-size: 14px;
    color: #232C2E;
    font-weight: 600;
    height: max-content;
}

.cpl {
    justify-content: center;
    /* height: 100%; */
    display: flex;
    flex-direction: column;
}

.cpl p {
    margin-top: 40px;
    font-size: 14px;
    opacity: 0.5;
    /* color: #21c17c; */
    font-weight: 500;

}

.cp-search {

}

.nearby-courses {
    margin-top: 20px;
    padding-left: 20px;
    padding-bottom: 20px;
}

.cs-container {
    position: relative;
}



.course-slider {
    display: flex;  
    align-items: center;
    justify-content: space-between;
    overflow-x: scroll;
    /* position: relative; */
    width: 100%;
    scroll-behavior: smooth;
    white-space: nowrap;
    margin-top: 20px;
    /* padding: 0px 20px; */
}

.square-square {
    display: flex;
    margin-top: 20px;
    flex-wrap: wrap;
    overflow-y: scroll;
}

.square-square .course-square {
    margin-bottom: 20px;
    min-width: 39vw !important;
}

.course-picker {
    height: 100%;
}

.course-picker {
    padding-bottom: 20px;
}