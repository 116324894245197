.RadioButton .btn {
  width: 10vw;
  float: left;
  margin: 0;
  box-sizing: border-box;
  position: relative;
  font-size: 12px;
  min-height: 30px !important;
  line-height: 30px;
  border-radius: 5px;
  cursor: pointer;
  text-align: center;
  text-transform: uppercase;
  /* border: none; */
  /* overflow: hidden; */
}

.RadioButton .btn:not(.btn-active):hover {
  background-color: #edefef;
}

.RadioButton-two .btn:not(.btn-active):hover {
  background-color: #edefef;
}

.RadioButton .btn:not(.btn-active) {
  background-color: white;
}

.RadioButton-two .btn:not(.btn-active) {
  background-color: white;
}

.RadioButton {
  /* border: 2px solid #1e7a69; */
  border-radius: 20px;
  overflow: hidden;
}
.RadioButton-two .btn {
  width: 10vw;
  float: left;
  margin: 0;
  box-sizing: border-box;
  position: relative;
  font-size: 12px;
  min-height: 30px !important;
  line-height: 30px;
  border-radius: 5px;
  cursor: pointer;
  /* z-index: 1; */
  text-align: center;
  /* border: none; */
  /* overflow: hidden; */
}

.RadioButton-two {
  /* border: 2px solid #1e7a69; */
  border-radius: 20px;
  overflow: hidden;
}

.radio-group {
  display: flex;
  padding-left: 20px;
  padding-bottom: 20px;
  margin-top: 10px;
  height: max-content;
}

.btn-default {
  /* border: 2px solid #1e7a69; */
  color: #1e7a69;
  /* font-weight: 500; */
  font-family: "MS-Bold";
}

.btn-group > .btn:first-child {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.btn-group > .btn:nth-child(2) {
  border-radius: 0;
}

.btn-group > .btn:last-child {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.btn-active {
  background-color: #1e7a69;
  color: white;
}

.btn-group {
  clear: both;
  box-shadow: rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;

  /* box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px; */
}

.RadioButtoon .btn:nth-child(3)::before {
  background-image: linear-gradient(1deg, #1e7a69, #ffffff20 99%);

  content: "";
  height: 100%;
  width: 100%;
  /* background-image: linear-gradient(45deg, #1e7a69 0%, #21c17c 100%); */
  box-shadow: 0 6px 6px -2px rgb(50 50 93 / 25%),
    0 3px 5px -3px rgb(0 0 0 / 30%);
  background-color: #1e7a69;
  position: absolute;
  top: 0;
  /* border-radius: 20px; */
  transition: 0.3s all ease;
  z-index: -1;
  left: 0px;
}

.RadioButtoon-two .btn:nth-child(2)::before {
  background-image: linear-gradient(1deg, #1e7a69, #ffffff20 99%);

  content: "";
  height: 100%;
  width: 100%;
  /* background-image: linear-gradient(45deg, #1e7a69 0%, #21c17c 100%); */
  /* border-radius: 20px; */
  background-color: #1e7a69;
  position: absolute;
  top: 0;
  transition: 0.3s all ease;
  z-index: -1;
  left: 0px;
}

.btn-active:first-child ~ .btn::before {
  transform: translateX(calc(-4px - 200%));
}
.RadioButton .btn-active:nth-child(2) ~ .btn::before {
  transform: translateX(calc(-2px - 100%));
}
.RadioButton-two .btn-active:nth-child(1) ~ .btn::before {
  transform: translateX(calc(-2px - 100%));
}

.RadioButton .btn:nth-child(2) {
  /* border-left: none !important; */
  /* border-right: none !important; */
}

.chat-settings .btn-active {
  z-index: 2;
}

.chat-settings .RadioButton-two .btn:nth-child(1)::before {
  z-index: 1;
}

.chat-settings .btn-active:first-child ~ .btn::before {
  z-index: 1;
}

.switch-row .btn-group {
  margin-left: 10px;
}

.RadioButton {
  background-color: white;
}
