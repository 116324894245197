.new-query-modal {
padding: 20px;
min-height: 40vh;
}

.new-query-modal .loading {
    height: 30vh;
}

.new-query-modal textarea {
    margin-top: 20px;
}