.pdc-feats {
  padding: 20px;
}

.pdc-feat {
  margin-bottom: 20px;
}

.pdc-feat h3 {
  font-family: "MS-SM";
  font-size: 16px;
}

.pdc-feat p {
  font-size: 14px;
  font-family: "MS-Reg";
  width: 70vw;
}
