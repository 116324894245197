.simple-player {
    display: flex;
    align-items: center;
    /* padding-left: 20px; */
    padding: 10px 0px 10px 20px;
    border-bottom: 1px solid #edefef;
    cursor: pointer;
}

.simple-left {
    display: flex;
    align-items: center;
    justify-content: center;
}

.simple-left img {
    width: 48px !important;
    height: 48px !important;
}

.simple-right {
    display: flex;
    flex-direction: column;    
    padding-left: 10px;
}

.simple-right h2 {
    color: #232C2E;
    font-weight: 600;
    font-size: 13px;
}

.simple-right p {
    color: #5C6363;
    font-size: 12px;

}