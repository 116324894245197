.arrears-bar {
  top: 80px;
  background-color: #a9363f;
  position: fixed;
  width: 100vw;
  z-index: 100;
}

.ab-txt {
  padding: 5px 0px;
  display: flex;
  justify-content: center;
}

.arrears-bar a {
  color: white;
  text-decoration: underline !important;
}

.arrears-bar p {
  font-size: 13px;
  text-align: center;
  color: white;
  font-family: "MS-SM";
}
