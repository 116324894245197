.bpc-row {
  display: flex;
  align-items: center;
  border-bottom: 1px solid #8e91917e;
  border-left: 1px solid #8e91917e;
}

.bpc-row input {
  width: 100% !important;
}

.bpc-item {
  flex: calc(1 / 6);
  border-right: 1px solid #8e91917e;
}

.bpc-stdl {
  min-height: 80vh;
}

.bpc-players {
  display: flex;
  align-items: center;
  justify-content: center;
}

.bpc-header {
  border-top: 1px solid #8e91917e;
  border-bottom: 2px solid #232c2e;
  /* border-radius: 20px 20px 0px 0px; */
}

.bpc-box {
  /* border-radius: 20px; */
  /* overflow: hidden; */
}

.bpcb-box {
  width: 90vw;
}

.bpc-header h3 {
  font-size: 12px;
  color: #232c2e;
  font-family: "MS-SM";
}

.bpc-item input {
  border: none;
  font-size: 12px;
  background-color: transparent;
}

.bpc-item {
  padding: 5px;
}

.bpc-err {
  /* background-color: #edefef; */
}

.bpci-err {
  background-color: #a9364070;
}
