.ehel-players {
  display: flex;
  flex-wrap: wrap;
}

.ehel-players .player-item {
  width: 22.5vw;
  margin-right: 2.5vw;
}

.ehel {
  width: 90vw !important;
}

.ehel .player-item .default-input {
  width: 2.5vw !important;
}

.ig-row {
  display: flex;
  align-items: center;
}

.ig-row .default-button {
  padding: 10px 20px;
  font-size: 16px !important;
  margin-left: 2.5vw;
}

.event-handicap-edit .scoring-switch {
  margin-bottom: 10px;
  margin-left: 20px;
  transform: scale(0.8);
}

.event-handicap-edit .nassau-group {
  align-items: flex-start;
}

.ig-col span {
  font-size: 12px;
}
