.mh-header {
  display: flex;
  align-items: center;
  width: 100%;
  margin-top: 20px;
}

.mh-header h4 {
  color: #232c2e;
  font-size: 14px;
  text-align: center;
}

.mh-left {
  flex: 0.45;
  display: flex;
  justify-content: center;
  align-items: center;
}

.mhrc h4 {
  font-size: 12px !important;
}

.mh-mid {
  flex: 0.1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.mh-right {
  flex: 0.45;
  display: flex;
  justify-content: center;
  align-items: center;
}

.matchplay-holes {
  width: 100%;
}

.mh-vs h4 {
  opacity: 0.7;
  font-weight: 500;
  font-size: 12px;
  width: 75%;
}

.mhr-active .mhrc {
  background-color: #21c17c;
  padding: 5px 0px;
  border-radius: 20px;
  /* width: 90%; */
  /* flex: 0.4; */
}

.mhr-active h4 {
  color: white !important;
}

.mhrc {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50%;
}

.mh-holes {
  margin-bottom: 20px;
}
