.su-opt {
  width: 30vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  /* padding: 40px; */
  height: 50vh;
  border-radius: 40px;
  /* cursor: pointer; */
  margin-bottom: 5vh;
  background-image: linear-gradient(160deg, #fff 0%, #edefef 100%);
  transition: 200ms ease-in-out;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px,
    rgba(0, 0, 0, 0.3) 0px 30px 60px -30px,
    rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;
}

.su-opt:hover {
  /* transform: scale(1.05); */
}

.su-opt:active {
  /* transform: scale(0.95); */
}

.suo-active {
  background-image: none !important;
  background-color: #e9f8f2;

  /* transform: none !important; */
}

.su-options {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  padding: 20px 0px;
}

.suo-left h3 {
  text-transform: uppercase;
  /* font-weight: 300; */
  letter-spacing: 1.5px;
  /* opacity: 0.7; */
  font-size: 24px;
  text-align: center;
  width: 20vw;
  color: #10603d;
  /* filter: none; */
  font-weight: 900;
  filter: drop-shadow(1px 2px 2px #1e7a69c9);
  margin-bottom: 5px;
}

.su-confirm-row {
  display: flex;
  justify-content: center;
  margin-bottom: 5vh;
}

.su-confirm-row button {
  cursor: pointer;
  font-family: "MS-Bold";
  text-transform: uppercase;
  font-weight: 900;
  background-color: #1e7a69;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px,
    rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;

  /* background-image: linear-gradient(45deg, #1e7a69 0%, #21c17c 100%); */
  /* border: 2px solid #232c2e; */
  border: none;
  font-size: 30px;
  color: white;
  border-radius: 40px;
  width: 40vw;
  padding: 20px 0px;
  transition: 200ms ease-in-out;
}

.su-confirm-row button:hover {
  transform: scale(1.1);
}

.su-confirm-row span {
  /* color: #fff; */
}

.suo-left p {
  font-size: 13px;
  opacity: 0.5;
  text-align: center;

  color: #10603d;
}

.suo-left h5 {
  text-transform: uppercase;
  opacity: 0.5;
  letter-spacing: 1px;
  margin-top: 10px;
  text-align: center;

  width: 20vw;
}

.suo-left {
  padding: 0px 20px;
}

.suo-right {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.suo-right h3 {
  font-size: 30px;
  text-transform: uppercase;
  color: #1e7a69;
}

.suo-right p {
  font-size: 14px;
  opacity: 0.5;
}

.btn-56,
.btn-56 *,
.btn-56 :after,
.btn-56 :before,
.btn-56:after,
.btn-56:before {
  border: 0 solid;
  box-sizing: border-box;
}
.btn-56 {
  -webkit-tap-highlight-color: transparent;
  -webkit-appearance: button;
  background-color: #000;
  background-image: none;
  color: #fff;
  cursor: pointer;
  font-size: 100%;
  line-height: 1.5;
  margin: 0;
  -webkit-mask-image: -webkit-radial-gradient(#000, #fff);
  padding: 0;
}
.btn-56:disabled {
  cursor: default;
}
.btn-56:-moz-focusring {
  outline: auto;
}
.btn-56 svg {
  display: block;
  vertical-align: middle;
}
.btn-56 [hidden] {
  display: none;
}
.btn-56 {
  border: 1px solid;
  border-radius: 999px;
  box-sizing: border-box;
  display: block;
  font-weight: 900;
  -webkit-mask-image: none;
  overflow: hidden;
  padding: 1.2rem 3rem;
  position: relative;
  text-transform: uppercase;
}
.btn-56:active {
  -webkit-animation: pulse 1s;
  animation: pulse 1s;
  box-shadow: 0 0 0 2em transparent;
}
@-webkit-keyframes pulse {
  0% {
    box-shadow: 0 0 0 0 #21c17c;
  }
}
@keyframes pulse {
  0% {
    box-shadow: 0 0 0 0 #1e7a69;
  }
}

.payfast-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 10vh 0px;
}

.payfast-form input[type="submit"] {
  /* background-color: #21c17c !important; */
  background-color: #1e7a69;
  /* box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px,
    rgba(0, 0, 0, 0.3) 0px 3px 7px -3px; */
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px,
    rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;
  transition: 200ms ease-in-out;
  border: none;
  color: white;
  text-transform: uppercase;
  cursor: pointer;
  font-size: 20px;
  padding: 10px 80px;
  border-radius: 40px;
  font-family: "MS-Bold";
  font-weight: 600;
  letter-spacing: 1px;
}
.payfast-form input[type="submit"]:active {
  transform: scale(0.95);
}

.payfast-info {
  background-image: url("../../assets/images/nextbg.png");

  /* background-image: linear-gradient(45deg, #1e7a69 0%, #21c17c 100%); */
  height: 40vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  border-radius: 0px 0px 40px 40px;
  margin-bottom: 5vh;
}

.payfast-info h1 {
  color: white;
  opacity: 0.95;
  text-transform: uppercase;
  font-size: 60px;
  letter-spacing: 2px;
  filter: drop-shadow(2px 4px 6px #232c2e);
  font-family: "MS-Bold";
}

.payfast-info p {
  color: white;
  opacity: 0.8;
  filter: drop-shadow(2px 4px 6px #232c2e);
  text-transform: uppercase;
  letter-spacing: 1px;
  margin-top: 20px;
  font-family: "MS-Reg";
}

.su-free {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.su-free input {
  font-size: 18px;
  padding: 20px;
  font-family: "MS-Bold";
  margin-bottom: 40px;
  border-radius: 40px;
  width: 40vw;
}

.pi-back {
  display: flex;
  width: 100%;
}

.pi-top {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.pi-buttons {
  margin: 20px 0px;
  display: flex;
  justify-content: space-around;
  width: 100%;
}
