.ps-player {
    border-top: 1px solid #edefef;
    border-bottom: 1px solid #edefef;
    /* padding-bottom: 20px; */
    height: 100%;
}

.psp-h {
    padding-top: 20px;
    padding-left: 20px;
    margin-bottom: 10px;
}

.psp-h h2 {
    font-size: 14px;
    color: #232c2e;
    font-weight: 600;
}

.rp-mid p {
    font-size: 12px;
    /* margin-top: 2.5px; */
    opacity: 0.7;
}

.ps-rp {
    justify-content: flex-start !important;
}

.player-setup {
    width: 50vw;
}

.player-setup .page-header {
    display: flex;
    justify-content: space-between;
}

.player-setup .default-button-row {
    display: flex;
    justify-content: center;
}

.ps-rp .rp-mid {
    /* flex: 0.8 !important; */
    display: flex;
    align-items: center;
}

.tee-item {
    display: flex;
    padding: 10px 20px;
    margin: 10px 0px;
}

.ti-left {
    flex: 0.1;
    /* display: flex; */
    /* align-items: center;  */
    /* justify-content: center; */
}

.ti-mid {
    flex: 0.8;
}

.ti-mid h2 {
    text-transform: capitalize;
    font-size: 14px;
    color: #232c2e;
}

.ti-mid p {
    font-size: 12px;
line-height: 17px;
/* identical to box height */


/* Black/Black 40 */

color: #5C6363;
}

.ti-right {
    display: flex;
    align-items: center;
    justify-content: center;
}

.til-circle {
    height: 12px;
    width: 12px;
    border-radius: 50%;
    border: 1px solid;
    margin-top: 2.5px;
}

.tir-check {
    fill: #21c17c !important;
}

.ti-active {
    background-color: #E9F8F2;
}

.ps-handicap input {
    margin-left: 20px;
    width: 10vw !important;
    text-align: center;
    /* background-color: white !important; */
    /* color: #232c2e !important; */
    padding: 5px 10px;
    font-size: 14px;
    font-weight: 600;
    /* border: 2px solid #232c2e; */
    border-radius: 20px;
}

.rpm-name {
    display: flex;
    align-items: center;
}

.ps-name-icon {
    height: 20px !important;
    width: 20px !important;
    fill: #21c17c !important;
    margin-left: 10px;
}

.rp-name-input {
    border: none !important;
    width: min-content !important;
    padding: 0px !important;
    color: #232c2e !important;
    border-radius: 0px !important;
    font-weight: bold !important;
    font-size: 14px !important;
}