@media all and (min-width: 1281px) {
  .abob h3 {
    width: 10vw;
  }
}
@media all and (min-width: 1024px) and (max-width: 1280px) {
  .abob h3 {
    width: 15vw;
  }
}

@media all and (min-width: 768px) and (max-width: 1024px) {
  .hae-events .fixture-preview {
    width: 38vw !important;
  }

  .abob h3 {
    width: 15vw;
  }

  .si-card {
    width: 90vw !important;
  }

  .selector-holes {
    width: 65vw !important;
  }

  .ed-box {
    width: 90vw !important;
  }
}

@media all and (min-width: 481px) and (max-width: 768px) {
  .sce-details {
    flex-direction: column !important;
    row-gap: 20px;
  }

  .sce-opts {
    row-gap: 10px;
  }

  .sce-details .MuiAvatar-root {
    height: 30vw !important;
    width: 30vw !important;
    margin-bottom: 20px;
  }

  .acc-drill-row {
    flex-wrap: wrap;
    row-gap: 10px;
    align-items: center !important;
  }

  .adr-item {
    padding: 0px 10px !important;
  }

  .acc-drill-row .Dropdown-control {
    width: 25vw !important;
  }

  .csg-per {
    flex-wrap: wrap;
    flex-direction: column;
    align-items: center !important;
  }

  .ec-tabs {
    max-width: 90vw;
  }

  .rg-20 {
    row-gap: 20px;
  }

  .rdi-cc .rdi-cc-res .course-item {
    width: 90vw !important;
  }

  .rdi-search {
    flex-direction: column !important;
    row-gap: 10px;
  }

  .gre-meu {
    max-width: 90vw;
  }

  .crl-smol {
    display: none !important;
  }

  .clro-right {
    padding-left: 20px;
  }

  .csl-item {
    margin-right: 10px;
  }

  .pls-ps input {
    width: 70vw !important;
  }

  .vpb-box h2 {
    font-size: 40px !important;
  }

  .vpb-box p {
    font-size: 14px;
  }

  .lp-top {
    flex-direction: column;
    height: max-content !important;
  }

  .ed-box {
    width: 90vw !important;
  }

  .si-card {
    width: 90vw !important;
  }

  .selector-holes {
    width: 65vw !important;
  }

  .ltp-left {
    padding: 0px 20px !important;
    align-items: center;
  }

  .hae-events .fixture-preview {
    width: 38vw !important;
  }

  .si .lp-top h2 {
    text-align: center;
    margin-top: 20px;
    font-size: 36px !important;
  }

  .ltp-left h3 {
    text-align: center;
    font-size: 24px !important;
  }

  .ltp-left h5 {
    margin: 10px 0px !important;
    text-align: center;
  }

  .ltp-left p {
    text-align: center;
    width: 100% !important;
    font-size: 12px;
  }

  .lp-top button {
    /* display: none; */
  }

  .ltp-right img {
    height: 25vh !important;
    margin: 20px 0px;
  }

  .mb-left {
    /* display: none !important; */
  }

  .mb-right {
    /* display: none !important; */
  }

  .mb-middle {
    display: none !important;
    /* flex: 1 !important; */
  }

  .mb-right button {
    display: none;
  }

  .mbm-nav p {
    font-size: 12px !important;
  }

  .mbm-active p {
    font-size: 12px !important;
  }

  .lp-features {
    flex-direction: column;
  }

  .lpl-box,
  .lpr-box {
    width: 80vw !important;
    margin-bottom: 20px;
  }

  .lpl-top img {
    height: 25vh !important;
  }

  .lp-slider {
    flex-direction: column;
  }

  .ltp-left h2 {
    text-align: center;
  }

  .ltp-left h3 {
    margin: 20px 0px;
  }

  .lps-box {
    width: 100% !important;
  }

  .lp-video {
    flex-direction: column;
  }

  .lp-video iframe {
    width: 90vw !important;
  }

  .lpv-right h2 {
    margin-top: 20px;
    font-size: 32px !important;
  }

  .lpv-right h3 {
    font-size: 14px !important;
  }

  .about-box {
    flex-direction: column;
  }

  .dp-header h1 {
    font-size: 32px !important;
    margin-left: 20px;
  }

  .abob {
    flex-direction: column;
  }

  .abo-text p {
    width: 100% !important;
  }

  .abob p {
    max-width: none !important;
    width: 75vw !important;
  }

  .abo-right {
    margin-top: 20px;
  }

  .aboib-right {
    margin-left: 0px !important;
    width: 90vw !important;
  }

  .abo-header {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .aboibs {
    flex-direction: column;
  }
  .abo-ib-text p {
    max-width: none !important;
    width: 75vw !important;
  }

  .aboib-left {
    width: 100% !important;
  }

  .abo-header h1 {
    text-align: center;
  }

  .mobile-mt-20 {
    margin-top: 20px;
  }

  .aboi-box {
    margin-top: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .module-item {
    width: 100% !important;
  }

  .mb-left img {
    display: none;
  }

  .mb-right,
  .mb-left {
    flex: 0.5 !important;
  }

  .contact-container {
    width: 70vw !important;
  }

  .cp-email p {
    width: 75vw !important;
  }

  .cp-email {
    margin-top: 40px !important;
  }

  .contact-container {
    margin-top: 40px !important;
  }

  .menu-draw {
    width: 80vw !important;
  }

  .md-link p {
    font-size: 13px !important;
  }
  .md-profile .MuiAvatar-root {
    height: 40px !important;
    width: 40px !important;
  }

  .md-link svg {
    /* width: 0.75em !important; */
    /* height: 0.75em !important; */
  }

  .md-bot p {
    font-size: 10px !important;
  }

  .md-logo img {
    height: 10vh !important;
  }

  .page-header h2 {
    font-size: 20px !important;
  }

  .sub-header h5 {
    font-size: 14px !important;
  }

  .sd-menu {
    display: none !important;
  }

  .mes-txt p {
    font-size: 10px !important;
  }

  .mes-txt {
    padding: 0px !important;
  }

  .ph-me-opt {
    /* width: 100% !important; */
    align-items: flex-start !important;
    justify-content: flex-start !important;
    flex-wrap: wrap;
    flex-direction: column;
  }

  .ce-filters input {
    width: 75vw !important;
  }

  .RadioButton .btn {
    font-size: 12px !important;
  }

  .si-search {
    width: 80vw !important;
  }

  .sis-ig {
    width: 100%;
  }

  .si-search input {
    width: 100% !important;
  }

  .si-search button {
    padding: 10px 20px !important;
  }

  .clp-list {
    overflow: scroll;
  }

  .club-players .cp-box .player-item {
    width: 90vw !important;
  }

  .club-course {
    width: 75vw !important;
  }

  .hd-item {
    width: 90vw !important;
    height: 50vh !important;
  }

  .hd-item .page-header h2 {
    font-size: 16px !important;
  }

  .fp-left img {
    height: 50px !important;
    width: 50px !important;
  }

  .fprb-rds {
    display: none;
  }

  .fp-right h1 {
    max-width: none !important;
    font-size: 12px !important;
  }

  .fp-right h2 {
    font-size: 11px !important;
  }

  .fp-icons {
    display: none !important;
  }

  .ddm-but {
    font-size: 11px !important;
    padding: 5px 10px !important;
  }

  .ddmb-box {
    flex-wrap: wrap;
  }

  .ddmb-box .ddm-box {
    margin-bottom: 10px;
  }

  .ch-links {
    width: 100% !important;
  }

  .pill-button p {
    font-size: 11px !important;
  }

  .chat-drawer .MuiPaper-root {
    width: 100vw !important;
    border-radius: 0px !important;
  }

  .green-link {
    font-size: 11px !important;
  }

  .ltp-left button {
    width: 75vw !important;
    font-size: 20px !important;
  }

  .login {
    width: 95vw !important;
    justify-content: center;
  }

  .login-image img {
    width: 50vw !important;
    height: auto !important;
  }

  .login-dia .MuiPaper-root {
    margin: 0px !important;
  }

  .login-dia .default-input {
    width: 70vw !important;
  }

  .login-box {
    box-shadow: none !important;
  }

  .ph-me-opt .page-header {
    padding-left: 0px !important;
    padding-bottom: 0px !important;
  }

  .ph-me-opt {
    padding-left: 20px;
    /* padding-bottom: 10px; */
  }

  .event-round {
    width: calc(90vw - 40px) !important;
  }

  .ccr-icon {
    width: 20px !important;
    height: 20px !important;
  }

  .er-left {
    display: none !important;
  }

  .er-right p {
    padding-right: 10px;
  }

  .RadioButton .btn {
    width: 30vw !important;
  }

  .epb-left .ig-header h3 {
    width: 50vw !important;
  }

  .default-input {
    /* width: 65vw !important; */
  }

  .ec-box .Dropdown-root {
    /* width: calc(90vw - 40px) !important; */
    width: 100% !important;
  }

  .event-creator .ec-box .input-group {
    width: 75vw !important;
  }

  .ec-date-ip {
    width: 65vw !important;
  }

  .default-button {
    font-size: 14px !important;
  }

  .default-button-row {
    display: flex;
    justify-content: center;
  }

  .event-creator input {
    width: 65vw !important;
  }

  .back-row {
    padding: 5px 10px 5px 10px !important;
  }

  .back-row p {
    font-size: 11px !important;
  }

  .bm-dia {
    width: 80vw !important;
  }

  .back-row svg {
    height: 20px !important;
    width: 20px !important;
  }

  .RadioButton-two .btn {
    width: 25vw !important;
    font-size: 12px !important;
  }

  .bcm-dia .MuiPaper-root {
    width: 95vw !important;
    margin: 0px !important;
  }

  .mpa-dia .MuiDialog-paper {
    margin: 0px !important;
  }

  .mpa-dia .MuiDialog-paper {
    border-radius: 0px !important;
    max-height: 100% !important;
  }

  .lap-dia .MuiDialog-paper {
    width: 100%;
  }

  .ig-group {
    flex-wrap: wrap;
  }

  .mpa-players .player-item {
    width: 75vw !important;
  }

  .mmt-20 {
    margin-top: 20px !important;
  }

  .ipm-search-ico {
    display: none !important;
  }

  .event-home .part-players-box .player-item {
    width: 75vw !important;
  }

  .input-group .ig-header p {
    width: 45vw;
  }

  .ec-tabs .MuiTabs-flexContainer {
    overflow: scroll;
  }

  .ec-box textarea {
    width: auto !important;
  }

  .dz-sel {
    width: 40vw !important;
    height: 40vw !important;
  }

  .ec-tabs .MuiTab-root {
    font-size: 12px !important;
  }

  .event-home .ne-header {
    font-size: 13px !important;
  }

  .delete-button {
    font-size: 16px !important;
  }

  .ld-division {
    width: 100% !important;
    margin-right: 0px !important;
  }

  .event-guests .input-group {
    width: 70vw;
  }

  .event-home .default-input {
    width: 50vw;
  }

  .new-guest-row {
    flex-direction: column;
  }

  .new-guest-row .input-group {
    margin-bottom: 20px;
  }

  .eg-icos {
    display: flex;
  }

  .cf-opts {
    flex-direction: column;
  }

  .custom-formats .radio-group {
    padding-left: 0px !important;
  }

  .cf-button {
    font-size: 12px !important;
  }

  .cff-top {
    padding-right: 20px;
    padding-left: 20px;
  }

  .custom-formats .pres-group .Dropdown-control {
    width: 50vw !important;
  }

  .cf-stroke {
    display: none !important;
  }

  .cf-default {
    padding-left: 0px !important;
  }

  .cfh-deets p {
    margin-right: 10px !important;
    text-align: center;
  }

  .cf-dc {
    margin-left: 0px !important;
  }

  .cfh-type .radio-group .btn {
    font-size: 10px !important;
    width: 16vw !important;
  }

  .cfh-deets {
    min-width: 0px !important;
  }

  .rs-box .input-group {
    width: 75vw !important;
  }

  .sub-header h3 {
    font-size: 14px;
  }

  .pts-tees {
    overflow: scroll;
    padding: 5px;
  }

  .pts-circle {
    height: 10vw !important;
    width: 10vw !important;
  }

  .club-editor .ec-box .input-group:not(.colour-group) {
    width: 75vw !important;
  }
  .club-editor .ec-box .input-group input[type="text"] {
    width: 65vw !important;
  }

  .club-editor .ec-box .player-item {
    width: 90vw !important;
  }

  .cps-search input {
    width: 65vw !important;
  }

  .select-group select {
    width: 75vw !important;
  }

  .message-item {
    width: calc(90vw - 40px) !important;
  }

  .mi-right h2 {
    font-size: 14px !important;
  }

  .mi-right h5 {
    font-size: 10px !important;
  }

  .mi-right {
    padding-right: 10px;
  }

  .ecb-left input {
    width: 50vw !important;
  }

  .ecb-left textarea {
    width: 50vw !important;
  }

  .mb-add .dz-sel {
    width: 50vw !important;
  }

  .club-sponsor {
    width: 90vw !important;
    padding: 10px 0px !important;
  }

  .new-sponsor input {
    width: 50vw !important;
  }

  .new-sponsor .nassau-group {
    width: calc(50vw + 40px) !important;
    justify-content: space-between;
  }

  .new-sponsor .dz-sel {
    width: 50vw !important;
  }

  .user-import .input-group {
    width: 75vw !important;
  }

  .user-import .input-group input {
    width: 65vw !important;
  }

  .pids-search {
    justify-content: flex-start !important;
  }

  .pids-search .input-group {
    width: 75vw !important;
  }

  .pids-search .default-input {
    width: 65vw !important;
  }

  .cpi-row h5 {
    overflow: scroll;
    font-size: 12px;
  }

  .pg-item {
    width: 90vw !important;
  }

  .player-groups .pg-players .player-item {
    width: 28vw !important;
  }

  .apg-ctt .input-group {
    width: 75vw;
  }

  .apg-ctt .default-input {
    width: calc(100% - 40px) !important;
  }

  .apg-ctt .mpaf-slider {
    width: 100% !important;
  }

  .pp-friends .player-item {
    width: 90vw !important;
  }

  .input-group p {
    font-size: 11px !important;
  }

  .ph-msg p {
    font-size: 10px !important;
  }

  .clp-header p {
    font-size: 10px !important;
  }

  .clp-header div {
    display: flex;
    justify-content: center;
  }

  .cl-player td {
    font-size: 11px !important;
    text-align: center;
  }

  .pids-box {
    min-width: 80vw !important;
  }

  .pids-box .cpi-header p {
    font-size: 13px !important;
  }

  .cpi-row p {
    font-size: 12px !important;
  }

  .page-header {
    padding-bottom: 10px !important;
    max-width: 75vw;
  }

  .ph-msg {
    margin-bottom: 10px;
  }

  .pids-box-box {
    margin-top: 0px !important;
  }

  .default-input {
    font-size: 11px !important;
  }

  .calendar-page .cal-events .fixture-preview {
    width: 90vw !important;
  }

  .cp-container .rbc-toolbar {
    justify-content: center !important;
  }

  .rbc-toolbar .rbc-btn-group {
    margin-bottom: 20px;
  }

  .ce-events .fixture-preview {
    width: calc(100% - 20px) !important;
    margin-right: 0px !important;
  }

  .ce-filters {
    flex-wrap: wrap;
  }

  .cl-box .ch-leagues .course-item {
    width: calc(100% - 40px) !important;
  }

  .tabs-container .MuiTab-root {
    font-size: 11px !important;
    min-height: 40px !important;
  }

  .tabs-container .MuiTabs-root {
    min-height: 40px !important;
  }

  .tabs-container .MuiTabs-scroller {
    overflow: scroll !important;
  }

  .tabs-container {
    width: 90vw !important;
  }

  .fp-cont .fixture-preview {
    width: calc(100% - 20px);
    margin-right: 0px !important;
  }

  .selector-group button {
    font-size: 12px !important;
    padding: 5px 15px !important;
  }

  .seasons-container .course-item {
    width: 80vw !important;
  }

  .members-container .player-item {
    width: 100% !important;
    margin-right: 0px !important;
  }

  .league-members .input-group {
    width: 80vw !important;
  }

  .league-members .default-input {
    width: 100% !important;
  }

  .league-admins .player-item {
    width: 90vw !important;
  }

  .sub-header h2 {
    font-size: 14px !important;
  }

  .season-editor .ec-box .input-group {
    width: 75vw !important;
    margin-bottom: 20px;
  }

  .season-editor .ec-box .default-input {
    width: 65vw !important;
  }

  .season-editor .lc-box {
    margin-top: 20px !important;
    margin-bottom: 20px !important;
  }

  .im-players .player-item {
    width: 100% !important;
    margin-right: 0px !important;
  }

  .lap-content {
    width: 100vw !important;
  }

  .player-item .MuiAvatar-root {
    width: 50px !important;
    height: 50px !important;
  }

  .eg-di .default-input {
    width: 50vw !important;
  }

  .event-remove-player {
    max-height: 100vh !important;
    height: 100vh !important;
    padding: 0px !important;
    width: 100vw !important;
  }

  .event-remove-player .erp-players .player-item {
    width: 80vw !important;
  }

  .erpc-msg {
    width: 80%;
  }

  .erpc-msg {
    text-align: center;
  }

  .erp-confirm .default-input {
    width: 75vw !important;
  }

  .erp-players {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .lap-filt {
    flex-wrap: wrap;
  }

  .player-list-item p {
    font-size: 11px !important;
  }

  .pli-item svg {
    height: 20px !important;
    width: 20px !important;
  }

  .drop-group {
    width: 90vw !important;
  }

  .gs-rem-dia {
    width: 100vw !important;
    height: 100vh;
  }

  .etm-dia .MuiPaper-root {
    width: 100% !important;
    border-radius: 0px !important;
    margin: 0px !important;
    height: 100vh;
    max-height: 100%;
  }

  .ted-box .input-group {
    width: 75vw !important;
  }

  .ted-box .default-input {
    width: 50vw !important;
  }

  .team-edit-dia .ted-box .dz-sel {
    height: 30vw !important;
    width: 30vw !important;
  }

  .evt-player-list .epl-header p {
    font-size: 12px !important;
  }

  .epl-player .Dropdown-control {
    padding-top: 1.75px !important;
    padding-bottom: 1.75px !important;
  }

  .epl-player {
    justify-content: space-between !important;
  }

  .epl-player p {
    font-size: 12px !important;
  }

  .epl-name {
    flex: 0.2 !important;
  }

  .epl-tee {
    flex: 0.3 !important;
  }

  .epl-edit {
    flex: 0.1 !important;
  }

  .epl-edit .MuiSvgIcon-root {
    height: 12px !important;
    width: 12px !important;
  }

  .ae-draw .MuiPaper-root {
    width: calc(80vw + 40px) !important;
  }

  .ed-box {
    width: 90vw !important;
    height: 75vw !important;
  }

  .leaderboard-players .lbp-buts .default-button {
    font-size: 11px !important;
  }

  .event-dash .ed-box .sub-header svg {
    display: none !important;
  }

  .sett-ph .page-header {
    margin-right: 20px !important;
  }

  .eve-rds {
    flex-wrap: wrap;
  }

  .si-card {
    width: 100vw !important;
  }

  .si-table {
    overflow: scroll;
  }

  .sit-box {
    overflow: scroll;
  }

  .score-input .sub-header {
    flex-wrap: wrap;
  }

  .score-input .sub-header .selector-group {
    padding-left: 0px !important;
  }

  .si-sel-hole {
    height: 6vw !important;
    width: 6vw !important;
  }

  .si-sel-hole p {
    font-size: 12px !important;
  }

  .si-table th {
    font-size: 10px !important;
  }

  .si-table td {
    font-size: 12px !important;
  }

  .sis-item p {
    font-size: 11px !important;
  }

  .selector-holes {
    width: 100% !important;
  }

  .pts-player {
    flex-direction: column;
  }

  .ltc-dia .MuiPaper-root {
    margin: 0px !important;
    width: 100%;
  }

  .dg-players .drop-group h2 {
    font-size: 13px !important;
  }

  .ptsp-left {
    margin-bottom: 10px;
  }

  .pts-tees {
    margin-left: 0px !important;
    overflow: scroll;
    width: 100%;
    justify-content: center;
  }

  .event-handicap-edit {
    width: 100vw !important;
  }

  .ltc-dia .MuiPaper-root {
    border-radius: 0px !important;
  }

  .ehel {
    width: 100vw !important;
  }

  .pts-name p {
    font-size: 13px !important;
  }

  .ltc-handicaps p {
    font-size: 12px !important;
  }

  .ehe-dia .MuiPaper-root {
    height: 100%;
    max-height: 100%;
    margin: 0px !important;
    border-radius: 0px !important;
  }

  .si-select {
    width: 100vw !important;
  }

  .si-select-dialog .MuiPaper-root {
    margin: 0px !important;
    border-radius: 0px !important;
    height: 100vh !important;
    max-height: 100% !important;
  }

  .si-card {
    box-shadow: none !important;
    padding: 0px !important;
  }

  .round-player .rp-mid h2 {
    font-size: 12px !important;
  }

  .round-player .rp-mid p {
    font-size: 11px !important;
  }

  .tied-players {
    overflow-x: scroll;
  }

  .tp-player {
    /* overflow: scroll; */
  }

  .tp-header {
    /* overflow: scroll; */
  }

  .mb-ae-box .fixture-preview {
    width: 80vw;
  }

  .mb-ae-box {
    padding: 0px !important;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .tph-opt {
    width: 20vw !important;
  }

  .event-add-player {
    width: 100vw !important;
    height: 100vh !important;
  }

  .event-add-player .eap-players .player-item {
    width: 100% !important;
  }

  .evt-add-guest {
    width: 100vw !important;
    height: 100vh !important;
  }

  .evt-add-guest .input-group {
    width: 75vw !important;
  }

  .evt-add-guest .default-input {
    width: 65vw !important;
  }

  .gs-row {
    /* display: flex; */
    flex-wrap: wrap;
  }

  .group-selector .gh-options .default-input {
    width: 20vw !important;
  }

  .thsl,
  .thsr {
    width: 10vw !important;
    height: 10vw !important;
  }

  .player-dq .ne-header {
    font-size: 16px !important;
  }

  .player-dq .pdq-ps .player-item {
    width: 90vw !important;
  }

  .player-dq .input-group {
    margin-top: 0px !important;
  }

  .bm-dia .bm-input textarea {
    width: 65vw !important;
  }

  .pm-dia .MuiPaper-root {
    height: 100vh !important;
  }

  .team-league-home .hd-item {
    width: 80vw !important;
  }

  .tem-team span {
    height: 7vw !important;
    width: 7vw !important;
    font-size: 16px !important;
  }

  .tem-match .matchplay-score .ms-mid .msm-top {
    height: 8vw !important;
    width: 8vw !important;
  }

  .mpbm-circle {
    height: 8vw !important;
    width: 8vw !important;
  }

  .ted-col .mpb-match .player-item {
    width: 25vw !important;
  }

  .MuiDialog-paper {
    margin: 0px !important;
    border-radius: 0px !important;
    width: 100vw;
  }

  .mpb-match {
    border: none !important;
    border-radius: 0px !important;
    width: 100%;
    justify-content: space-evenly;
  }

  .mhrb-left {
    min-width: 30vw !important;
  }

  .mhr-state-bot {
    display: flex;
    align-items: center;
  }

  .mhr-state-bot .mhr-state-act-two .mhrw-box {
    height: 10vw !important;
    width: 10vw !important;
  }

  .ted-result-input .ted-col .mp-man-holes {
    width: 75vw !important;
  }

  .mhrb-state .mhr-state-act-tie .mhrw-box {
    height: 10vw !important;
    width: 10vw !important;
  }

  .tlee-buts {
    flex-direction: column;
  }

  .tlee-buts button {
    margin-bottom: 10px;
  }

  .ltm-teams .tsp-right .player-item {
    width: 60vw !important;
  }

  .ltm-team {
    width: 100% !important;
  }

  .mhr-state-act-tie {
    display: flex;
    align-items: center;
  }

  .delete-button {
    font-size: 14px !important;
  }

  .mhr-state-act {
    display: flex;
    align-items: center;
  }

  .mhr-state-mid {
    display: flex;
    justify-content: center;
  }

  .mhrb-hole-i .mhrb-left {
    /* width: 30vw !important; */

    /* margin-left: 20vw !important; */
  }

  .ted-col .mp-man-holes .default-input {
    width: 10vw !important;
  }

  .mhrb-state .mhr-state-top .mhrw-box {
    height: 10vw !important;
    width: 10vw !important;
  }

  .mhrw-box {
    width: 10vw !important;
    height: 10vw !important;
  }

  .mhrb-hole {
    width: 10vw !important;
    margin-right: 10px;
  }

  .mhrb-ct {
    overflow: scroll;
  }

  .mhrb-state {
    width: 10vw !important;
  }

  .hd-item {
    /* box-shadow: none !important; */
  }

  .ed-box {
    /* box-shadow: none !important; */
  }

  .team-league-editor .input-group {
    width: 75vw !important;
  }

  .team-league-editor .default-input:not(.default-select) {
    width: 65vw !important;
  }

  .team-league-editor .ec-box .player-item {
    width: 90vw !important;
  }

  .mt-items .course-item {
    width: 80vw !important;
  }

  .bdm-player {
    width: 25vw !important;
  }

  .event-home .bdm-player h1 {
    text-align: center;
    font-size: 10px !important;
  }

  .bd-box {
    overflow: scroll;
  }

  .singles-middle {
    padding: 10px 0px;
  }

  .mpr-rounds .mpb-match {
    width: 100% !important;
  }

  .singles-match {
    flex-direction: column;
  }

  .singles-match input {
    margin-left: 20px !important;
  }

  .cm-pc {
    /* width: 100%; */
    justify-content: space-between !important;
  }

  .mpr-match .mpb-match .player-item {
    width: 25vw !important;
  }

  .cp-cups .fixture-preview {
    width: 80vw !important;
  }

  .mpl-round-header h2 {
    font-size: 14px !important;
  }

  .mp-start-dia .mps-players .player-item {
    width: 90vw !important;
  }

  .mp-ma-dia .MuiPaper-root {
    max-height: 100%;
  }

  .mp-start-dia {
    height: 100vh;
    width: 100vw !important;
  }

  .bdr-header p {
    font-size: 12px !important;
  }

  .news-pill p {
    font-size: 12px !important;
  }

  .mpl-matches .mpb-match .player-item {
    width: 25vw !important;
  }

  .mpbm-circle p {
    font-size: 11px !important;
  }

  .mpb-match {
    padding: 0px !important;
  }

  .new-contest .ec-box .default-input {
    width: 65vw !important;
  }

  .new-contest .ec-box .input-group {
    width: 75vw !important;
  }

  .comp-prizes {
    flex-direction: column;
  }

  .contest-item h1 {
    font-size: 13px !important;
  }

  .contest-item h2 {
    font-size: 12px !important;
  }

  .contest-item .MuiSvgIcon-root {
    height: 20px !important;
    width: 20px !important;
  }

  .contest-page .participants-bloc .player-item {
    width: 90vw !important;
  }

  .cir-item {
    height: 25vw !important;
    width: 25vw !important;
  }

  .cir-item h2 {
    font-size: 14px !important;
  }

  .cir-item h3 {
    font-size: 12px !important;
  }

  .contest-input .ne-header {
    font-family: "MS-SM";
    font-size: 14px;
  }

  .aci-players {
    padding: 0px !important;
  }

  .aci-players {
    width: 100% !important;
  }

  .contest-player {
    width: calc(100% - 20px) !important;
    padding: 10px !important;
  }

  .contest-player-header p {
    font-size: 12px !important;
  }

  .contest-player p {
    font-size: 12px !important;
  }

  .coi-dia .MuiDialog-paper {
    max-height: 100% !important;
  }

  .contest-input {
    height: 100vh;
  }

  .new-query-modal textarea {
    width: 65vw !important;
  }

  .support-page .default-input {
    width: 65vw !important;
  }

  .faq-cat {
    padding: 10px 20px !important;
  }

  .sp-item {
    margin-right: 20px !important;
    padding: 10px !important;
  }

  .sp-group p {
    width: 20vw !important;
  }

  .set-sds {
    flex-direction: column;
    align-items: flex-start !important;
  }

  .settings-page .ec-box .default-input {
    width: 65vw !important;
  }

  .settings-page .ec-box .green-link {
    margin-top: 20px;
  }

  .onboarding .default-input {
    width: 65vw !important;
  }

  .onboarding .input-group {
    width: 75vw !important;
  }

  .hae-events .fixture-preview {
    width: 80vw !important;
  }
}

@media all and (max-width: 480px) {
  .hl-logo img {
    height: 20vh !important;
  }

  .hl-header h1 {
    width: 90vw;
    font-size: 36px !important;
    text-align: center;
  }

  .hl-item {
    width: 80vw !important;
  }

  .hl-ico {
    height: 10vw !important;
    width: 10vw !important;
  }

  .lp-top {
    flex-direction: column;
    height: max-content !important;
  }

  .ltp-left {
    padding: 0px 20px !important;
    align-items: center;
  }

  .lp-top h2 {
    text-align: center;
    margin-top: 20px;
    font-size: 36px !important;
  }

  .ltp-left h3 {
    text-align: center;
    font-size: 24px !important;
  }

  .ltp-left h5 {
    margin: 10px 0px !important;
    text-align: center;
  }

  .ltp-left p {
    text-align: center;
    width: 100% !important;
    font-size: 12px;
  }

  .lp-top button {
    /* display: none; */
  }

  .lp-top p {
    display: none !important;
  }

  .ltp-right img {
    height: 25vh !important;
    margin: 20px 0px;
  }

  .mb-left {
    /* display: none !important; */
    flex: 0.5 !important;
  }

  .mb-right {
    /* display: none !important; */
    flex: 0.5 !important;
  }

  .mb-middle {
    /* flex: 1 !important; */
    display: none !important;
  }

  .mb-right button {
    display: none;
  }

  .mb-left img {
    display: none !important;
  }

  .mbm-nav p {
    font-size: 12px !important;
  }

  .mbm-active p {
    font-size: 12px !important;
  }

  .lp-features {
    flex-direction: column;
  }

  .lpl-box,
  .lpr-box {
    width: 80vw !important;
    margin-bottom: 20px;
  }

  .lpl-top img {
    height: 25vh !important;
  }

  .lp-slider {
    flex-direction: column;
  }

  .ltp-left h2 {
    text-align: center;
  }

  .ltp-left h3 {
    margin: 20px 0px;
  }

  .lps-box {
    width: 100% !important;
  }

  .lp-video {
    flex-direction: column;
  }

  .lp-video iframe {
    width: 90vw !important;
  }

  .lpv-right h2 {
    margin-top: 20px;
    font-size: 32px !important;
  }

  .lpv-right h3 {
    font-size: 14px !important;
  }

  .about-box {
    flex-direction: column;
  }

  .dp-header h1 {
    font-size: 32px !important;
    margin-left: 20px;
  }

  .abob {
    flex-direction: column;
  }

  .abo-text p {
    width: 100% !important;
  }

  .abob p {
    max-width: none !important;
    width: 75vw !important;
  }

  .abo-right {
    margin-top: 20px;
  }

  .aboib-right {
    margin-left: 0px !important;
    width: 90vw !important;
  }

  .abo-header {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .aboibs {
    flex-direction: column;
  }
  .abo-ib-text p {
    max-width: none !important;
    width: 75vw !important;
  }

  .aboib-left {
    width: 100% !important;
  }

  .abo-header h1 {
    text-align: center;
  }

  .mobile-mt-20 {
    margin-top: 20px;
  }

  .aboi-box {
    margin-top: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .module-item {
    width: 100% !important;
  }

  .contact-container {
    width: 70vw !important;
  }

  .cp-email p {
    width: 75vw !important;
  }

  .cp-email {
    margin-top: 40px !important;
  }

  .contact-container {
    margin-top: 40px !important;
  }

  .menu-draw {
    width: 80vw !important;
  }

  .md-link p {
    font-size: 13px !important;
  }
  .md-profile .MuiAvatar-root {
    height: 40px !important;
    width: 40px !important;
  }

  .md-link svg {
    /* width: 0.75em !important; */
    /* height: 0.75em !important; */
  }

  .md-bot p {
    font-size: 10px !important;
  }

  .md-logo img {
    height: 10vh !important;
  }

  .page-header h2 {
    font-size: 20px !important;
  }

  .sub-header h5 {
    font-size: 14px !important;
  }

  .sd-menu {
    display: none !important;
  }

  .mes-txt p {
    font-size: 10px !important;
  }

  .mes-txt {
    padding: 0px !important;
  }

  .ph-me-opt {
    /* width: 100% !important; */
    align-items: flex-start !important;
    justify-content: flex-start !important;
    flex-wrap: wrap;
    flex-direction: column;
  }

  .ce-filters input {
    width: 75vw !important;
  }

  .RadioButton .btn {
    font-size: 12px !important;
  }

  .si-search {
    width: 80vw !important;
  }

  .sis-ig {
    width: 100%;
  }

  .si-search input {
    width: 100% !important;
  }

  .si-search button {
    padding: 10px 20px !important;
  }

  .clp-list {
    overflow: scroll;
  }

  .club-players .cp-box .player-item {
    width: 90vw !important;
  }

  .club-course {
    width: 75vw !important;
  }

  .hd-item {
    width: 90vw !important;
    height: 50vh !important;
  }

  .hd-item .page-header h2 {
    font-size: 16px !important;
  }

  .fp-left img {
    height: 50px !important;
    width: 50px !important;
  }

  .fprb-rds {
    display: none;
  }

  .fp-right h1 {
    max-width: none !important;
    font-size: 12px !important;
  }

  .fp-right h2 {
    font-size: 11px !important;
  }

  .fp-icons {
    display: none !important;
  }

  .ddm-but {
    font-size: 11px !important;
    padding: 5px 10px !important;
  }

  .ddmb-box {
    flex-wrap: wrap;
  }

  .ddmb-box .ddm-box {
    margin-bottom: 10px;
  }

  .ch-links {
    width: 100% !important;
  }

  .pill-button p {
    font-size: 11px !important;
  }

  .chat-drawer .MuiPaper-root {
    width: 100vw !important;
    border-radius: 0px !important;
  }

  .green-link {
    font-size: 11px !important;
  }

  .ltp-left button {
    width: 75vw !important;
    font-size: 20px !important;
  }

  .login {
    width: 95vw !important;
    justify-content: center;
  }

  .login-image img {
    width: 50vw !important;
    height: auto !important;
  }

  .login-dia .MuiPaper-root {
    margin: 0px !important;
  }

  .login-dia .default-input {
    width: 70vw !important;
  }

  .login-box {
    box-shadow: none !important;
  }

  .ph-me-opt .page-header {
    padding-left: 0px !important;
    padding-bottom: 0px !important;
  }

  .ph-me-opt {
    padding-left: 20px;
    /* padding-bottom: 10px; */
  }

  .event-round {
    width: calc(90vw - 40px) !important;
  }

  .ccr-icon {
    width: 20px !important;
    height: 20px !important;
  }

  .er-left {
    display: none !important;
  }

  .er-right p {
    padding-right: 10px;
  }

  .RadioButton .btn {
    width: 30vw !important;
  }

  .epb-left .ig-header h3 {
    width: 50vw !important;
  }

  .default-input {
    /* width: 65vw !important; */
  }

  .ec-box .Dropdown-root {
    /* width: calc(90vw - 40px) !important; */
    width: 100% !important;
  }

  .event-creator .ec-box .input-group {
    width: 75vw !important;
  }

  .ec-date-ip {
    width: 65vw !important;
  }

  .default-button {
    font-size: 14px !important;
  }

  .default-button-row {
    display: flex;
    justify-content: center;
  }

  .event-creator input {
    width: 65vw !important;
  }

  .back-row {
    padding: 5px 10px 5px 10px !important;
  }

  .back-row p {
    font-size: 11px !important;
  }

  .bm-dia {
    width: 80vw !important;
  }

  .back-row svg {
    height: 20px !important;
    width: 20px !important;
  }

  .RadioButton-two .btn {
    width: 25vw !important;
    font-size: 12px !important;
  }

  .bcm-dia .MuiPaper-root {
    width: 95vw !important;
    margin: 0px !important;
  }

  .mpa-dia .MuiDialog-paper {
    margin: 0px !important;
  }

  .mpa-dia .MuiDialog-paper {
    border-radius: 0px !important;
    max-height: 100% !important;
  }

  .lap-dia .MuiDialog-paper {
    width: 100%;
  }

  .ig-group {
    flex-wrap: wrap;
  }

  .mpa-players .player-item {
    width: 75vw !important;
  }

  .mmt-20 {
    margin-top: 20px !important;
  }

  .ipm-search-ico {
    display: none !important;
  }

  .event-home .part-players-box .player-item {
    width: 75vw !important;
  }

  .input-group .ig-header p {
    width: 45vw;
  }

  .ec-tabs .MuiTabs-flexContainer {
    overflow: scroll;
  }

  .ec-box textarea {
    width: auto !important;
  }

  .dz-sel {
    width: 40vw !important;
    height: 40vw !important;
  }

  .ec-tabs .MuiTab-root {
    font-size: 10px !important;
  }
  .dt-tabs .MuiTab-root {
    font-size: 10px !important;
  }
  .gre-meu .MuiTab-root {
    font-size: 10px !important;
  }

  .event-home .ne-header {
    font-size: 13px !important;
  }

  .delete-button {
    font-size: 16px !important;
  }

  .ld-division {
    width: 100% !important;
    margin-right: 0px !important;
  }

  .event-guests .input-group {
    width: 70vw;
  }

  .event-home .default-input {
    width: 50vw;
  }

  .new-guest-row {
    flex-direction: column;
  }

  .new-guest-row .input-group {
    margin-bottom: 20px;
  }

  .eg-icos {
    display: flex;
  }

  .cf-opts {
    flex-direction: column;
  }

  .custom-formats .radio-group {
    padding-left: 0px !important;
  }

  .cf-button {
    font-size: 12px !important;
  }

  .cff-top {
    padding-right: 20px;
    padding-left: 20px;
  }

  .custom-formats .pres-group .Dropdown-control {
    width: 50vw !important;
  }

  .cf-stroke {
    display: none !important;
  }

  .cf-default {
    padding-left: 0px !important;
  }

  .cfh-deets p {
    margin-right: 10px !important;
    text-align: center;
  }

  .cf-dc {
    margin-left: 0px !important;
  }

  .cfh-type .radio-group .btn {
    font-size: 10px !important;
    width: 16vw !important;
  }

  .cfh-deets {
    min-width: 0px !important;
  }

  .rs-box .input-group {
    width: 75vw !important;
  }

  .sub-header h3 {
    font-size: 14px;
  }

  .pts-tees {
    overflow: scroll;
    padding: 5px;
  }

  .pts-circle {
    height: 10vw !important;
    width: 10vw !important;
  }

  .club-editor .ec-box .input-group:not(.colour-group) {
    width: 75vw !important;
  }
  .club-editor .ec-box .input-group input[type="text"] {
    width: 65vw !important;
  }

  .club-editor .ec-box .player-item {
    width: 90vw !important;
  }

  .cps-search input {
    width: 65vw !important;
  }

  .select-group select {
    width: 75vw !important;
  }

  .message-item {
    width: calc(90vw - 40px) !important;
  }

  .mi-right h2 {
    font-size: 14px !important;
  }

  .mi-right h5 {
    font-size: 10px !important;
  }

  .mi-right {
    padding-right: 10px;
  }

  .ecb-left input {
    width: 50vw !important;
  }

  .ecb-left textarea {
    width: 50vw !important;
  }

  .mb-add .dz-sel {
    width: 50vw !important;
  }

  .club-sponsor {
    width: 90vw !important;
    padding: 10px 0px !important;
  }

  .new-sponsor input {
    width: 50vw !important;
  }

  .new-sponsor .nassau-group {
    width: calc(50vw + 40px) !important;
    justify-content: space-between;
  }

  .new-sponsor .dz-sel {
    width: 50vw !important;
  }

  .user-import .input-group {
    width: 75vw !important;
  }

  .user-import .input-group input {
    width: 65vw !important;
  }

  .pids-search {
    justify-content: flex-start !important;
  }

  .pids-search .input-group {
    width: 75vw !important;
  }

  .pids-search .default-input {
    width: 65vw !important;
  }

  .cpi-row h5 {
    overflow: scroll;
    font-size: 12px;
  }

  .pg-item {
    width: 90vw !important;
  }

  .player-groups .pg-players .player-item {
    width: 28vw !important;
  }

  .apg-ctt .input-group {
    width: 75vw;
  }

  .apg-ctt .default-input {
    width: calc(100% - 40px) !important;
  }

  .apg-ctt .mpaf-slider {
    width: 100% !important;
  }

  .pp-friends .player-item {
    width: 90vw !important;
  }

  .input-group p {
    font-size: 11px !important;
  }

  .ph-msg p {
    font-size: 10px !important;
  }

  .clp-header p {
    font-size: 10px !important;
  }

  .clp-header div {
    display: flex;
    justify-content: center;
  }

  .cl-player td {
    font-size: 11px !important;
    text-align: center;
  }

  .pids-box {
    min-width: 80vw !important;
  }

  .pids-box .cpi-header p {
    font-size: 13px !important;
  }

  .cpi-row p {
    font-size: 12px !important;
  }

  .page-header {
    padding-bottom: 10px !important;
    max-width: 75vw;
  }

  .ph-msg {
    margin-bottom: 10px;
  }

  .pids-box-box {
    margin-top: 0px !important;
  }

  .default-input {
    font-size: 11px !important;
  }

  .calendar-page .cal-events .fixture-preview {
    width: 90vw !important;
  }

  .cp-container .rbc-toolbar {
    justify-content: center !important;
  }

  .rbc-toolbar .rbc-btn-group {
    margin-bottom: 20px;
  }

  .ce-events .fixture-preview {
    width: calc(100% - 20px) !important;
    margin-right: 0px !important;
  }

  .ce-filters {
    flex-wrap: wrap;
  }

  .cl-box .ch-leagues .course-item {
    width: calc(100% - 40px) !important;
  }

  .tabs-container .MuiTab-root {
    font-size: 11px !important;
    min-height: 40px !important;
  }

  .tabs-container .MuiTabs-root {
    min-height: 40px !important;
  }

  .tabs-container .MuiTabs-scroller {
    overflow: scroll !important;
  }

  .tabs-container {
    width: 90vw !important;
  }

  .fp-cont .fixture-preview {
    width: calc(100% - 20px);
    margin-right: 0px !important;
  }

  .selector-group button {
    font-size: 12px !important;
    padding: 5px 15px !important;
  }

  .seasons-container .course-item {
    width: 80vw !important;
  }

  .members-container .player-item {
    width: 100% !important;
    margin-right: 0px !important;
  }

  .league-members .input-group {
    width: 80vw !important;
  }

  .league-members .default-input {
    width: 100% !important;
  }

  .league-admins .player-item {
    width: 90vw !important;
  }

  .sub-header h2 {
    font-size: 14px !important;
  }

  .season-editor .ec-box .input-group {
    width: 75vw !important;
    margin-bottom: 20px;
  }

  .season-editor .ec-box .default-input {
    width: 65vw !important;
  }

  .season-editor .lc-box {
    margin-top: 20px !important;
    margin-bottom: 20px !important;
  }

  .im-players .player-item {
    width: 100% !important;
    margin-right: 0px !important;
  }

  .lap-content {
    width: 100vw !important;
  }

  .player-item .MuiAvatar-root {
    width: 50px !important;
    height: 50px !important;
  }

  .eg-di .default-input {
    width: 50vw !important;
  }

  .event-remove-player {
    max-height: 100vh !important;
    height: 100vh !important;
    padding: 0px !important;
    width: 100vw !important;
  }

  .event-remove-player .erp-players .player-item {
    width: 80vw !important;
  }

  .erpc-msg {
    width: 80%;
  }

  .erpc-msg {
    text-align: center;
  }

  .erp-confirm .default-input {
    width: 75vw !important;
  }

  .erp-players {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .lap-filt {
    flex-wrap: wrap;
  }

  .player-list-item p {
    font-size: 11px !important;
  }

  .pli-item svg {
    height: 20px !important;
    width: 20px !important;
  }

  .drop-group {
    width: 90vw !important;
  }

  .gs-rem-dia {
    width: 100vw !important;
    height: 100vh;
  }

  .etm-dia .MuiPaper-root {
    width: 100% !important;
    border-radius: 0px !important;
    margin: 0px !important;
    height: 100vh;
    max-height: 100%;
  }

  .ted-box .input-group {
    width: 75vw !important;
  }

  .ted-box .default-input {
    width: 50vw !important;
  }

  .team-edit-dia .ted-box .dz-sel {
    height: 30vw !important;
    width: 30vw !important;
  }

  .evt-player-list .epl-header p {
    font-size: 12px !important;
  }

  .epl-player .Dropdown-control {
    padding-top: 1.75px !important;
    padding-bottom: 1.75px !important;
  }

  .epl-player {
    justify-content: space-between !important;
  }

  .epl-player p {
    font-size: 12px !important;
  }

  .epl-name {
    flex: 0.2 !important;
  }

  .epl-tee {
    flex: 0.3 !important;
  }

  .epl-edit {
    flex: 0.1 !important;
  }

  .epl-edit .MuiSvgIcon-root {
    height: 12px !important;
    width: 12px !important;
  }

  .ae-draw .MuiPaper-root {
    width: calc(80vw + 40px) !important;
  }

  .ed-box {
    width: 90vw !important;
    height: 75vw !important;
  }

  .leaderboard-players .lbp-buts .default-button {
    font-size: 11px !important;
  }

  .event-dash .ed-box .sub-header svg {
    display: none !important;
  }

  .sett-ph .page-header {
    margin-right: 20px !important;
  }

  .eve-rds {
    flex-wrap: wrap;
  }

  .si-card {
    width: 100vw !important;
  }

  .si-table {
    overflow: scroll;
  }

  .sit-box {
    overflow: scroll;
  }

  .score-input .sub-header {
    flex-wrap: wrap;
  }

  .score-input .sub-header .selector-group {
    padding-left: 0px !important;
  }

  .si-sel-hole {
    height: 6vw !important;
    width: 6vw !important;
  }

  .si-sel-hole p {
    font-size: 12px !important;
  }

  .si-table th {
    font-size: 10px !important;
  }

  .si-table td {
    font-size: 12px !important;
  }

  .sis-item p {
    font-size: 11px !important;
  }

  .selector-holes {
    width: 100% !important;
  }

  .pts-player {
    flex-direction: column;
  }

  .ltc-dia .MuiPaper-root {
    margin: 0px !important;
    width: 100%;
  }

  .dg-players .drop-group h2 {
    font-size: 13px !important;
  }

  .ptsp-left {
    margin-bottom: 10px;
  }

  .pts-tees {
    margin-left: 0px !important;
    overflow: scroll;
    width: 100%;
    justify-content: center;
  }

  .event-handicap-edit {
    width: 100vw !important;
  }

  .ltc-dia .MuiPaper-root {
    border-radius: 0px !important;
  }

  .ehel {
    width: 100vw !important;
  }

  .pts-name p {
    font-size: 13px !important;
  }

  .ltc-handicaps p {
    font-size: 12px !important;
  }

  .ehe-dia .MuiPaper-root {
    height: 100%;
    max-height: 100%;
    margin: 0px !important;
    border-radius: 0px !important;
  }

  .si-select {
    width: 100vw !important;
  }

  .si-select-dialog .MuiPaper-root {
    margin: 0px !important;
    border-radius: 0px !important;
    height: 100vh !important;
    max-height: 100% !important;
  }

  .si-card {
    box-shadow: none !important;
    padding: 0px !important;
  }

  .round-player .rp-mid h2 {
    font-size: 12px !important;
  }

  .round-player .rp-mid p {
    font-size: 11px !important;
  }

  .tied-players {
    overflow-x: scroll;
  }

  .tp-player {
    /* overflow: scroll; */
  }

  .tp-header {
    /* overflow: scroll; */
  }

  .mb-ae-box .fixture-preview {
    width: 80vw;
  }

  .mb-ae-box {
    padding: 0px !important;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .tph-opt {
    width: 20vw !important;
  }

  .event-add-player {
    width: 100vw !important;
    height: 100vh !important;
  }

  .event-add-player .eap-players .player-item {
    width: 100% !important;
  }

  .evt-add-guest {
    width: 100vw !important;
    height: 100vh !important;
  }

  .evt-add-guest .input-group {
    width: 75vw !important;
  }

  .evt-add-guest .default-input {
    width: 65vw !important;
  }

  .gs-row {
    /* display: flex; */
    flex-wrap: wrap;
  }

  .group-selector .gh-options .default-input {
    width: 20vw !important;
  }

  .thsl,
  .thsr {
    width: 10vw !important;
    height: 10vw !important;
  }

  .player-dq .ne-header {
    font-size: 16px !important;
  }

  .player-dq .pdq-ps .player-item {
    width: 90vw !important;
  }

  .player-dq .input-group {
    margin-top: 0px !important;
  }

  .bm-dia .bm-input textarea {
    width: 65vw !important;
  }

  .pm-dia .MuiPaper-root {
    height: 100vh !important;
  }

  .team-league-home .hd-item {
    width: 80vw !important;
  }

  .tem-team span {
    height: 7vw !important;
    width: 7vw !important;
    font-size: 16px !important;
  }

  .tem-match .matchplay-score .ms-mid .msm-top {
    height: 8vw !important;
    width: 8vw !important;
  }

  .mpbm-circle {
    height: 8vw !important;
    width: 8vw !important;
  }

  .ted-col .mpb-match .player-item {
    width: 25vw !important;
  }

  .MuiDialog-paper {
    margin: 0px !important;
    border-radius: 0px !important;
    width: 100vw;
  }

  .mpb-match {
    border: none !important;
    border-radius: 0px !important;
    width: 100%;
    justify-content: space-evenly;
  }

  .mhrb-left {
    min-width: 30vw !important;
  }

  .mhr-state-bot {
    display: flex;
    align-items: center;
  }

  .mhr-state-bot .mhr-state-act-two .mhrw-box {
    height: 10vw !important;
    width: 10vw !important;
  }

  .ted-result-input .ted-col .mp-man-holes {
    width: 75vw !important;
  }

  .mhrb-state .mhr-state-act-tie .mhrw-box {
    height: 10vw !important;
    width: 10vw !important;
  }

  .tlee-buts {
    flex-direction: column;
  }

  .tlee-buts button {
    margin-bottom: 10px;
  }

  .ltm-teams .tsp-right .player-item {
    width: 60vw !important;
  }

  .ltm-team {
    width: 100% !important;
  }

  .mhr-state-act-tie {
    display: flex;
    align-items: center;
  }

  .delete-button {
    font-size: 14px !important;
  }

  .mhr-state-act {
    display: flex;
    align-items: center;
  }

  .mhr-state-mid {
    display: flex;
    justify-content: center;
  }

  .mhrb-hole-i .mhrb-left {
    /* width: 30vw !important; */

    /* margin-left: 20vw !important; */
  }

  .ted-col .mp-man-holes .default-input {
    width: 10vw !important;
  }

  .mhrb-state .mhr-state-top .mhrw-box {
    height: 10vw !important;
    width: 10vw !important;
  }

  .mhrw-box {
    width: 10vw !important;
    height: 10vw !important;
  }

  .mhrb-hole {
    width: 10vw !important;
    margin-right: 10px;
  }

  .mhrb-ct {
    overflow: scroll;
  }

  .mhrb-state {
    width: 10vw !important;
  }

  .hd-item {
    /* box-shadow: none !important; */
  }

  .ed-box {
    /* box-shadow: none !important; */
  }

  .team-league-editor .input-group {
    width: 75vw !important;
  }

  .team-league-editor .default-input:not(.default-select) {
    width: 65vw !important;
  }

  .team-league-editor .ec-box .player-item {
    width: 90vw !important;
  }

  .mt-items .course-item {
    width: 80vw !important;
  }

  .bdm-player {
    width: 25vw !important;
  }

  .event-home .bdm-player h1 {
    text-align: center;
    font-size: 10px !important;
  }

  .bd-box {
    overflow: scroll;
  }

  .singles-middle {
    padding: 10px 0px;
  }

  .mpr-rounds .mpb-match {
    width: 100% !important;
  }

  .singles-match {
    flex-direction: column;
  }

  .singles-match input {
    margin-left: 20px !important;
  }

  .cm-pc {
    /* width: 100%; */
    justify-content: space-between !important;
  }

  .mpr-match .mpb-match .player-item {
    width: 25vw !important;
  }

  .cp-cups .fixture-preview {
    width: 80vw !important;
  }

  .mpl-round-header h2 {
    font-size: 14px !important;
  }

  .mp-start-dia .mps-players .player-item {
    width: 90vw !important;
  }

  .mp-ma-dia .MuiPaper-root {
    max-height: 100%;
  }

  .mp-start-dia {
    height: 100vh;
    width: 100vw !important;
  }

  .bdr-header p {
    font-size: 12px !important;
  }

  .news-pill p {
    font-size: 12px !important;
  }

  .mpl-matches .mpb-match .player-item {
    width: 25vw !important;
  }

  .mpbm-circle p {
    font-size: 11px !important;
  }

  .mpb-match {
    padding: 0px !important;
  }

  .new-contest .ec-box .default-input {
    width: 65vw !important;
  }

  .new-contest .ec-box .input-group {
    width: 75vw !important;
  }

  .comp-prizes {
    flex-direction: column;
  }

  .contest-item h1 {
    font-size: 13px !important;
  }

  .contest-item h2 {
    font-size: 12px !important;
  }

  .contest-item .MuiSvgIcon-root {
    height: 20px !important;
    width: 20px !important;
  }

  .contest-page .participants-bloc .player-item {
    width: 90vw !important;
  }

  .cir-item {
    height: 25vw !important;
    width: 25vw !important;
  }

  .cir-item h2 {
    font-size: 14px !important;
  }

  .cir-item h3 {
    font-size: 12px !important;
  }

  .contest-input .ne-header {
    font-family: "MS-SM";
    font-size: 14px;
  }

  .aci-players {
    padding: 0px !important;
  }

  .aci-players {
    width: 100% !important;
  }

  .contest-player {
    width: calc(100% - 20px) !important;
    padding: 10px !important;
  }

  .contest-player-header p {
    font-size: 12px !important;
  }

  .contest-player p {
    font-size: 12px !important;
  }

  .coi-dia .MuiDialog-paper {
    max-height: 100% !important;
  }

  .contest-input {
    height: 100vh;
  }

  .new-query-modal textarea {
    width: 65vw !important;
  }

  .support-page .default-input {
    width: 65vw !important;
  }

  .faq-cat {
    padding: 10px 20px !important;
  }

  .sp-item {
    margin-right: 20px !important;
    padding: 10px !important;
  }

  .sp-group p {
    width: 20vw !important;
  }

  .set-sds {
    flex-direction: column;
    align-items: flex-start !important;
  }

  .settings-page .ec-box .default-input {
    width: 65vw !important;
  }

  .settings-page .ec-box .green-link {
    margin-top: 20px;
  }

  .onboarding .default-input {
    width: 65vw !important;
  }

  .onboarding .input-group {
    width: 75vw !important;
  }

  .hae-events .fixture-preview {
    width: 80vw !important;
  }

  .pill-button {
    width: max-content;
  }

  .cm-match-box .cm-pc .default-input {
    width: 50vw !important;
  }

  .singles-left .dg-icon-bg {
    margin-right: 0px !important;
  }

  .clista-box {
    flex-direction: column;
  }

  .clista-left .cr-search {
    display: none;
  }

  .csl-item p {
    font-size: 10px !important;
    padding-left: 0px !important;
  }

  .stats-item {
    width: 80vw !important;
  }
  .csl-item {
    margin-bottom: 0px !important;
    width: 30vw !important;
  }

  .stats-item .si-right > div {
    width: 10vw !important;
  }

  .clista-left {
    height: 10vh !important;
    flex-direction: row !important;
  }

  .clista-right {
    margin-left: 0px !important;
    width: calc(100% - 40px) !important;
  }

  .clista-box .ccp-right {
    width: calc(100% - 40px) !important;
    /* padding: 20px 0px 20px 0px; */
  }

  .rs-box {
    padding: 0px !important;
  }

  .round-item {
    width: calc(100% - 40px) !important;
  }

  .rir-item {
    width: 10vw !important;
  }

  .clista-left {
    padding-left: 0px !important;
  }

  .cc-graphs .small-btn {
    margin-top: 20px !important;
  }

  .cs-graphs .small-but {
    margin-top: 20px;
  }

  .stats-correl {
    width: calc(100% - 40px) !important;
    margin-left: 0px !important;
  }

  .gmb .MuiTabs-scroller {
    overflow: scroll !important;
  }

  .rc-sc {
    overflow: scroll;
  }

  .cs-graphs {
    margin-left: 0px !important;
  }

  .client-stats .cs-graphs .Dropdown-root {
    width: 30vw !important;
  }

  .client-stats .cs-graphs .MuiAutocomplete-root {
    width: 30vw !important;
  }

  .client-stats .cs-graphs .MuiAutocomplete-root .MuiInputBase-root {
    width: 30vw !important;
  }

  .pl-stats-home .psh-members .player-item {
    width: 70vw !important;
  }

  .rci-hcp p {
    width: 40vw !important;
    font-size: 12px !important;
  }

  .rci-hcp h5 {
    font-size: 12px !important;
  }

  .round-card .gre-meu .MuiTab-root {
    font-size: 11px !important;
  }

  .round-card .gre-meu {
    width: 100vw;
  }

  .round-card .gre-meu .MuiTabs-flexContainer {
    overflow: scroll;
  }

  .round-card .round-stats {
    width: 90vw !important;
  }

  .round-card .round-summary .neh-box {
    padding-left: 0px !important;
  }

  .rcn-box p {
    font-size: 12px !important;
  }

  td .bir,
  td .bog,
  td .dbl,
  td .eag {
    width: 5vw !important;
    height: 5vw !important;
  }

  .rc-sc td {
    font-size: 12px !important;
  }

  .aca-coach-card {
    flex: 1 !important;
    width: 80vw !important;
  }

  .client-drills .wbg > .wbg-box {
    flex-direction: column;
  }

  .acc-bg-box img {
    width: 88vw !important;
  }

  .drill-attempt {
    padding: 0px 10px;
  }

  .MuiDialog-container .confirm-modal .MuiPaper-root {
    border-radius: 0px !important;
  }

  .cdl-dia .MuiPaper-root {
    width: 100vw !important;
  }

  .cdl-but {
    padding-left: 20px;
  }

  .ac-dri-box {
    padding: 0px !important;
  }

  .cdl-ctt {
    /* width: 100vw !important; */
    padding: 0px !important;
  }

  .ad-filters {
    flex-direction: column;
  }

  .academy-drills > .flex-ac {
    flex-direction: column;
  }

  .academy-drills .pill-button {
    margin-top: 20px;
  }

  .drill-creator .input-group {
    width: 80vw !important;
  }

  .drill-creator .default-input {
    width: 65vw !important;
  }

  .ac-dr-ch {
    width: 90vw;
  }

  .academy-drills .ad-filters .input-group {
    /* flex-wrap: wrap; */
  }

  .pl-stats-home .rdi-cc-res .course-item {
    width: 80vw !important;
    margin: 10px !important;
  }

  .pls-crs-search .flex-align-center {
    flex-direction: column;
    align-items: flex-start !important;
  }

  .pl-stats-home .rdi-cc-res .course-item .ci-right h1 {
    max-width: 50vw !important;
  }

  .switch-row {
    flex-wrap: wrap;
  }

  .vpb-box h2 {
    font-size: 30px !important;
  }

  .vpb-box p {
    font-size: 14px;
  }

  .big-header {
    font-size: 44px !important;
  }

  .tlp-right {
    display: none !important;
  }

  .tlp-top {
    background-position: initial !important;
  }

  .tlp-left {
    flex: 1 !important;
    padding-left: 20px !important;
  }

  .hlp-buts {
    flex-direction: column;
    align-items: flex-start !important;
    row-gap: 20px;
  }

  .sce-details {
    flex-direction: column !important;
    row-gap: 20px;
  }

  .sce-opts {
    row-gap: 10px;
  }

  .sce-details .MuiAvatar-root {
    height: 30vw !important;
    width: 30vw !important;
    margin-bottom: 20px;
  }

  .acc-drill-row {
    flex-wrap: wrap;
    row-gap: 10px;
    align-items: center !important;
  }

  .adr-item {
    padding: 0px 10px !important;
  }

  .acc-drill-row .Dropdown-control {
    width: 25vw !important;
  }

  .csg-per {
    flex-wrap: wrap;
    flex-direction: column;
    align-items: center !important;
  }

  .ec-tabs {
    max-width: 90vw;
    width: max-content;
  }

  .rg-20 {
    row-gap: 20px;
  }

  .rdi-cc .rdi-cc-res .course-item {
    width: 90vw !important;
  }

  .rdi-search {
    flex-direction: column !important;
    row-gap: 10px;
  }

  .gre-meu {
    max-width: 90vw;
  }

  .crl-smol {
    display: none !important;
  }

  .clro-right {
    padding-left: 20px;
  }

  .csl-item {
    margin-right: 10px;
  }

  .pls-ps input {
    width: 70vw !important;
  }

  .ddb-box {
    flex-direction: column;
  }

  .ddb-right {
    padding-left: 0px !important;
  }

  .ddb-left .MuiAvatar-root {
    width: 30vw !important;
    height: 30vw !important;
  }

  .ddb-left {
    padding-bottom: 20px;
    display: flex;
    justify-content: center;
  }

  .drill-attempt p {
    text-align: center;
  }

  .acc-drill-row {
    overflow-x: scroll;
    padding-bottom: 5px !important;
  }
}
