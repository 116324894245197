.smart-item {
  display: flex;
  align-items: center;
}

.smart-box {
  padding-top: 0px;
}

.smart-item p {
  color: #232c2e;
  font-size: 13px;
  font-weight: 600;
}

.smart-item h5 {
  color: #232c2e;
  font-size: 13px;
  width: 20vw;
}

.bold {
  font-weight: 600;
}

.smart-stats {
  background-color: white;
  padding: 20px;
}
