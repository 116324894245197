.si-select {
  height: 75vh;
  width: 50vw;
  /* padding: 20px; */
}

.si-players {
  padding: 20px;
}

.si-search {
  padding: 0px 20px;
}

.putt-ico {
  width: calc(1em + 22.5px);
  height: calc(1em + 22.5px);
  padding: 0px !important;
}

.sic-box #text {
  display: none;
}

.si-card {
  box-shadow: rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;

  /* box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px,
    rgba(14, 30, 37, 0.32) 0px 2px 16px 0px; */
  border-radius: 20px;
  padding: 20px;
  width: 70vw;
  background-color: white;
}

.sis-item {
  margin-right: 20px;
}

.sis-item p {
  font-size: 12px;
  color: #232c2e;
  opacity: 0.8;
}

.sis-item span {
  font-weight: 900;
}

.si-summary {
  margin-bottom: 20px;
  padding-left: 20px;
  display: flex;
  align-items: center;
}

.sic-box {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 40px;
  margin-top: 20px;
}

.si-table td {
  padding: 10px;
}

.score-table-container table {
  width: 100%;
  /* table-layout: fixed; */
  border-collapse: collapse;
}

.scorecard h3 {
  font-family: "Inter", sans-serif !important;
  /* font-family: 'Roboto Mono', monospace; */
}

.score-table-container > thead > tr {
  display: block;
}

.score-table-container td {
  text-align: center;
  padding: 10px;
  font-size: 14px;
  font-family: "Inter";
}

.prtc-header th {
  font-weight: 400 !important;
  font-size: 12px;
  text-align: center;
  color: #5c6363;
  font-family: "Inter";
}

.in-row {
  background-color: #e9f8f2;
}

.out-row {
  background-color: #fcedee;
}

.total-row {
  background-color: #f9fafb;
}

.header-row {
  border-bottom: 1px solid #edefef;
  border-top: 1px solid #edefef;
}

.score-table-container td {
  text-align: center;
  padding: 10px;
  font-size: 14px;
  font-family: "Inter";
}

.hole-td {
  font-weight: 600;
  opacity: 0.5;
  font-family: "Inter";
}

.stroke-td {
  opacity: 0.5;
}

.par-td {
  opacity: 0.5;
}

.player-td {
  border-radius: 2px;
  font-weight: 600;
}

.hit {
  fill: #1e7a69 !important;
}

.missed {
  fill: #e24955 !important;
}

.fir-icon:hover {
  cursor: pointer;
  opacity: 0.5;
}

.score-td input {
  width: 2.5vw !important;
}

.normal-td input {
  width: 2.5vw !important;
}

.si-course {
  padding-left: 20px;
}

.si-course p {
  font-size: 12px;
  opacity: 0.6;
  font-weight: 600;
  margin: 2.5px 0px;
}

.selector-holes {
  display: flex;
  align-items: center;
  width: 55vw;
  overflow-x: scroll;
  white-space: nowrap;
}

.sh-td {
  /* overflow: hidden; */
  /* width: 50vw; */
}

.si-tee {
  padding-left: 20px;
  margin-top: -10px;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  /* justify-content: ; */
  width: max-content;
}

.si-ico {
  fill: #1e7a69 !important;
  height: 20px !important;
}

.si-tee-box {
  border: 1px solid;
  border-radius: 20px;
  padding: 5px;
}

.si-tee-box p {
  text-transform: capitalize;
  font-weight: 600;
  font-size: 12px;
}

.si-sel-hole {
  border: 1.5px solid #232c2e;
  border-radius: 50%;
  width: 3.25vw;
  height: 3.25vw;
  min-width: 3.25vw;
  min-height: 3.25vw;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  margin-right: 10px;
}

.si-sel-hole p {
  font-weight: 500;
  font-size: 16px;
}

.sis-active-zero {
  background-color: #232c2e;
}

.sis-active-zero p {
  color: whitesmoke;
}

.sis-active {
  background-color: #232c2e;
}

.sis-active p {
  color: whitesmoke;
}

.sis-under {
  border-color: #1e7a69 !important;
}

.sis-under p {
  color: #1e7a69;
}

.sis-over {
  border-color: #a9363f !important;
}

.sis-over p {
  color: #a9363f;
}

.sis-active.sis-under {
  background-color: #1e7a69;
}

.sis-active.sis-under p {
  color: whitesmoke;
}

.sis-active.sis-over {
  background-color: #a9363f;
}

.sis-active.sis-over p {
  color: whitesmoke;
}

.sis-par {
  border-color: #5c6363;
}

.sis-par p {
  color: #5c6363;
}

.sis-active.sis-par {
  background-color: #5c6363;
}

.sis-active.sis-par p {
  color: whitesmoke !important;
}

/* .sis-1 {
  border-color: #e24955 !important;
}

.sis-1 p {
  color: #e24955;
}

.sis-1.sis-active {
  background-color: #e24955 !important;
}

.sis-2 {
  border-color: #70242a !important;
}

.sis-2 p {
  color: #70242a;
}

.sis-2.sis-active {
  background-color: #70242a !important;
}

.sis--2 {
  border-color: #21c17c !important;
}

.sis--2 p {
  color: #21c17c;
}

.sis--2.sis-active {
  background-color: #21c17c !important;
}

.sis--3 {
  border-color: #58d09c !important;
}

.sis--3 p {
  color: #58d09c;
}

.sis--3.sis-active {
  background-color: #58d09c !important;
} */

.hole-container {
  width: 100%; /* Adjust the width as needed */
  overflow: auto;
  display: flex;
  align-items: center;
  white-space: nowrap; /* Prevent line breaks within the container */
}

.sh-td {
  width: 50vw;
}
