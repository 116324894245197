.contest-page {
  min-height: calc(100vh - 110px);
  /* overflow: scroll; */
}

.cp-buttons {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.contest-page .Dropdown-root {
  /* width: calc(90vw - 20px); */
}

.comp-prizes {
  display: flex;
  align-items: center;
}

.comp-prizes input {
  width: 15vw !important;
}

.comp-prize {
  margin-right: 5vw;
}

.new-contest-players {
  display: flex;
  flex-wrap: wrap;
  /* justify-content: space-around; */
  width: 75%;
}

.participants-bloc {
  padding: 20px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}

.participants-bloc .player-item {
  width: 25vw !important;
}

.cm-rem-dia .close-row svg {
  margin-right: 20px;
  margin-top: 20px;
}

.comp-prize {
  display: flex;
  align-items: center;
}

.comp-prize p {
  margin-right: 20px;
}

.comp-prize span {
  font-size: 14px;
  color: #232c2e;
}

.new-contest-input {
  padding: 0px 20px;
}

.new-contest-players p {
  color: #232c2e;
  font-size: 12px;
  margin-right: 20px;
  line-height: 20px;
}

.contest-page .MuiAccordion-root::before {
  display: none;
}

.contest-page .MuiAccordion-root {
  margin-bottom: 20px;
}

.contest-item {
  display: flex;
  align-items: center;
  cursor: pointer;
  box-shadow: var(--bs1);
  border-radius: 7px;
  margin-bottom: 10px;
}

.con-item-left .MuiAvatar-root {
  height: 10vh !important;
  width: 10vh !important;
}

.con-item-left {
  padding-right: 20px;
}

.contest-item {
  padding: 20px;
  border-bottom: 1px solid #edefef;
}

.contest-item h1 {
  color: #232c2e;
  font-size: 15px;
  border-bottom: 2px solid #1e7a69;
  width: max-content;
  font-weight: 600;
}

.contest-item h2 {
  font-size: 13px;
  font-weight: 400;
  margin: 5px 0px;
  opacity: 0.7;
}

.ci-flex {
  display: flex;
  align-items: center;
}

.ci-flex p {
  font-size: 12px;
  color: #232c2e;
  opacity: 0.5;
  margin-left: 10px;
}

.cp-code {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.cp-code p {
  font-size: 14px;
  margin-bottom: 40px;
  color: #232c2e;
}

.cp-code button {
  margin-top: 40px !important;
}

.contest-page .fd-ts-header {
  justify-content: space-between;
}

.contest-details {
  background-color: #1e7a69;
}

.cp-menu .MuiTab-root {
  width: 33.3vw;
}

.cp-menu-two .MuiTab-root {
  width: 50vw;
}

.contest-input .new-header {
  font-size: 18px !important;
  width: 75%;
  color: #232c2e;
  text-align: center;
  margin: 5vh 0px 2.5vh 0px;
}

.contest-details h1 {
  color: white;
  filter: drop-shadow(2px 4px 6px #232c2e);
}

.ci-attempts {
  display: flex;
  align-items: center;
  justify-content: center;
}

.ci-attempt {
  background-color: #1e7a69;
  /* margin: 5vw; */
  /* margin-right: 10px; */
  margin-bottom: 10px;
  height: 5vw;
  width: 5vw;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.ci-attempt-box {
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-right: 20px;
}

.ci-attempts p {
  color: white;
  font-size: 24px;
  text-align: center;
  font-weight: 900;
}

.contest-input h1 {
  filter: drop-shadow(1px 2px 2px #232c2e73);
  color: #232c2e;
  font-size: 30px;
}

.contest-board {
  min-height: calc(100vh - 187px);
}

.contest-board .neh-box {
  width: 100%;
}

.contest-board {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.contest-page .pir-item h3 {
  color: #232c2e !important;
}

.contest-page .fdc-reg::before {
  background-image: none;
}

.cb-row {
  display: flex;
  align-items: center;
}

.ci-input p {
  filter: drop-shadow(1px 2px 2px #232c2e2c);
  color: #232c2e;
  font-size: 14px;
  margin-bottom: 10px;
  font-weight: 900;
  text-transform: capitalize;
}

.aci-ps {
  display: flex;
  width: 100%;
  align-items: center;
}

.conman-remove .big-icon {
  width: 10vw !important;
  height: 10vw !important;
}

.cpm-players {
  padding: 0px 20px 0px 20px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}

.cpm-players .player-item {
  width: 25vw !important;
}

.conman-remove {
  height: 50vh;
  width: 75vw;
  justify-content: center;
}

.conman-remove p {
  width: 75%;
  text-align: center;
  margin: 20px 0px;
  font-size: 14px;
  color: #232c2e;
  font-weight: 500;
}

.contest-input {
  min-width: 50vw;
}

.new-contest-input input {
  width: 50vw !important;
}

.new-contest-input .Dropdown-root {
  width: calc(50vw + 40px) !important;
}

.conman-remove button {
  width: 50vw !important;
}

.con-ent-bot {
  display: flex;
  margin-top: 10px;
  align-items: center;
}

.conman-entries h2 {
  color: #232c2e;
}

.con-ent-bot h3 {
  color: #232c2e;
  margin: 0px 10px;
}

.conman-entries {
  margin-bottom: 20px;
}

.cir-item {
  width: 12vw;
  height: 12vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #1e7a69;
  border-radius: 50%;
}

.cir-center {
  margin: 0px 5vw;
}

.cir-item h2,
.cir-item h3 {
  color: #edefef;
  filter: drop-shadow(1px 2px 2px #232c2eab);
  text-transform: uppercase;
}

.cir-item h2 {
  font-size: 18px;
}

.cir-item h3 {
  font-size: 14px;
}

.contest-info-row {
  display: flex;
  align-items: center;
  padding: 20px;
}

.cir-div {
  border-bottom: 1px solid #edefef;
  width: 50%;
  margin: 10px 0px;
}

.contest-player {
  padding: 10px 20px;
  width: 70%;
  display: flex;
  align-items: center;
  border-bottom: 1px solid rgb(237, 239, 239);
}

.cp-pos {
  flex: 0.15;
  font-size: 14px;
  color: #5c6363;
  /* font-weight: 500; */
  text-align: center;
}

.cp-name {
  flex: 0.4;
  color: #232c2e;
  font-size: 14px;
  font-weight: 500;
}

.cp-atts {
  flex: 0.15;
  font-size: 14px;
  opacity: 0.5;
  color: #232c2e;
  text-align: center;
}

.contest-player-header p {
  color: #232c2e !important;
  opacity: 1 !important;
}

.cp-score {
  flex: 0.15;
  font-size: 14px;
  text-align: center;

  color: #a9363f !important;
}

.cp-edit {
  flex: 0.15;
  display: flex;
  justify-content: center;
  align-items: center;
}

.aci-players {
  padding: 20px;
  align-items: flex-start !important;
}

.ci-input {
  display: flex;
  flex-direction: column;
  align-items: center;
}
