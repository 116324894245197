.rank-tracker {
    width: 100%;
    height: 100%;
    overflow: scroll;
}


.rank-tracker .Dropdown-control {
    padding-top: 4.75px !important;
    padding-bottom: 4.75px !important;
}

.rank-tracker .input-group {
    padding: 0px 20px;
}

.track-graph {
    padding: 0px 20px;
}


.league-rank-tracker {
    min-height: 50vh;
}