.time-item {
  display: flex;
  /* background-color: #F5F7F8; */
  width: 85%;
  border-radius: 8px;
  flex-direction: column;
  padding: 2.5%;
  margin-top: 20px;
  /* margin-top: 20px; */
  color: #232c2e;
  border-bottom: 1px solid #edefef;
  /* padding-left: 10%; */
  transition: 200ms ease-in-out;
  -webkit-appearance: none !important;
  box-shadow: rgba(9, 30, 66, 0.25) 0px 1px 1px,
    rgba(9, 30, 66, 0.13) 0px 0px 1px 1px;
  /* box-shadow: 0px 2px 2px 1px rgb(0 0 0 / 28%) !important; */
  /* -webkit-box-shadow: 0px 2px 2px 1px rgb(0 0 0 / 28%) !important; */
}

.ti-select {
  cursor: pointer;
}

.ti-select:hover {
  background-color: #e9f8f2 !important;
}

.ti-top-row {
  display: flex;
  align-items: center;
}

.time-row {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
  border-bottom: 2px solid #1e7a69;
  width: max-content;
}

.time-row h1 {
  /* margin-left: 10px; */
  font-size: 13px !important;
  margin-bottom: 0px !important;
  margin-left: 0px;
  font-weight: 600;
}

.players-container {
  display: flex;
  flex-direction: column;
  /* padding-left: 5px; */
}

.players-container p {
  /* margin-bottom: 5px; */
  opacity: 0.9;
  font-size: 12px;
  margin-left: 10px;
  padding: 0px !important;
  margin-top: 0px !important;
}

.times-container {
  border-top: 1px solid #edefef;
}

.tee-times-header {
  margin-top: 20px;
  margin-bottom: 10px;
  display: flex;
  width: calc(100% - 20px);
  /* margin-left: 20px; */
  border-bottom: 1px solid #edefef;
  padding-bottom: 20px;
}

.tee-times-header h1 {
  font-size: 14px;
  margin: 0px !important;
  color: #232c2e;
  font-weight: 600;
}
